<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_rt flex ">
					<div>上传充值审核</div>
				</div>
				<div class="upload_t flex">
					<div class="upload_tl bixuan">充值人手机号</div>
					<el-input v-model="input1" placeholder="请输入手机号"></el-input>
				</div>
				<div class="upload_t flex">
					<div class="upload_tl bixuan">充值金额</div>
					<el-input v-model="input2" @input="sendCoupon" placeholder="请输入充值金额"></el-input>
				</div>
				<div class="upload_t flex">
					<div class="upload_tl bixuan">公司抬头</div>
					<el-input v-model="input3" placeholder="请输入公司抬头"></el-input>
				</div>
				<div class="upload_t flex">
					<div class="upload_tl bixuan">税号</div>
					<el-input v-model="input4" placeholder="请输入税号"></el-input>
				</div>
				<div class="upload_t flex">
					<div class="upload_tl bixuan">银行卡号</div>
					<el-input v-model="input5" placeholder="请输入银行卡号"></el-input>
				</div>
				<div class="upload_t flex">
					<div class="upload_tl bixuan">图片</div>
					<div class="upload_tr">
						<!--  -->
						<!-- :on-success="onSuccess" -->
						<el-upload class="avatar-uploader" :action="url" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="imageUrl" :src="imageUrl" class="avatar">
							<!-- <i v-else class="el-icon-plus avatar-uploader-icon"></i> -->
							<img v-else class="shang" src="../../static/image/45.png" alt="" srcset="">
						</el-upload>
					</div>
				</div>
				<div class="upload_b flex ">
					<div class="upload_bl pointer" @click="tijiao()">提交</div>
					<div class="upload_br bodycolor pointer" @click="chufa">取消</div>
				</div>
			</div>
		</div>
		<phone></phone>
		<el-dialog title="" :visible.sync="dialogVisible" @close="chufa" width="30%">
			<div class="tanl">
				<img class="goood" src="../../static/image/46.png" alt="" srcset="">
				<div class="tanll">充值申请提交成功</div>
				<div class="tanlr">充值金额：50元</div>
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {
			menud,
			phone
		},
		data() {
			return {
				lestId: 2,
				input1: '',
				input2: '',
				input3: '',
				input4: '',
				input5: '',
				imageUrl: '',
				dialogVisible: false,
				url: '',
				fileList: [],
			}
		},
		mounted() {},
		created() {
			this.url = 'https://api.beixusmt.com/' + this.$apiUrls.uploadFile
		},
		methods: {
			// 保留两位小数
			sendCoupon(e) {
				var str = e
				var len1 = str.substr(0, 1)
				var len2 = str.substr(1, 1)
				//如果第一位是0，第二位不是点，就用数字把点替换掉
				if (str.length > 1 && len1 == 0 && len2 != ".") {
					str = str.substr(1, 1)
				}
				//第一位不能是.
				if (len1 == ".") {
					str = ""
				}
				//限制只能输入一个小数点
				if (str.indexOf(".") != -1) {
					var str_ = str.substr(str.indexOf(".") + 1)
					if (str_.indexOf(".") != -1) {
						str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
					}
				}
				//正则替换
				str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
				str = str.replace(/\.\d\d\d$/, '') // 小数点后只能输两位
				this.$nextTick(() => {
					this.input2 = str
				})

			},
			chufa() {
				console.log('触发')
				// this.tiaohuan('yellow',1)
				this.$router.go(-1)
			},
			tijiao() {
				if (!this.input1) {
					this.$message.error('请输入充值人手机号！');
					return
				} else {
					let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
					if (!reg.test(this.input1)) {
						this.$message.error('请输入正确的手机号！');
						return
					}
				}
				if (!this.input2) {
					this.$message.error('请输入充值金额！');
					return
				}
				if (!this.input3) {
					this.$message.error('请输入公司抬头！');
					return
				}
				if (!this.input4) {
					this.$message.error('请输入税号！');
					return
				}
				if (!this.input5) {
					this.$message.error('请输入银行卡号！');
					return
				}
				if(this.fileList.length == 0){
					this.$message.error('请上传图片！');
					return
				}
				let params = {
					bankCard: this.input5,
					dutyParagraph: this.input4,
					fileIds: this.fileList,
					gsName: this.input3,
					rechargeMoney: this.input2,
					rechargePhone: this.input1,
				}
				// fileIds
				this.$api.post(this.$apiUrls.recordSav, params).then((res) => {
					if (res.code == 0) {
						this.$message.success('上传成功！');
						this.$router.back()
					} else {
						this.$message.error(res.msg);
						return
					}
				})
			},
			tiaohuan(url, type) {
				let page = {}
				if (type) {
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			// 上传成功
			onSuccess(response, file, fileList) {
				// console.log(response, file, fileList,123)
			},
			handleAvatarSuccess(res, file) {
				this.fileList[0] = res.data.fileId
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				console.log(file)
				let isJPG = ''
				if (file.type === 'image/jpeg' || file.type === 'image/png') {
					isJPG = true
				} else {
					isJPG = false
				}
				// return
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep {
		.el-input {
			height: 48px;
			width: 320px;

			input {
				height: 48px;
				width: 320px;
			}
		}
	}

	.page {
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}

	.confirm_t {
		margin: 0 0 18px 220px;

		div {
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}

		.imd {
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}

	.person_r {
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px;
		box-sizing: border-box;

		.person_rt {
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 20px 0;

			.imd {
				width: 17px;
				height: 17px;
				margin: 3px 9px 0 0;
			}
		}
	}

	.upload_b {
		justify-content: center;
		align-content: center;
		padding: 32px 0 0 0;
		box-sizing: border-box;

		.upload_bl,
		.upload_br {
			width: 184px;
			height: 53px;
			border-radius: 28px 28px 28px 28px;
			font-size: 16px;
			font-weight: 400;
			line-height: 53px;
			text-align: center;
		}

		.upload_bl {
			background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
			color: #fff;
			margin: 0 68px 0 0;
		}

		.upload_br {
			border: 1px solid #0DC3D6;
		}
	}

	.upload_t {
		margin: 0 0 30px 0;

		.upload_tl {
			width: 40%;
			text-align: right;
			margin: 0 24px 0 0;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 48px;
		}

		.upload_tr {}
	}

	.avatar-uploader .el-upload {
		// border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	// .avatar-uploader-icon {
	//   font-size: 28px;
	//   color: #8c939d;
	//   width: 106px;
	//   height: 106px;
	//   line-height: 106px;
	//   text-align: center;
	// }
	.avatar {
		width: 106px;
		height: 106px;
		display: block;
	}

	.shang {
		width: 106px;
		height: 106px;
	}

	.tanl {
		text-align: center;

		.goood {
			width: 152px;
			height: 152px;
			margin: 0 auto;
		}

		.tanll {
			margin: 24px 0;
			font-size: 20px;
			font-weight: 400;
			color: #333333;
		}

		.tanlr {
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
	}
</style>
