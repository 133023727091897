<template>
  <div class="container">
    <nav-header v-if="topType" ref="componenta" @getList="getList"></nav-header>
    <router-view :parentPrint="parentPrint"></router-view>
    <nav-footer v-if="topType"></nav-footer>
  </div>
</template>

<script>
  const { default: NavFooter }=require("@/components/NavFooter.vue")
  const { default: NavHeader }=require("@/components/NavHeader.vue")

  export default {
    components: { NavFooter, NavHeader },
	data() {
		return {
			topType:true
		}
	},
	    watch: {
	        //监听路由地址的改变
	        $route:{
	            immediate:true,
	            handler(){
		            console.log(this.$route.path,1111)
					if(this.$route.path == '/login/1' || this.$route.path == '/login/2' || this.$route.path == '/login'){
						this.topType = false
					}else{
						this.topType = true
					}
	            }
	        }
	    },
	methods:{
		parentPrint(){
			console.log('执行123')
			this.$refs.componenta.xinxi();
		},
		getList(name) {
		  // this.$refs.componenta.testA(name);
		},
	}
  }
  
</script>

<style >
	.bixuan:before{
		    content: "*";
		    color: #f56c6c;
		    margin-right: 4px;
	}
	html{
		background: #f6f7f9 !important;
	}
	.w1600{
		width:1600px;
		margin: 0 auto;
	}
	.w1200{
		width:1200px;
		margin: 0 auto;
	}
	.absolute{
		position: absolute;
	}
	.relative{
		position: relative;
	}
	.fixed{
		position: fixed;
	}
	.p1{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}
	.p2{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
	.p3{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
		overflow: hidden;
	}
	.flex{
		display: flex;
	}
	.wrap{
		flex-wrap: wrap;
	}
	.space-between{
		justify-content:space-between;
	}
	.flex-end{
		justify-content:flex-end;
	}
	.pointer{
		cursor:pointer
	}
    .container {
        background: #F4F8FA;
    }
	.bodycolor{
		color: #0DC3D6 !important;
	}
	.borderBottm{
		border-bottom: 1px dashed  #0DC3D6 !important;
	}
	.justify-content{
		justify-content: center;
	}
	img{
		width: 100%;
	}
	.scroll-box::-webkit-scrollbar {
	    display: none !important;
	  }
</style>

