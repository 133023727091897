<template>
	<div class="fixed shouji" @click="" @mouseenter="handleMouseLeave" @mouseleave="handleMouseLeave">
		<img class="pot" src="../../static/image/4.png" alt="" srcset="">
		<div class="xinxi flex absolute" v-if="toBoole">
			<img class="dian" src="../../static/image/4.png" alt="" srcset="">
			<div class="xinxi_r">
				<p class="xinxi_rt">联系电话</p>
				<div class="xinxi_rh">{{detail.phone}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				toBoole:false,
				detail:'',
			}
		},
		mounted() {
			this.usInfo()
		},
		methods: {
			usInfo(){
				this.$api.get(this.$apiUrls.usInfo,).then((res)=> {
					// console.log(res,'执行')
				    if (res.code == 0) {
						this.detail = res.data
				    }
				})
			},
			handleMouseLeave() {
			    this.toBoole = !this.toBoole
			},
			
		}
	}
</script>

<style  lang="scss" scoped>
	.shouji{
		right: 129px;
		bottom: 30%;
		width: 90px;
		height: 90px;
		.pot{
			width: 90px;
			height: 90px;
		}
		.xinxi{
			width: 338px;
			height: 98px;
			background: url(../../static/image/5.png) 0 0 no-repeat;
			background-size: 100% 100%;
			top: 0;
			right: 90px;
			padding: 26px 16px;
			box-sizing: border-box;
			.dian{
				width: 46px;
				height: 46px;
			}
			.xinxi_r{
				margin: 0 0 0 10px;
				padding: 0 15px;
				box-sizing: border-box;
				border-left: 1px solid #EEEEEE;
				.xinxi_rt{
					font-size: 14px;
					font-weight: 400;
					color: #555555;
					letter-spacing: 18px;
				}
				.xinxi_rh{
					font-size: 24px;
					font-weight: bold;
					color: #555555;
				}
			}
		}
	}
</style>