<template>
	<div class="page">
		
		<el-dialog
		  title="提示"
		  @close="chufa"
		  :visible.sync="dialog"
		  width="40%">
		    <div slot="title" class="dialog-title lookt">
			    <img class="lookt_l" src="../../static/image/2.png" alt="">
		        <span class="title-text">申请开票</span>
		    </div>
			<div class="lookEdit flex">
				<div class="lookEdit_l">开票主体</div>
				<div class="lookEdit_r">
					<el-radio v-model="radio" label="1">公司</el-radio>
					<el-radio v-model="radio" label="2">个人</el-radio>
				</div>
			</div>
			<div class="lookEdit flex">
				<div class="lookEdit_l" v-if="radio == 1">开票公司名称</div>
				<div class="lookEdit_l" v-if="radio == 2">个人名称</div>
				<div class="lookEdit_r">
					<el-input v-model="input1" placeholder="请输入名称"></el-input>
				</div>
			</div>
			<div class="lookEdit flex" v-if="radio == 1">
				<div class="lookEdit_l">公司税号</div>
				<div class="lookEdit_r">
					<el-input v-model="input2" placeholder="请输入公司税号"></el-input>
				</div>
			</div>
			<div class="lookEdit flex">
				<div class="lookEdit_l">所在地区</div>
				<div class="lookEdit_r flex">
					<el-select v-model="input3" placeholder="省">
					    <el-option
					      v-for="(item,index) in options"
						  @click.native="sheng(item)"
					      :key="item.id"
					      :label="item.name"
					      :value="item.id">
					    </el-option>
					</el-select>
					<div class="bian"></div>
					<el-select v-model="input4" placeholder="市">
					    <el-option
					      v-for="(item,index) in options1"
						  @click.native="shi(item)"
					      :key="item.id"
					      :label="item.name"
					      :value="item.id">
					    </el-option>
					</el-select>
					<div class="bian"></div>
					<el-select v-model="input5" placeholder="区">
					    <el-option
					      v-for="(item,index) in options2"
						  @click.native="qu(item)"
					      :key="item.id"
					      :label="item.name"
					      :value="item.id">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="lookEdit flex">
				<div class="lookEdit_l">详细地址</div>
				<div class="lookEdit_r">
					<el-input
					  type="textarea"
					  :rows="4"
					  placeholder="请填写详细地址：如道路、门牌号、小区、楼栋号等"
					  v-model="input6">
					</el-input>
				</div>
			</div>
			<div class="lookEdit flex">
				<div class="lookEdit_l">联系方式</div>
				<div class="lookEdit_r">
					<el-input v-model="input7" placeholder="请输入联系方式"></el-input>
				</div>
			</div>
			<div class="lookEdit flex">
				<div class="lookEdit_l">账号</div>
				<div class="lookEdit_r">
					<el-input v-model="input8" placeholder="请输入账号"></el-input>
				</div>
			</div>
			<div class="lookEdit flex">
				<div class="lookEdit_l">开户行</div>
				<div class="lookEdit_r">
					<el-input v-model="input9" placeholder="请输入开户行"></el-input>
				</div>
			</div>
			<div class="lookEdit flex">
				<div class="lookEdit_l">设置为默认抬头</div>
				<div class="lookEdit_r">
					<el-switch
					  v-model="input10"
					  active-color="#13ce66"
					  inactive-color="#EEEEEE">
					</el-switch>
				</div>
			</div>
		    <div class="tijiao pointer" @click="tijiao">提交</div>
		</el-dialog>
	</div>
</template>

<script>
	import { province } from '@/api/map'
	export default {
		components: {},
		props: {
		    edId:{
				type:String ,
				default: ''
			},
			dialogVisible:{
				type:Boolean,
				default: false
			},
		},
		data() {
			return {
                dialog: false,
				id:'',
				input1:'',
				input2:'',
				input3:'',
				input4:'',
				input5:'',
				input6:'',
				input7:'',
				input8:'',
				input9:'',
				input10:true,
				options:[],
				options1:[],
				options2:[],
				value: '',
				sName:'',
				hName:'',
				qName:'',
				toType:false,
				radio:'1',
			}
		},
		watch:{
			dialogVisible(n,o){
				if(n){
					this.dialog = true
				}
			},
			edId(n,o){
				console.log(n,123)
				if(n){
					this.dialog = true
					this.addressInfo()
				}
				// this.dialog=n
			}
		},
		mounted() {},
		created() {
			// console.log(this.edId,1234)
			this.options = province
		},
		methods: {
			addressInfo(){
				let params = {
					id:this.edId
				}
				this.$api.get(this.$apiUrls.addressInfo,{params}).then((res)=> {
					console.log(res,123)
					if (res.code == 0) {
						let data = res.data
						this.radio = data.billingSubject == 0 ? '1': '2'
						this.input1 = data.name
						this.input2 = data.dutyParagraph
						this.input3 = data.provinceCode
						this.input4 = data.cityCode
						this.input5 = data.areaCode
						this.input6 = data.address
						this.input7 = data.phone
						this.input8 = data.account
						this.input9 = data.openingBank
						this.input10 = data.sfDefault == 1 ? true : false 
						data.pcaName = data.pcaName.split(',')
						this.sName = data.pcaName[0]
						this.hName = data.pcaName[1]
						this.qName = data.pcaName[2]
						for (var i = 0; i < this.options.length; i++) {
							if(this.options[i].id == data.provinceCode){
								this.options1 = this.options[i].children
							}
						}
						for (var i = 0; i < this.options1.length; i++) {
							if(this.options1[i].id == data.cityCode){
								this.options2 = this.options1[i].children
							}
						}
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			tijiao(){
				if(!this.input1){
					this.$message.error('请输入开票名称');
					return
				}
				if(!this.input2 && this.radio == 1){
					this.$message.error('请输入公司税号');
					return
				}
				if(!this.input3){
					this.$message.error('请选择省');
					return
				}
				if(!this.input4){
					this.$message.error('请选择市');
					return
				}
				if(!this.input5){
					this.$message.error('请选择区');
					return
				}
				if(!this.input6){
					this.$message.error('请填写详细地址');
					return
				}
				if(!this.input7){
					this.$message.error('请输入联系方式');
					return
				}
				if(!this.input8){
					this.$message.error('请输入账号');
					return
				}
				if(!this.input9){
					this.$message.error('请输入开户行');
					return
				}
				if(this.toType){
					return
				}
				this.toType = true
				
				let params = {
					billingSubject:this.radio == 1 ? '0':1,
					name:this.input1,
					
					provinceCode:this.input3,
					cityCode:this.input4,
					areaCode:this.input5,
					address:this.input6,
					phone:this.input7,
					account:this.input8,
					openingBank:this.input9,
					sfDefault:this.input10 ? 1 : '0',
					pcaName:this.sName+this.hName+this.qName
				}
				console.log(params)
				// return
				if(this.radio == 1){
					params.dutyParagraph=this.input2
				}
				let url = ''
				if(this.edId){
					url = this.$apiUrls.addressEdit
					params.id = this.edId
				}else{
					url = this.$apiUrls.addressSave
				}
				this.$api.post(url,params).then((res)=> {
					console.log(res,123)
				    if (res.code == 0) {
						if(this.edId){
							this.$message.success('修改成功');
						}else{
							this.$message.success('添加成功');
						}
						
						this.dialog = false
						this.toType=false
						this.$emit('addAlss','')
						return
				    }else{
						this.toType = false
						this.$message.error(res.msg);
						return
					}
					this.toType = false
				})
			},
			sheng(item){
				this.options1 = item.children
				this.sName = item.name
				this.input4 = ''
				this.input5 = ''
			},
			shi(item){
				this.options2 = item.children
				this.hName = item.name
				
				this.input5 = ''
			},
			qu(item){
				this.qName = item.name
			},
			noLook(){
				this.dialog = false
				this.$emit('noLook','')
			},
	
			// 编辑
			edit(item){
				// console.log('编辑')
			},
			// 清空
			qing(){
				this.id=''
				this.input1=''
				this.input2=''
				this.input3=''
				this.input4=''
				this.input5=''
				this.input6=''
				this.input7=''
				this.input8=''
				this.input9=''
				this.options=[]
				this.options1=[]
				this.options2=[]
				this.value=''
				this.sName=''
				this.hName=''
				this.qName=''
				this.radio='1'
				this.toType=false
				this.input10 = true
				this.options = province
			},
			// 点击背景
			chufa(){
				// console.log('触发')
				this.qing()
				this.$emit('noLookEdit',this.id)
			},
			// 关闭
			guanbi(){
				this.dialog = false
				this.qing()
				this.$emit('noLookEdit',this.id)
			} 
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-select{
			width: 30%;
		}
	}
	/deep/ input{
		height: 48px;
	}
	.bian{
		width: 18px;
	}
	.lookt{
		.lookt_l{
			width: 3px;
			height: 14px;
		}
		.title-text{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			margin: 0 0 0 8px;
		}
	}
	.lookEdit{
		margin: 0 0 24px 0;
		.lookEdit_l{
			width: 22%;
			text-align: right;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 48px;
		}
		.lookEdit_r{
			width: 70%;
			padding-left: 24px;
			line-height: 48px;
		}
	}
	.tijiao{
		width: 462px;
		height: 56px;
		background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
		border-radius: 28px 28px 28px 28px;
		margin: 18px auto 0;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 56px;
		text-align: center;
	}
</style>