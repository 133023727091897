<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_t">
					<div class="person_rt flex space-between" :class="type == 1 ? 'pding' : ''">
						<div class="flex" :class="type == 1 ? 'chongzhi' : ''">
							<div class="pointer" @click="tiaohuan('wallet')">我的钱包</div>
							<img class="imd" src="../../static/image/7.png" alt="" srcset="">
							<div class="bodycolor" v-if="type == 2">转出记录</div>
							<div class="bodycolor" v-if="type == 1">充值记录</div>
						</div>
						<div class="person_rtr bodycolor pointer" v-if="type == 1" @click="tiaohuan('upload')">上传充值审核</div>
					</div>
					
					<div class="wallet_h flex space-between">
						<div class="wallet_hl" v-if="type == 2">转出明细</div>
						<div class="wallet_hl" v-if="type == 1">充值明细</div>
						<div class="wallet_hr flex">
							<div class="wallet_hrl">
								<el-date-picker
								      v-model="value1"
								      type="daterange"
								      range-separator="至"
								      start-placeholder="开始日期"
								      end-placeholder="结束日期"
									  value-format="yyyy-MM-dd HH:mm:ss"
									  >
								    </el-date-picker>
							</div>
							<div class="wallet_hrr flex pointer" @click="sousuo">
								<img class="imd" src="../../static/image/38.png" alt="" srcset="">
								<div>搜索</div>
							</div>
						</div>
					</div>
				</div>
				
				<div class="yellowt flex">
					<div class="yellowt_l">内容</div>
					<div class="yellowt_l">金额</div>
					<div class="yellowt_l">状态</div>
					<div class="yellowt_l">驳回原因</div>
					<div class="yellowt_l">时间</div>
				</div>
				<div v-if="isShowLoading">
				<div class="yellowb " v-if="orderList.length > 0">
					<div class="yellowb_l flex" v-for="(item,index) in orderList">
						<div class="yellowb_ll">{{item.name}}</div>
						<div class="yellowb_ll">{{item.rechargeMoney}}</div>
						<div class="yellowb_ll">
							<div v-if="item.toExamineState == 0">审核中</div>
							<div v-if="item.toExamineState == 1">已到账</div>
							<div v-if="item.toExamineState == 2">审核失败</div>
							<div v-if="item.toExamineState == 3" class="yellowb_llr">审核驳回</div>
						</div>
						<div class="yellowb_ll p1">
							 <el-tooltip class="item" effect="dark" :content="item.remark" placement="top">
							      
								  <div>{{item.remark}}</div>
							    </el-tooltip>
						
						</div>
						<div class="yellowb_ll">{{item.createTime}}</div>
					</div>
				</div>
				<none v-else></none>
				</div>
		
				<el-pagination
				v-if="orderList.length > 0"
				    class="fenye"
				     background
				     @size-change="handleSizeChange"
				     @current-change="handleCurrentChange"
				     :page-sizes="[10, 20, 30, 40]"
				     :page-size="100"
				     layout="total, sizes, prev, pager, next, jumper"
				     :current-page.sync='pages'
				     :total="total">
				    </el-pagination>
			</div>
		</div>
		<phone></phone>
		
	</div>
</template>

<script>
	import None from "@/components/none.vue"
    import logistics from "@/components/logistics.vue"
    import lookOrder from "@/components/lookOrder.vue"
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone,None},
		data() {
			return {
				lestId:2,
				value1:'',
				type:1,
				currentPage4:1,
				total:0,
				pages:1,
				orderList:[],
				tio:10,
				isShowLoading:true,
			}
		},
		mounted() {},
		created() {
			if(this.$route.params.type){
				this.type = this.$route.params.type
			}
			this.isShowLoading = false
			this.recordPage()
		},
		methods: {
			sousuo(){
				this.page = 1
				this.recordPage()
			},
			// 时间转
			timestampToTime(timestamp) {
			  var date = new Date(timestamp * 1000);
			  var Y = date.getFullYear() + "-";
			  var M =
			    (date.getMonth() + 1 < 10
			      ? "0" + (date.getMonth() + 1)
			      : date.getMonth() + 1) + "-";
			  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
			  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":";
			  var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
			  // var h = date.getHours() + ":";
			  // var m = date.getMinutes() + ":";
			  // var s = date.getSeconds();
			  return Y + M + D + h + m + s;
			},
			
			recordPage(){
				
				let params = {
					// startTime:this.value1[0],
					// endTime:this.value1[1],
					size:this.tio,
					current:this.pages,
					// flowType:this.type
				}
				if(this.value1 && this.value1.length > 0){
					params.startTime=this.value1[0]
					params.endTime=this.value1[1]
					params.startTime = Date.parse(new Date(params.startTime)) / 1000
					let name = params.endTime.replace('00:00:00',"23:59:59")
					// console.log(name,123)
					params.endTime = new Date(name).getTime() / 1000
				}
				// if(this.type != 2){
				// 	params.flowType = this.type
				// }
				// recordRecord  recordPage
				let url = ''
				if(this.type == 1){
					url = this.$apiUrls.recordPage
				}else{
					url = this.$apiUrls.recordRecord
				}
				this.$api.get(url,{params}).then((res)=> {
					// console.log(res,123)
					if (res.code == 0) {
						this.total = Number(res.data.total)
						this.orderList = res.data.records
						
						for (var i = 0; i < this.orderList.length; i++) {
							this.orderList[i].createTime = this.timestampToTime(this.orderList[i].createTime)
						}
						this.isShowLoading = true
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			handleSizeChange(val) {
			    console.log(`每页 ${val} 条`);
				this.tio = val
				this.recordPage()
			},
			handleCurrentChange(val) {
			    console.log(`当前页: ${val}`);
				this.pages = val
				this.recordPage()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.p1{
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		overflow: hidden;
	}
	::v-deep{
		.el-date-editor{
			width: 254px !important;
			border: none !important;
		}
		.active{
			background: #0DC3D6 !important;
		}
		.number{
			background: #F4F4F5 ;
		}
		.el-pagination {
		    text-align: center; 
			margin-top: 20px;
		}
		.el-range-separator{
			padding: 0 !important;
			margin: 0 3px !important;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 0px ;
		box-sizing: border-box;
		position: relative;
		.person_t{
			padding: 0 38px;
			box-sizing: border-box;
			.person_rt{
				font-size: 16px;
				font-weight: bold;
				color: #333333;
				border-bottom: 1px solid #E4EFF0;
				padding: 0 0 26px 0;
				margin: 0 0 20px 0;
				.imd{
					width: 7px;
					height: 12px;
					margin: 5px 12px 0;
				}
			}
		}
		
	}

	.wallet_h{
		margin: 32px 0 20px;
		.wallet_hl{
			line-height: 40px;
			font-size: 16px;
			font-weight: bold;
			color: #333333;
		}
		.wallet_hr{
			.wallet_hrl{
				width: 254px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 20px 20px 20px 20px;
				border: 1px solid #0DC3D6;
				margin: 0 14px 0 0;
				overflow: hidden;
			}
			.wallet_hrr{
				width: 72px;
				height: 40px;
				background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
				border-radius: 20px 20px 20px 20px;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 40px;
				justify-content: center;
				align-content: center;
				.imd{
					width: 16px;
					height: 16px;
					margin: 12px 4px 0 0 ;
				}
			}
			
		}
	}
	.bin{
		border-bottom: 2px solid #0DC3D6;
	}
	.fenye{
		position: absolute;
		bottom: 90px;
		left: 50%;
		transform: translateX(-50%);
	}
	.yellowt{
		padding: 0 34px;
		box-sizing: border-box;
		height: 40px;
		background: #FAFAFA;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #EEEEEE;
		.yellowt_l{
			width: 25%;
			font-size: 14px;
			font-weight: 400;
			color: #000000;
			line-height: 40px;
			text-align: center;
		}
		// .yellowt_l:nth-child(1){
		// 	margin: 0 0 0 83px;
		// }
		// .yellowt_l:nth-child(2){}
		// .yellowt_l:nth-child(3){}
		// .yellowt_l:nth-child(4){}
	}
	.yellowb{
		padding: 0 34px;
		box-sizing: border-box;
		.yellowb_l{
			border-bottom: 1px solid #EEEEEE;
			.yellowb_ll{
				width: 25%;
				font-size: 14px;
				font-weight: 400;
				color: #0A0A0D;
				line-height: 63px;
				text-align: center;
				.yellowb_llr{
					color: #FF2B2B;
				}
			}
		}
	}
	.person_rtr{
		width: 146px;
		height: 46px;
		border-radius: 28px 28px 28px 28px;
		opacity: 1;
		border: 1px solid #0DC3D6;
		font-size: 16px;
		font-weight: 400;
		line-height: 46px;
		text-align: center;
	}
	.chongzhi{
		line-height: 46px;
		.imd{
			margin-top: 15px !important;
		}
	}
	.pding{
		padding: 0 0 14px 0 !important;
	}
</style>