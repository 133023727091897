<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="inded('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r">
				<div class="person_rt">个人信息</div>
				<div class="person_rh flex">
					<div class="person_rhl lin4">头像</div>
					<el-upload
					  class="upload-demo"
					  :action="url"
					  ref="targetUpload"
					  @clearFiles="clearFiles"
					  multiple
					  :limit="1"
					  :on-success="onSuccess"
					  :on-error="onError"
					  :before-upload="beforeAvatarUpload"
					  >
					 	<div class="person_rhd relative">
					 		
					 		<div class="absolute person_rhdf">上传图片</div>
					 		<img class="imd" v-if="filePath" :src="filePath" alt="" srcset="">
					 		<img class="imd" v-else src="../../static/image/27.png" alt="" srcset="">
					 	</div>
					</el-upload>
					
				</div>
				<div class="person_rh flex">
					<div class="person_rhl">ID</div>
					<div class="person_rhr">{{user.userId}}</div>
				</div>
				<div class="person_rh flex">
					<div class="person_rhl lin4 bixuan">昵称</div>
					<div class="person_rhr">
						<el-input v-model="input" placeholder="请输入昵称"></el-input>
					</div>
				</div>
				<div class="person_rh flex">
					<div class="person_rhl">性别</div>
					<div class="person_rhr">
						<el-radio v-model="radio" label="1">男</el-radio>
						<el-radio v-model="radio" label="2">女</el-radio>
						<el-radio v-model="radio" label="3">保密</el-radio>
					</div>
				</div>
				<div class="person_rh flex">
					<div class="person_rhl lin4 bixuan">生日</div>
					<div class="person_rhr">
						<el-date-picker
						      v-model="value1"
						      type="date"
						      placeholder="选择日期"
							  :picker-options="pickerOptions"
							  value-format="yyyy-MM-dd"
							  >
						    </el-date-picker>
					</div>
				</div>
				<div class="save pointer" @click="currentEdit">保存</div>
			</div>
		</div>
		<phone></phone>
	</div>
</template>

<script>
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone},
		data() {
			return {
				lestId:0,
				input:'',
				radio:'3',
				value1:'',
				user:'',
				url:'',
				fileId:'',
				filePath:'',
				pickerOptions: {
				   disabledDate(time) {
				      return time.getTime() > Date.now();
				   }
				},
			}
		},
		mounted() {},
		created() {
			this.currentInfo()
			this.url ='https://api.beixusmt.com/'+ this.$apiUrls.uploadFile
		},
		methods: {
			
			currentEdit(){
				if(!this.input){
					this.$message.error('请填写昵称');
					return
				}
				if(!this.value1){
					this.$message.error('请填写生日信息！');
					return
				}
				// return
				let page = {
					// sex:this.radio == '1' ? 'M' : 'F',
					nickName:this.input,
					fileId:this.fileId,
					birthDate:this.value1,
				}
				if(this.radio == 1){
					page.sex = 'M'
				}else if(this.radio == 2){
					page.sex = 'F'
				}else if(this.radio == 3){
					page.sex = 'D'
				}
				this.$api.post(this.$apiUrls.currentEdit,page).then((res)=> {
				   if (res.code == 0) {
				   	this.$message.success('修改成功！');
					this.currentInfo()
					this.$parent.parentPrint();
				   }else{
				   	this.$message.error(res.msg);
				   	return
				   }
					// console.log(res,123)
				})
			},
			currentInfo(){
				this.$api.get(this.$apiUrls.currentInfo,).then((res)=> {
				    if (res.code == 0) {
						this.user = res.data
						this.input = this.user.nickName ? this.user.nickName : '新用户'
						// this.radio =  ? '1' : '2'
						if(this.user.sex == 'M'){
							this.radio = 1
						}else if(this.user.sex == 'F'){
							this.radio = 2
						}else{
							this.radio = 3
						}
						this.value1 = this.user.birthDate
						if(this.user.fileInfo){
							this.fileId = this.user.fileInfo.fileId
							this.filePath = this.user.fileInfo.filePath
						}else{
							this.fileId = this.user.userId
						}
						
				    }
					// console.log(res,123)
				})
			},
			// 上传成功
			onSuccess(response, file, fileList){
				this.filePath = response.data.filePath
				this.fileId = response.data.fileId
				this.clearFiles();
				console.log(response, file, fileList,123)
			},
			// 上传失败
			onError(err, file, fileList){
				this.$message.error('上传失败');
			},
			clearFiles(){
			    this.$refs.targetUpload.clearFiles()
				console.log('执行')
			},

			beforeAvatarUpload(file) {
				// if(file.type == 'jpg' || file.type == 'jpg')

				const isJPG = (file.type === 'image/jpeg') || (file.type === 'image/jpg') || (file.type === 'image/png')
				const isLt2M = file.size / 1024 / 1024 < 2;
				console.log(isJPG,isLt2M,2)
				if (!isJPG) {
				  this.$message.error('上传头像图片只能是 JPG PNG格式!');
				}
				if (!isLt2M) {
				  this.$message.error('上传头像图片大小不能超过 2MB!');
				}
			    return isJPG && isLt2M;
			},
			inded(){
				
			},
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-input{
			width: 320px;
			height: 48px;
			input{
				height: 48px;
			}
		}
		.el-radio__inner{
			width: 20px;
			height: 20px;
		}
		.el-radio__input.is-checked .el-radio__inner::after {
		    background: none;
		}

		.el-radio__input.is-checked .el-radio__inner{
			width: 20px;
			height: 20px;
			background: url(../../static/image/25.png) 0 0 no-repeat !important;
			background-size: 100% 100% !important;
		}
		.el-radio__input.is-checked+.el-radio__label{
			color: #333 !important;
			font-weight: 600 !important;
		}
		.el-date-editor.el-input, .el-date-editor.el-input__inner{
			width: 320px;
		}
		 .el-input__inner {
		        padding-left: 15px;
		        padding-right: 30px;
		    }
		 
		    .el-input__prefix {
		        // color: blue;
		        left: initial;
		        right: 5px;
		    }
		    &.date-close {
		        .el-icon-date {
		            display: none;
		        }
		    }
		.el-upload-list {
			display: none;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
	.lin4{
		line-height: 48px;
	}
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 51px 0;
		}
		.person_rh{
			margin-bottom: 39px;
			.person_rhl{
				width: 40%;
				text-align: right;
				// line-height: 74px;
				padding: 0 18px 0 0px;
			}
			.person_rhd{
				width: 74px;
				height: 74px;
				border-radius: 74px;
				overflow: hidden;
				.imd{
					width: 74px;
					height: 74px;
				}
				.person_rhdf{
					bottom: 0px;
					width: 100%;
					height: 29px;
					line-height: 29px;
					text-align: center;
					background: rgba(0, 0, 0, .4);
					color: #FFFFFF;
					font-size: 12px;
				}
			}
		}
		.save{
			width: 184px;
			height: 54px;
			background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
			border-radius: 28px 28px 28px 28px;
			font-size: 18px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 54px;
			text-align: center;
			margin: 51px auto 130px;
		}
	}
	.confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
</style>