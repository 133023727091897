<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_t">
					<div class="person_rt flex space-between">
						<div>我的钱包</div>
						<div class="flex bodycolor pointer" @click="tiaohuan('yellow',1)">
							<img class="imd" src="../../static/image/49.png" alt="" srcset="">
							<div>充值记录</div>
						</div>
					</div>
					<div class="wallet_t">
						<div>余额(元)</div>
						<div class="wallet_th flex">
							<!-- <p>￥</p> -->
							￥{{money || 0}}
						</div>
						<div class="wallet_tb pointer" @click="tiaohuan('transfer')">余额转出</div>
					</div>
					<div class="wallet_h flex space-between">
						<div class="wallet_hl">余额明细</div>
						<div class="wallet_hr flex">
							<div class="wallet_hrl">
								<el-date-picker
								      v-model="value1"
								      type="daterange"
								      range-separator="至"
								      start-placeholder="开始日期"
								      end-placeholder="结束日期"
									  value-format="yyyy-MM-dd HH:mm:ss"
									  >
								    </el-date-picker>
							</div>
							<div class="wallet_hrr flex pointer" @click="sousuo">
								<img class="imd" src="../../static/image/38.png" alt="" srcset="">
								<div>搜索</div>
							</div>
						</div>
					</div>
				</div>
				<div class="person_h flex">
					<div class="person_hl pointer" :class="type == 2 ?'bodycolor bin':''" @click="typTo(2)">全部</div>
					<div class="person_hh pointer" :class="type == '0' ?'bodycolor bin':''" @click="typTo('0')">收入记录</div>
					<div class="person_hr pointer" :class="type == 1 ?'bodycolor bin':''" @click="typTo(1)">支出记录</div>
				</div>
				<div v-if="isShowLoading">
				    <div class="person_d" v-if="orderList.length > 0">
				    	<div class="person_dt flex" v-for="(item,index) in orderList">
				    		<div class="person_dt1">{{item.flowExplain}}</div>
				    		<div class="person_dt2" v-if="item.flowType == 0">+{{item.flowMoney}}</div>
				    		<div class="person_dt3" v-else>{{item.flowMoney}}</div>
				    		<div class="person_dt4">{{item.createTime}}</div>
				    	</div>
				    </div>
				    <none v-else></none>
				</div>
				<el-pagination
				    v-if="orderList.length > 0"
				      background
				      @size-change="handleSizeChange"
				      @current-change="handleCurrentChange"
				      :page-sizes="[10, 20, 30, 40]"
				      :page-size="100"
				      layout="total, sizes, prev, pager, next, jumper"
					  :current-page.sync='pages'
				      :total="total">
				    </el-pagination>
			</div>
		</div>
		<phone></phone>
		
	</div>
</template>

<script>
	import None from "@/components/none.vue"
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone,None},
		data() {
			return {
				lestId:2,
				value1:'',
				type:2,
				currentPage4:1,
				money:'',
				total:0,
				pages:1,
				orderList:[],
				tio:10,
				isShowLoading:true,
			}
		},
		mounted() {},
		created() {
			this.isShowLoading = false
			this.userConnect()
			this.flowingPage()
		},
		methods: {
			sousuo(){
				this.pages = 1
				this.flowingPage()
			},
			// 时间转
			timestampToTime(timestamp) {
			  var date = new Date(timestamp * 1000);
			  var Y = date.getFullYear() + "-";
			  var M =
			    (date.getMonth() + 1 < 10
			      ? "0" + (date.getMonth() + 1)
			      : date.getMonth() + 1) + "-";
			  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
			  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":";
			  var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
			  // var h = date.getHours() + ":";
			  // var m = date.getMinutes() + ":";
			  // var s = date.getSeconds();
			  return Y + M + D + h + m + s;
			},
			// 余额明细列表
			flowingPage(){
				let params = {
					
					size:this.tio,
					current:this.pages,
					// flowType:this.type
				}
				if(this.value1 && this.value1.length > 0){
					params.startTime=this.value1[0]
					params.endTime=this.value1[1]
					let name = params.endTime.replace('00:00:00',"23:59:59")
					params.startTime = Date.parse(new Date(params.startTime)) / 1000
					params.endTime = new Date(name).getTime() / 1000
				}
				
				if(this.type != 2){
					params.flowType = this.type
				}
				
				this.$api.get(this.$apiUrls.flowingPage,{params}).then((res)=> {
					console.log(res,123)
					if (res.code == 0) {
						this.total = Number(res.data.total)
						this.orderList = res.data.records
						
						for (var i = 0; i < this.orderList.length; i++) {
							this.orderList[i].createTime = this.timestampToTime(this.orderList[i].createTime)
						}
						this.isShowLoading = true
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 我的金额
			userConnect(){
				this.$api.get(this.$apiUrls.userConnect).then((res)=> {
					if (res.code == 0) {
						this.money = res.data.money
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			typTo(type){
				this.type = type
				this.pages = 1
				this.flowingPage()
			},
			handleSizeChange(val) {
			    // console.log(`每页 ${val} 条`);
				this.tio = val
				this.pages = 1
				this.flowingPage()
			},
			handleCurrentChange(val) {
			    // console.log(`当前页: ${val}`);
				this.pages = val
				this.flowingPage()
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-date-editor{
			width: 254px !important;
			border: none !important;
		}
		.el-range-input{
			font-size: 12px !important;
		}
		.active{
			background: #0DC3D6 !important;
		}
		.number{
			background: #F4F4F5 ;
		}
		.el-pagination {
		    text-align: center; 
			margin-top: 20px;
		}
		.el-range-separator{
			padding: 0 !important;
			margin: 0 3px !important;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 0 ;
		box-sizing: border-box;
		.person_t{
			padding: 0 38px;
			box-sizing: border-box;
			.person_rt{
				font-size: 16px;
				font-weight: bold;
				color: #333333;
				border-bottom: 1px solid #E4EFF0;
				padding: 0 0 26px 0;
				margin: 0 0 20px 0;
				.imd{
					width: 17px;
					height: 17px;
					margin: 0px 9px 0 0;
				}
			}
		}
		
	}
	.wallet_t{
		width: 406px;
		height: 190px;
		background: url(../../static/image/42.png) 0 0 no-repeat;
		background-size: 100% 100%;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		padding: 35px 0 0 0;
		box-sizing: border-box;
		.wallet_th{
			margin: 10px 0 0 0;
			font-size: 37px;
			justify-content: center;
			align-content: center;
		}
		.wallet_tb{
			width: 96px;
			height: 32px;
			background: rgba(250,250,250, .8);
			color: #168EB1;
			line-height: 32px;
			margin: 16px auto 0;
		}
	}
	.wallet_h{
		margin: 32px 0 20px;
		.wallet_hl{
			line-height: 40px;
			font-size: 16px;
			font-weight: bold;
			color: #333333;
		}
		.wallet_hr{
			.wallet_hrl{
				width: 254px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 20px 20px 20px 20px;
				border: 1px solid #0DC3D6;
				margin: 0 14px 0 0;
				overflow: hidden;
			}
			.wallet_hrr{
				width: 72px;
				height: 40px;
				background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
				border-radius: 20px 20px 20px 20px;
				font-size: 14px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 40px;
				justify-content: center;
				align-content: center;
				.imd{
					width: 16px;
					height: 16px;
					margin: 12px 4px 0 0 ;
				}
			}
			
		}
	}
	.person_h{
		width: 100%;
		height: 40px;
		background: #FAFAFA;
		border: 1px solid #EEEEEE;
		padding: 0 0 0 38px;
		.person_hl{
			font-size: 14px;
			font-weight: bold;
			color: #000000;
			line-height: 40px;
			
		}
		.person_hr,
		.person_hh{
			font-size: 14px;
			font-weight: 400;
			color: #000000;
			line-height: 40px;
		}
		.person_hh{
			margin: 0 0 0 116px;
		}
		.person_hr{
			margin: 0 0 0 89px;
		}
	}
	
	.person_d{
		padding: 0 30px;
		box-sizing: border-box;
		.person_dt{
			height: 62px;
			border-bottom: 1px solid #E4EFF0;
			div{
				width: 33.3%;
				text-align: left;
				line-height: 62px;
				font-size: 14px;
				font-weight: 400;
			}
			.person_dt1{
				color: #0A0A0D;
			}
			.person_dt2{
				color: #FF2B2B;
			}
			.person_dt3{
				color: #808080;
			}
			.person_dt4{
				color: #999999;
			}
		}
	}
	
	.bin{
		border-bottom: 2px solid #0DC3D6;
	}
	
</style>