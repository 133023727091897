<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_rt flex space-between">
					<div>发票管理</div>
					<div class="flex bodycolor pointer" @click="tiaohuan('invoicing')">
						<img class="imd" src="../../static/image/44.png" alt="" srcset="">
						<div>开票记录</div>
					</div>
				</div>
				<div class="invoiceT" v-for="(item,index) in lootList">
					<div class="invoiceT_t flex ">
						<div class="invoiceT_tl p1">{{item.name}}</div>
						<div class="invoiceT_tr flex space-between">
							<div class="invoiceT_trl flex pointer">
								<img v-if="item.sfDefault == 1" class="imd" src="../../static/image/18.png" alt="" srcset="">
								<div v-if="item.sfDefault == 1">默认</div>
								<img v-if="item.sfDefault != 1" @click="sfDefault(item)" class="imd" src="../../static/image/17.png" alt="" srcset="">
								<div v-if="item.sfDefault != 1" @click="sfDefault(item)">设为默认</div>
							</div>
							<div class="invoiceT_trr flex pointer">
									<img @click="detal(item)" class="imd" src="../../static/image/48.png" alt="" srcset="">
									<div @click="detal(item)">删除</div>
									<img @click="tiaohuan('addInvoice',2,item.id)" class="imd" src="../../static/image/19.png" alt="" srcset="">
									<div @click="tiaohuan('addInvoice',2,item.id)">编辑</div>
							</div>
						</div>
					</div>
					<div class="invoiceT_b flex">
						<div class="invoiceT_bl">税号：</div>
						<div class="invoiceT_br">{{item.dutyParagraph}}</div>
					</div>
					<div class="invoiceT_b flex">
						<div class="invoiceT_bl">联系方式：</div>
						<div class="invoiceT_br">{{item.phone}}</div>
					</div>
				</div>
				<div class="invoiceB flex pointer" @click="tiaohuan('addInvoice',1)">
					<img class="imd" src="../../static/image/47.png" alt="" srcset="">
					<div>新增发票</div>
				</div>
			</div>
		</div>
		<phone></phone>
		<el-dialog
		  title="提示"
		  :visible.sync="detalType"
		  width="30%">
		  <!-- <span style="text-align: center;">？</span> -->
		  <div class="tish">确定删除该发票么?</div>
		  <div class="anniu flex">
			  <div class="anniu_l pointer" @click="detalType = false">取 消</div>
			  <div class="anniu_r pointer" @click="detailQd">确 定</div>
		  </div>
		  <!-- <span slot="footer" class="dialog-footer">
		    <el-button @click="detalType = false">取 消</el-button>
		    <el-button type="primary" @click="detailQd">确 定</el-button>
		  </span> -->
		</el-dialog>
	</div>
</template>

<script>
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone},
		data() {
			return {
				lestId:3,
				lootList:[],
				id:'',
				detalType:false
			}
		},
		mounted() {
			this.addressList()
		},
		created() {
			
		},
		methods: {
			sfDefault(item){
				let params = {
					id:item.id
				}
				this.$api.post(this.$apiUrls.sfDefault,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('设置成功！');
						// this.detalType = false
						this.addressList() 
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 发票列表
			addressList(){
				this.$api.get(this.$apiUrls.addressList).then((res)=> {
					if (res.code == 0) {
						this.lootList = res.data
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			detailQd(){
				
				let params = {
					id:this.id
				}
				this.$api.post(this.$apiUrls.addressDel,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('删除成功！');
						this.detalType=false
						this.addressList() 
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			detal(item){
				this.id = item.id
				this.detalType = true
			},
			tiaohuan(url,type,id){
				let page = {}
				if(type){
					page.type = type
				}
				if(id){
					page.id = id
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
	
		.el-pagination {
		    text-align: center; 
			margin-top: 20px;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 6px 0;
			.imd{
				width: 17px;
				height: 17px;
				margin: 3px 9px 0 0;
			}
		}
	}
	.invoiceT{
		width: 100%;
		height: 172px;
		border-radius: 10px 10px 10px 10px;
		opacity: 1;
		border: 1px solid #EEEEEE;
		margin: 26px 0 0 0;
		padding: 0 22px ;
		box-sizing: border-box;
		.invoiceT_t{
			height: 66px;
			border-bottom: 1px solid #EEEEEE;
			.invoiceT_tl{
				width: 50%;
				font-size: 22px;
				font-weight: bold;
				color: #000000;
				line-height: 66px;
				padding: 0 20px 0 0;
			}
			.invoiceT_tr{
				width: 50%;
				line-height: 66px;
				font-size: 16px;
				font-family: PingFang SC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
				.invoiceT_trl{
					.imd{
						width: 22px;
						height: 22px;
						margin: 22px 8px 0 0;
					}
				}
				.invoiceT_trr{
					.imd{
						width: 18px;
						height: 18px;
						margin: 24px 8px 0 41px;
					}
				}
			}
		}
		.invoiceT_b{
			margin: 18px 0 0 0;
			.invoiceT_bl{
				width: 100px;
				margin: 0 16px 0 0 ;
				font-size: 20px;
				font-weight: 400;
				color: #666666;
				text-align: left;
			}
			.invoiceT_br{
				font-size: 20px;
				font-weight: 400;
				color: #000000;
			}
		}
	}
	.invoiceB{
		margin: 45px 0 0 0;
		width: 184px;
		height: 53px;
		background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
		border-radius: 28px 28px 28px 28px;
	    justify-content: center;
		align-content: center;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 53px;
		.imd{
			width: 24px;
			height: 24px;
			margin: 13px 10px 0 0;
		}
	}
	.tish{
		text-align: center;
	}
	.anniu{
		justify-content: center;
		align-content: center;
		margin: 70px 0 0 0;
		.anniu_l,
		.anniu_r{
			width: 140px;
			height: 42px;
			border-radius: 21px 21px 21px 21px;
			font-size: 16px;
			font-weight: 400;
			line-height: 42px;
			text-align: center;
		}
		.anniu_l{
			border: 1px solid #D8D8D8;
			color: #333333;
		}
		.anniu_r{
			color: #FFFFFF;
			background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
			margin: 0 0 0 60px;
		}
	}
</style>