<template>
	<div class="page">
		
		<el-dialog
		  title="提示"
		  @close="chufa"
		  :visible.sync="dialog"
		  width="40%">
		   <div slot="title" class="dialog-title lookt">
			    <img class="lookt_l" src="../../static/image/2.png" alt="">
		        <span class="title-text">抬头选择</span>
		    </div>
		    <div class="look">
			    <div class="lookList flex pointer" v-for="(item,index) in lootList" @click="lootTop(item)">
				    <img v-if="lootId == item.id" class="lookList_l" src="../../static/image/18.png" alt="">
				    <img v-else class="lookList_l" src="../../static/image/17.png" alt="">
					<div class="lookList_r">
						<div class="lookList_rt flex space-between ">
							<div class="lookList_rtl p1">{{item.name}}</div>
							<div class="lookList_rtr flex" @click.stop="edit(item)">
								<img class="lookList_rtri" src="../../static/image/19.png" alt="">
								<div class="lookList_rtrd">编辑</div>
							</div>
						</div>
						<div class="lookList_rd flex">
							<div class="lookList_rdl">税号：</div>
							<div class="lookList_rdr p1">{{item.dutyParagraph}}</div>
						</div>
						<div class="lookList_rd flex">
							<div class="lookList_rdl">联系方式：</div>
							<div class="lookList_rdr p1">{{item.phone}}</div>
						</div>
					</div>
				</div>
		    </div>
		    <div class="add bodycolor pointer" @click="addLook" >添加抬头</div>
		    <div class="nofa pointer" @click="noLook" v-if="type == 1">不开发票</div>
		    <div class="nofa pointer" @click="queidng" v-else>确定</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
		    dialogVisible:{
				type:Boolean,
				default: false
			},
			type:{
				type:Number,
				default:1,
			}
		},
		data() {
			return {
                dialog: false,
				id:1,
				lootList:[],
				lootId:'',
			}
		},
		watch:{
			dialogVisible(n,o){
				// console.log(n,o)
				this.dialog=n
			}
		},
		mounted() {
		},
		created() {
			this.addressList()
		},
		methods: {
			// 发票列表
			addressList(){
				this.$api.get(this.$apiUrls.addressList).then((res)=> {
					if (res.code == 0) {
						this.lootList = res.data
						for (var i = 0; i < this.lootList.length; i++) {
							if(this.lootList[i].sfDefault == 1){
								this.lootId = this.lootList[i].id
							}
						}
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			sing(){
				console.log('执行123')
				this.addressList()
			},
			addLook(){
				this.$emit('addLook','')
			},
			noLook(){
				this.dialog = false
				this.$emit('noLook','')
			},
			queidng(){
				this.$emit('callberr',this.lootId,'x')
			},
			// 选择数据
			lootTop(item){
				this.lootId = item.id
				console.log(item.id,item.name,123)
				this.$emit('callber',item.id,item.name)
				// this.dialog = false
			},
			// 编辑
			edit(item){
				// console.log('编辑')
				this.$emit('edit',item.id)
			},
			// 点击背景
			chufa(){
				console.log('触发2')
				this.$emit('callber','')
			},
			// 关闭
			guanbi(){
				console.log('触发1')
				this.dialog = false
				this.$emit('callber',this.id)
			} 
		}
	}
</script>

<style lang="scss" scoped>
	.add{
		width: 462px;
		height: 56px;
		background: rgba(13,195,214,0.06);
		border-radius: 28px 28px 28px 28px;
		opacity: 1;
		border: 1px solid #0DC3D6;
		font-size: 18px;
		font-weight: 400;
		line-height: 56px;
		margin: 0 auto;
		text-align: center;
	}
	.nofa{
		font-size: 18px;
		font-weight: 400;
		line-height: 56px;
		margin: 17px auto 0;
		text-align: center;
		color: #fff;
		width: 462px;
		height: 56px;
		background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
		border-radius: 28px 28px 28px 28px;
	}
	.look{
		max-height: 340px;
		overflow-y: auto;
		margin: 0 0 20px 0;
		.lookList{
			width: 98%;
			height: 172px;
			border-radius: 10px 10px 10px 10px;
			opacity: 1;
			border: 1px solid #EEEEEE;
			margin: 0 0 26px 0;
			padding: 0 19px;
			box-sizing: border-box;
			.lookList_l{
				width: 26px;
				height: 26px;
				margin: 74px 19px 0 0;
			}
			.lookList_r{
				width: calc(100% - 45px);
				.lookList_rt{
					padding: 19px 0 17px;
					box-sizing: border-box;
					border-bottom: 1px solid #EEEEEE;
					.lookList_rtl{
						font-size: 22px;
						font-weight: bold;
						color: #000000;
					}
					.lookList_rtr{
						.lookList_rtri{
							width: 22px;
							height: 22px;
							margin: 6px 0 0 0;
						}
						.lookList_rtrd{
							margin: 0 0 0 12px;
							font-size: 22px;
							font-family: PingFang SC-Regular, PingFang SC;
							font-weight: 400;
							color: #666666;
						}
					}
				}
				.lookList_rd{
					margin: 17px 0 0 0;
					.lookList_rdl{
						width: 100px;
						text-align: left;
						font-size: 20px;
						font-weight: 400;
						color: #666666;
					}
					.lookList_rdr{
						font-size: 20px;
						font-weight: 400;
						color: #000000;
						margin: 0 0 0 16px;
					}
				}
			}
		}
	}
	.lookt{
		.lookt_l{
			width: 3px;
			height: 14px;
		}
		.title-text{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			margin: 0 0 0 8px;
		}
	}


</style>