<template>
	<div class="logn">
		<img style="width: 100%;height: 100%;" src="../../static/image/1.png" alt="" srcset="">
		<div class="lognb" v-if="!codeDype" :class="loginType != 1 ? 'lognheig' : ''">
			<div class="lognb_t" v-if="loginType == 1">欢迎登录</div>
			<div class="lognb_t logncode" v-if="loginType == 2">注册账号</div>
			<div class="lognb_t logncode" v-if="loginType == 3">忘记密码</div>
			<div class="lognb_h">
				<div class="int">
					<el-input v-model="phone" :maxlength='11' placeholder="请输入手机号"></el-input>
				</div>
				<div class="lotCn_rb flex" v-if="loginType==2 || loginType==3 || !codeType">
					<el-input v-model="code" :maxlength='4' placeholder="请输入验证码"></el-input>
					<div class="lotCn_rbr pointer" >
						<div @click="tioCod" v-if="!timTpe">获取验证码</div>
						<div v-else>{{time}}秒后重新获取</div>
					</div>
				</div>
				<div class="int" v-if="loginType==2 || loginType==3 ||codeType">
					<el-input ref="pwdInput"
					          :type="pwdObj.pwdType"
					          v-model="password"
							  placeholder="请输入密码(6~12位字母+数字)"
					          @focus="focusEnd($event)">
					<!-- <el-input v-model="password" show-password placeholder="请输入密码(6~12位字母+数字)"> -->
						 <img slot="suffix"
						            class="img-sty"
						            :src="pwdObj.pwdType === 'text' ? img2 : img1 "
						            fit="scale-down"
									@click="changeye('pwdType','pwdInput')"
						            >
					    
					</el-input>
				</div>
				<div class="code pointer" v-if="codeType && loginType == 1" @click="codeTo">验证码登录</div>
				<div class="code pointer" v-if="!codeType && loginType == 1" @click="codeTo">密码登录</div>
				<div class="logon pointer" v-if="loginType == 1" @click="login">登录</div>
				<div class="logon pointer forget" v-if="loginType == 2" @click="zhuce">注册</div>
				<div class="logon pointer forget" v-if="loginType == 3" @click="editPassword">确定</div>
				<div class="lognb_hd flex space-between" v-if="loginType == 1">
					<div class="pointer" @click="register()">注册账号</div>
					<div class="pointer" @click="forget()">忘记密码</div>
				</div>
				<div class="lognd flex justify-content" v-if="loginType == 2 || loginType == 3">
					<p style="color: #999999;">已有账号？</p>
					<p class="bodycolor pointer" @click="logonTop()">去登录</p>
				</div>
			</div>
			<div class="lognb_b flex">
				<el-checkbox v-model="radio" style="margin: 16px 10px 0 0;"></el-checkbox>
				<div class="wen">我同意</div>
				<div class="wen pointer" @click="xieyi(1)">《用户注册协议》</div>
				<div class="wen">和</div>
				<div class="wen pointer" @click="xieyi(2)">《隐私政策》</div>
				<div class="wen toInde pointer" @click="toIndex">去首页</div>
			</div>
		</div>
		
		<div class="fangshu" v-if="codeDype">
			<!-- <img style="width: 100%;height: 1005;" :src="bgimgs[0]" alt="" srcset=""> -->
			<div class="fangshu_t flex space-between">
				<div>请完成安全验证</div>
				<i class="el-icon-close pointer" @click="codeDype = false"></i>
			</div>
			<div class="fangshu_h">
				<div class="page-slidecode">
				   <slide-verify
				       :l="42"
				       :r="10"
				       :w="460"
				       :h="250"
				       :imgs="bgimgs"
					   class="page-slidecodl"
				       @success="onSuccess"
				       @fail="onFail"
				       @refresh="onRefresh"
				       :slider-text="text"
				     ></slide-verify>
				 </div>
			</div>
				     <!-- <div>{{msg}}</div> -->
			
		</div>
		<!-- <el-card> -->
		
		<!-- </el-card> -->
		<el-dialog
		  :title="agreeTile"
		  :visible.sync="dialogVisible"
		  width="50%"
		  >
		    <div class="content" v-html="agreeTile == '隐私政策' ? content2 : content"></div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				pwdObj: { pwdType: 'password'},

				msg: "",
				// bgimgs1:[require('../../static/image/1.png')],
				bgimgs:[],	//	如果使用网络图片资源就用该值
				text: "向右滑动完成验证",
				phone:'',
				password:'',
				code:'',
				radio:false,
				agreeTile:'',
				dialogVisible:false,
				content:'',
				content2:'',
				codeType:true,
				time:60,
				timTpe:false,
				td:'',
				loginType:1,
				codeDype:false,
				user:'',
				img1:require('../../static/image/52.png'),
				img2:require('../../static/image/53.png'),
				typeof:false,
			}
		},
		mounted() {},
		created() {
			// this.yanCode()
			if(this.$route.params.type){
				// .type = this.$route.params.type
				if(this.$route.params.type == 1){
					this.loginType = 1
				}else if(this.$route.params.type == 2){
					this.loginType = 2
				}else{
					this.loginType = 1
				}
			}
			if (this._isMobile()) {
			    console.log('手机端')
				this.typeof = true
			} else {
				this.typeof = false
			    console.log('pc端')
			}
			this.configCode1()
			this.configCode2()
		},
		methods: {
			_isMobile () {
			    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
			    return flag
			},
			toIndex(){
				window.sessionStorage.setItem('statusType', 0);
				this.$router.push({
					name: 'index',
					params: {},
				});
			},
			editPassword(){
				if(!this.phone){
					this.$message.error('请输入手机号');
					return
				}else{
                    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
					console.log(reg.test(this.phone))  
					if(!reg.test(this.phone)){
						this.$message.error('请输入正确的手机号！');
						return
					}
				}
				if(!this.code){
					this.$message.error('请输入验证码');
					return
				}
				if(!this.password){
					this.$message.error('请输入密码');
					return
				}
				if(!this.radio){
					this.$message.error('请勾选协议');
					return
				}
				let params = {
					// type: '1',
					loginPassword : this.password,
					code : this.code,
					mobileOrEmail:this.phone
				}
			
				this.$api.post(this.$apiUrls.forgetPassword,params).then((res)=> {
					if (res.code == 0) {
						clearInterval(this.td);
						this.$message.success('修改成功！');
						this.loginType = 1
						this.detal()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			changeye(typeName, refName) {
						this.$set(
							this.pwdObj,
							`${typeName}`,
							this.pwdObj[`${typeName}`] === 'password' ? 'text' : 'password'
						)
						this.$refs[`${refName}`].focus()
					},
					//点击查看密码图标使光标定位到最后
					focusEnd(e) {
						//input获取光标显示在最后
						const obj = e.srcElement,
							// obj.focus()
							len = obj.value.length
						//光标定位要加上 setTimeOut，不然就会重新光标定位失败
						setTimeout(() => {
							obj.selectionStart = obj.selectionEnd = len
						}, 60)
					},
			// 注册协议
			configCode1(){
				let params = {
					code:'ZCXY'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 隐私协议
			configCode2(){
				let params = {
					code:'YSZC'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content2 = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 注册账号
			zhuce(){
				if(!this.phone){
					this.$message.error('请输入手机号');
					return
				}else{
                    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
					console.log(reg.test(this.phone))  
					if(!reg.test(this.phone)){
						this.$message.error('请输入正确的手机号！');
						return
					}
				}
				if(!this.code){
					this.$message.error('请输入验证码');
					return
				}
				if(!this.password){
					this.$message.error('请输入密码');
					return
				}
				if(!this.radio){
					this.$message.error('请勾选协议');
					return
				}
				let params = {
					code:this.code,
					loginPassword:this.password,
					mobileOrEmail:this.phone
				}
				this.$api.post(this.$apiUrls.loginRegister,params).then((res)=> {
				    if (res.code == 0) {
						this.$message.success('注册成功');
						this.logonTop()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			login(){
				if(!this.phone){
					this.$message.error('请输入手机号');
					return
				}else{
                    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
					console.log(reg.test(this.phone))  
					if(!reg.test(this.phone)){
						this.$message.error('请输入正确的手机号！');
						return
					}
				}
				if(this.codeType){
					if(!this.password){
						this.$message.error('请输入密码');
						return
					}
				}else{
					if(!this.code){
						this.$message.error('请输入验证码');
						return
					}
				}
				if(!this.radio){
					this.$message.error('请勾选协议');
					return
				}
				let params = {
					userName:this.phone,
				}
				if(this.codeType){
					params.loginType = 'PASSWORD'
					params.password = this.password
				}else{
					params.loginType = 'SMS'
					params.code = this.code
				}
				this.$api.post(this.$apiUrls.login,params).then((res)=> {
					console.log(res,1234)
				    if (res.code == 0) {
						
						// this.user = JSON.stringify(res.data)
						this.user = res.data
						if(this.typeof){
							window.sessionStorage.setItem('token', this.user.access_token);
							window.sessionStorage.setItem('token_type', this.user.token_type);
							window.sessionStorage.setItem('user',JSON.stringify(this.user));
							window.sessionStorage.setItem('statusType', 0);
							this.$message.success('登录成功');
							setTimeout(() => {
							    this.$router.push({
							    	name: 'index',
							    	params: {},
							    });
							}, 1000)
						}else{
							this.yanCode()
						}
						
						
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
				
			},

			yanCode(){
				this.$api.get(this.$apiUrls.loginCode).then((res)=> {
				    if (res.code == 0) {
						this.codeDype = true
						this.bgimgs = [0]
						let url = 'data:image/png;base64,'+ res.data.image.toString()
						this.bgimgs[0]=url
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			onSuccess() {
			  // this.msg = "登录成功~";
			  window.sessionStorage.setItem('token', this.user.access_token);
			  window.sessionStorage.setItem('token_type', this.user.token_type);
			  window.sessionStorage.setItem('user',JSON.stringify(this.user));
			  window.sessionStorage.setItem('statusType', 0);
			  this.$message.success('登录成功');
			  setTimeout(() => {
			      this.$router.push({
			      	name: 'index',
			      	params: {},
			      });
			  }, 1000)
			  
			  
			},
			onFail() {
			  this.msg = "登录失败！";
			},
			onRefresh() {
				console.log('刷新')
			  this.yanCode()
			},
			// 清空数据
			detal(){
				this.phone=''
				this.password=''
				this.code=''
				this.timTpe = false
				clearInterval(this.td);
				this.time = 60
			},
			// 去登录
			logonTop(){
				this.loginType = 1
				this.detal()
			},
			// 忘记密码
			forget(){
				this.loginType = 3
				this.detal()
			},
			// 注册账号
			register(){
				this.loginType = 2
				this.detal()
			},
			tioCod(){
				if(!this.phone){
					this.$message.error('请填写手机号');
					return
				}
				
				if(this.loginType == 2){
					this.registerCode()
				}else if(this.loginType == 3){
					this.passwordCode()
				}else if(this.loginType == 1){
					if(!this.codeType){
						this.loginCode()
					}
				}
			},
			// 注册账号验证码
			registerCode(){
				let params = {
					mobileOrEmail:this.phone
				}
				this.$api.get(this.$apiUrls.registerCode,{params}).then((res)=> {
				    if (res.code == 0) {
						this.$message.success(res.msg);
						let that = this
						this.td = setInterval(function() {
							// console.log(that.time ,'执行')
							// 判断剩余秒数
							if (that.time == 0) {
							// 清除定时器和复原按钮
							    that.timTpe = false
								clearInterval(that.td);
							} else {
								that.timTpe = true
								that.time--;
							}
						}, 1000);
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 忘记密码验证码
			passwordCode(){
				let params = {
					mobileOrEmail:this.phone
				}
				this.$api.get(this.$apiUrls.passwordCode,{params}).then((res)=> {
				    if (res.code == 0) {
						this.$message.success(res.msg);
						let that = this
						this.td = setInterval(function() {
							console.log(that.time ,'执行')
							// 判断剩余秒数
							if (that.time == 0) {
							// 清除定时器和复原按钮
							    that.timTpe = false
								clearInterval(that.td);
							} else {
								that.timTpe = true
								that.time--;
							}
						}, 1000);
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 登录验证码
			loginCode(){
				let params = {
					mobileOrEmail:this.phone
				}
				this.$api.get(this.$apiUrls.sendLoginCode,{params}).then((res)=> {
				    if (res.code == 0) {
						this.$message.success(res.msg);
						let that = this
						this.td = setInterval(function() {
							console.log(that.time ,'执行')
							// 判断剩余秒数
							if (that.time == 0) {
							// 清除定时器和复原按钮
							    that.timTpe = false
								clearInterval(that.td);
							} else {
								that.timTpe = true
								that.time--;
							}
						}, 1000);
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 验证码密码切换
			codeTo(){
				this.codeType = !this.codeType
				this.detal()
			},
			// 协议弹框
			xieyi(type){
				if(type == 1){
					this.agreeTile = '用户注册协议'
				}else{
					this.agreeTile = '隐私政策'
				}
				this.dialogVisible = true
			}
		}
	}
</script>
<style lang="scss" scoped>
	.toInde{
		margin-left: auto;
	}
	::v-deep{
		.el-icon-view{
			display: none;
		}
	}
	.img-sty{
		width: 24px;
		height: 24px;
		margin: 12px 14px 0 0;
	}
	.logn {
		// position: absolute;
		// top: 0;
		// height: 100%;
		// width: 100%;
		// background: url(../../static/image/1.png) 0 0 no-repeat;
		// background-size: 100% 100%;
	}
	.lognheig{
		height: 530px !important;
	}
	.lognb {
		width: 480px;
		height: 480px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 70%;
		transform: translateY(-50%);
		border-radius: 6px;
	}

	.lognb_t {
		height: 74px;
		border-bottom: 1px solid #EEEEEE;
		font-size: 20px;
		font-weight: 400;
		color: #333333;
		line-height: 74px;
		text-align: center;
	}
	.logncode{
		text-align: left !important;
		color: #0DC3D6 !important;
		padding: 0 0 0 50px;
	}
	.lognb_h{
		padding: 0 50px;
		.int{
			margin-top: 33px;
			height: 48px;
			border: 1px solid #DADADA;
			border-radius: 8px 8px 8px 8px;
			overflow: hidden;
		}
		.code{
			font-size: 14px;
			font-weight: 400;
			color: #0DC3D6;
			text-align: right;
			margin: 28px 0 17px;
			text-align: right;
		}
		.logon{
			width: 100%;
			height: 48px;
			background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
			border-radius: 24px 24px 24px 24px;
			font-size: 18px;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 48px;
			text-align: center;
		}
		.lognd{
			margin: 16px 0 0 0;
			text-align: center;
			font-size: 14px;
			font-weight: 400;
		}
		.forget{
			margin-top: 30px;
		}
		.lognb_hd{
			margin: 21px 0 0 0;
			font-size: 14px;
			font-weight: 400;
			color: #999999;
		}
	}

	.lognb_b {
		width: 100%;
		height: 48px;
		background: #F4F8F8;
		padding: 0px 28px;
		box-sizing: border-box;
		position: absolute;
		bottom: 0;
		left: 0;
		.wen{
			font-size: 14px;
			font-weight: 400;
			color: #666666;
			line-height: 48px;
		}
		.wen:nth-child(odd){
			color: #1C95C9;
		}
	}
	::v-deep {
		.el-input input{
			height: 48px;
		}
	}
	.content{
		max-height: 700px;
		overflow: auto;
	}
	.lotCn_rb{
		// width: 360px;
		height: 48px;
		background: #FFFFFF;
		border-radius: 8px 8px 8px 8px;
		// opacity: 1;
		border: 1px solid #DADADA;
		margin: 33px 0 0 0;
		// padding-left: 10px;
		overflow: hidden;
		
		.xian{
			width: 0px;
			height: 24px;
			opacity: 1;
			// border: 1px solid #DADADA;
			margin-top: 13px;
		}
		.lotCn_rbr{
			width: 160px;
			font-size: 14px;
			font-weight: 400;
			color: #0DC3D6;
			text-align: center;
			line-height: 48px;
			border-left: 1px solid #DADADA;
			// padding: 14px 0 0 14px;
			// box-sizing: border-box;
		}
	}
	/deep/ input{
		border: none;
		// width: 250px;
		// margin-left: 10px;
	}
	.fangshu{
		width: 480px;
		height: 383px;
		background: #FFFFFF;
		position: absolute;
		top: 50%;
		left: 70%;
		transform: translateY(-50%);
		border-radius: 6px 6px 6px 6px;
		.fangshu_t{
			height: 50px;
			border-bottom: 1px solid #DADADA;
			line-height: 50px;
			padding:0 20px;
			box-sizing: border-box;
			font-size: 18px;
			.el-icon-close{
				font-size: 25px;
				line-height: 50px;
			}
		}
		.fangshu_h{
			padding: 10px;
			box-sizing: border-box;
		}
	}
	.page-slidecode{
		width: 100%;
		height: 100%;
	}
	.page-slidecodl{
		width: 100% !important;
	}
</style>
