<template>
	<div class="w1200 page">
		<div class="content" v-html="detail.content"></div>
		<div class="briefb flex">
			<div class="pointer" :class="listId == item.id ? 'briefb_r' : 'briefb_l'" v-for="(item,index) in list" @click="listTo(item)">
				<div class="imgd" v-if="listId == item.id">
					<img  :src="item.url" alt="" srcset="">
				</div>
				<div class="imgd" v-else>
					<img :src="item.urs" alt="" srcset="">
				</div>
				<div class="">{{item.name}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				list:[
					{name:'0755-09871623',id:1,url:require('../../static/image/11.png'),urs:require('../../static/image/14.png')},
					{name:'上海浦东新区益江路309弄',id:2,url:require('../../static/image/12.png'),urs:require('../../static/image/15.png')},
					{name:'yuan@126.COM',id:3,url:require('../../static/image/13.png'),urs:require('../../static/image/16.png')},
				],
				listId:1,
				content:'',
				detail:'',
			}
		},
		mounted() {},
		created() {
			this.usInfo()
		},
		methods: {
			usInfo(){
				this.$api.get(this.$apiUrls.usInfo,).then((res)=> {
				    if (res.code == 0) {
						this.detail = res.data
						this.list[0].name = this.detail.phone
						this.list[1].name = this.detail.pcaName+ this.detail.addressName
						this.list[2].name = this.detail.email
				    }
				})
			},
			listTo(item){
				this.listId = item.id
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		padding: 36px 40px 59px;
		box-sizing: border-box;
		background: #fff;
		margin: 46px auto 63px;
	}
	.briefb{
		margin: 70px 0 0 0;
		background: #fff;
		div:nth-child(1){
			margin-left: 0;
		}
		.briefb_r,
		.briefb_l{
			width: 260px;
			height: 160px;
			background: rgba(0, 231, 223, .06);
			border-radius: 6px 6px 6px 6px;
			margin-left: 162px;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			padding: 34px 20px 0;
			box-sizing: border-box;
			.imgd{
				width: 46px;
				height: 46px;
				// background: #fff;
				border-radius: 46px;
				margin: 0 auto 18px;
				img{
					width: 46px;
					height: 46px;
					
				}
			}
			
		}
		.briefb_r{
			// margin-left: 0;
			background: linear-gradient(0deg, #00E7DF 0%, #1C95C9 100%);
			color: #fff;
		}
	}
</style>