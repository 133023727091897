<template>
	<div class="page">
		<img class="page_1" src="../../static/image/54.png" alt="">
		<div>暂无数据</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
		},
		data() {
			return {
                dialog: false,
			}
		},
		watch:{
		},
		mounted() {},
		created() {
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	.page_1{
		width: 200px;
	}
	.page{
		color: #999;
		text-align: center;
	}
</style>