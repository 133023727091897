<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_rt flex space-between">
					<div>余额转出</div>
					<div class="flex bodycolor pointer" @click="tiaohuan('yellow',2)">
						<img class="imd" src="../../static/image/44.png" alt="" srcset="">
						<div>转出记录</div>
					</div>
				</div>
				<div class="wallet_t">
					<div>余额(元)</div>
					<div class="wallet_th flex">￥{{money}}</div>
				</div>
				<div class="person_rd flex space-between">
					<div>转出账号信息</div>
				</div>
				
				<div class="transfer flex">
					<div class="transfer_t flex">
						<div class="transfer_tl bixuan">转出手机号</div>
						<el-input class="nber" v-model="input1" :maxlength='11' placeholder="请输入手机号"></el-input>
						<div class="transfer_tll pointer" >
							<div @click="tioCod" v-if="!timTpe">获取验证码</div>
							<div v-else>{{time}}秒后重新获取</div>
						</div>
					</div>
					<div class="transfer_r flex">
						<div class="transfer_tl bixuan">所在地区</div>
						<el-cascader
						    v-model="input2"
						    :options="options"
							:props="page"
						    @change="handleChange"></el-cascader>
						<!-- <el-input v-model="input2" placeholder="请输入"></el-input> -->
					</div>
				</div>
				<div class="transfer flex">
					<div class="transfer_t flex">
						<div class="transfer_tl bixuan">验证码</div>
						<el-input v-model="input3" placeholder="请输入验证码"></el-input>
					</div>
					<div class="transfer_r flex">
						<div class="transfer_tl bixuan">银行账号</div>
						<el-input v-model="input4" placeholder="请输入银行账号"></el-input>
					</div>
				</div>
				<div class="transfer flex">
					<div class="transfer_t flex">
						<div class="transfer_tl bixuan">转出金额</div>
					
						<el-input v-model="input5" size="small" placeholder="请输入转出金额" @input="sendCoupon"></el-input>
					</div>
					<div class="transfer_r flex">
						<div class="transfer_tl bixuan">开户行</div>
						<el-input v-model="input6" placeholder="请输入开户行"></el-input>
					</div>
				</div>
				<div class="transfer flex">
					<div class="transfer_t flex">
						<div class="transfer_tl bixuan">转出公司名称</div>
						<el-input v-model="input7" placeholder="请输入公司名称"></el-input>
					</div>
				</div>
				<div class="transfer flex">
					<div class="transfer_t flex">
						<div class="transfer_tl bixuan">公司税号</div>
						<el-input v-model="input8" placeholder="请输入公司税号"></el-input>
					</div>
				</div>
				<div class="transfeb flex">
					<div class="transfeb_l pointer" @click="tijiao">提交</div>
					<div class="transfeb_r bodycolor" @click="quxiao">取消</div>
				</div>
			</div>
		</div>
		<phone></phone>
		
	</div>
</template>

<script>
	import { province } from '@/api/map'
    import logistics from "@/components/logistics.vue"
    import lookOrder from "@/components/lookOrder.vue"
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone},
		data() {
			return {
				lestId:2,
				value1:'',
				type:1,
				currentPage4:1,
				input1:'',
				input2:'',
				input3:'',
				input4:'',
				input5:'',
				input6:'',
				input7:'',
				input8:'',
				money:'',
				options:'',
				page:{
					label:'name',
					value:'name'
				},
				time:60,
				timTpe:false,
				td:'',
				input9:'',
			}
		},
		mounted() {},
		created() {
			this.options = province
			this.userConnect()
		},
		methods: {
			 // 保留两位小数
			      sendCoupon(e){
					  var str = e
					  		var len1 = str.substr(0, 1)
					  		var len2 = str.substr(1, 1)
					  		//如果第一位是0，第二位不是点，就用数字把点替换掉
					  		if (str.length > 1 && len1 == 0 && len2 != ".") {
					  			str = str.substr(1, 1)
					  		}
					  		//第一位不能是.
					  		if (len1 == ".") {
					  			str = ""
					  		}
					  		//限制只能输入一个小数点
					  		if (str.indexOf(".") != -1) {
					  			var str_ = str.substr(str.indexOf(".") + 1)
					  			if (str_.indexOf(".") != -1) {
					  				str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1)
					  			}
					  		}
					  		//正则替换
					  		str = str.replace(/[^\d^\.]+/g, '') // 保留数字和小数点
					  		str = str.replace(/\.\d\d\d$/,'') // 小数点后只能输两位
							this.$nextTick(() => {
							    this.input5 = str
							})

			      },
			tioCod(){
				if(!this.input1){
					this.$message.error('请填写手机号');
					return
				}else{
                    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
					if(!reg.test(this.input1)){
						this.$message.error('请输入正确的手机号！');
						return
					}
				}
				let params = {
					phone:this.input1
				}
				this.$api.get(this.$apiUrls.mobileCode,{params}).then((res)=> {
				    if (res.code == 0) {
						this.$message.success(res.msg);
						let that = this
						this.td = setInterval(function() {
							console.log(that.time ,'执行')
							// 判断剩余秒数
							if (that.time == 0) {
							// 清除定时器和复原按钮
							    that.timTpe = false
								clearInterval(that.td);
							} else {
								that.timTpe = true
								that.time--;
							}
						}, 1000);
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			handleChange(){
				console.log(this.input2,123)
			},
			// 取消
			quxiao(){
				this.$router.back() 
			},
			// 提交
			tijiao(){
				if(!this.input1){
					this.$message.error('请输入转出手机号');
					return
				}else{
                    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
					if(!reg.test(this.input1)){
						this.$message.error('请输入正确的手机号！');
						return
					}
				}
				if(!this.input2){
					this.$message.error('请选择所在地区');
					return
				}
				if(!this.input3){
					this.$message.error('请输入验证码');
					return
				}
				if(!this.input4){
					this.$message.error('请输入银行账号');
					return
				}
				if(!this.input5){
					this.$message.error('请输入转出金额');
					return
				}
				if(!this.input6){
					this.$message.error('请输入开户行');
					return
				}
				if(!this.input7){
					this.$message.error('请输入转出公司名称');
					return
				}
				if(!this.input8){
					this.$message.error('请输入公司税号');
					return
				}
				
				let params = {
					dutyParagraph:this.input8,
					gsName:this.input7,
					openingBank:this.input6,
					rechargeMoney:this.input5,
					bankCard:this.input4,
					code:this.input3,
					address:this.input2.toString(),
					rechargePhone:this.input1,
				}
				// fileIds
				this.$api.post(this.$apiUrls.balanceOutSave,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('申请成功！');
						this.$router.back() 
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 我的金额
			userConnect(){
				this.$api.get(this.$apiUrls.userConnect).then((res)=> {
					if (res.code == 0) {
						this.money = res.data.money
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			typTo(type){
				this.type = type
			},
			handleSizeChange(val) {
			    console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
			    console.log(`当前页: ${val}`);
			}
		}
	}
</script>
<style>
	.el-cascader-menu__wrap {
	  height: 400px!important;
	}
	.el-cascader-menu{
	  height: 400px!important;
	}
	.el-cascader-panel{
	  height: 400px!important;
	}
</style>
<style lang="scss" scoped>
	::v-deep{
		.el-input,
		.el-input__inner{
			height: 48px;
			width: 320px;
		}
		.nber{
			.el-input__inner{
				padding: 0 120px 0 15px;
			}
		}
		

	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px;
		box-sizing: border-box;
		.person_rd,
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 20px 0;
			.imd{
				width: 17px;
				height: 17px;
				margin: 3px 9px 0 0;
			}
		}
		.person_rd{
			margin: 40px 0 20px 0;
		}
	}
	.wallet_t{
		width: 406px;
		height: 130px;
		background: url(../../static/image/43.png) 0 0 no-repeat;
		background-size: 100% 100%;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		text-align: center;
		padding: 23px 0 0 0;
		box-sizing: border-box;
		.wallet_th{
			margin: 10px 0 0 0;
			font-size: 37px;
			justify-content: center;
			align-content: center;
		}
		.wallet_tb{
			width: 96px;
			height: 32px;
			background: rgba(250,250,250, .8);
			color: #168EB1;
			line-height: 32px;
			margin: 16px auto 0;
		}
	}
	.transfer{
		margin: 0 0 26px 0;
		.transfer_r,
		.transfer_t{
			position: relative;
			margin: 0 127px 0 0;
			.transfer_tl{
				width: 120px;
				text-align: right;
				margin: 0 24px 0 0;
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 48px;
			}
			.transfer_tr{
				border-radius: 6px 6px 6px 6px;
				opacity: 1;
				border: 1px solid #EEEEEE;
			}
			.transfer_tll{
				position: absolute;
				right: 15px;
				top: 15px;
				font-size: 14px;
				font-weight: 400;
				color: #F73E64;
			}
		}
		.transfer_r{
			margin: 0 0 0 0;
		}
	}
	.transfeb{
		margin: 7px 0 0 0;
		.transfeb_l,
		.transfeb_r{
			width: 184px;
			height: 53px;
			border-radius: 28px 28px 28px 28px;
			font-size: 16px;
			font-weight: 400;
			color: #0DC3D6;
			line-height: 53px;
			text-align: center;
		}
		.transfeb_l{
			margin: 0 68px 0 298px;
			background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
			color: #FFFFFF;
		}
		.transfeb_r{
			border: 1px solid #0DC3D6;
		}
	}

	
</style>