<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
@import './assets/scss/reset.scss';
@import './assets/scss/common.scss';
* {
padding: 0;
margin: 0;
box-sizing: border-box;

font-size: 14px;
font-weight: 400;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.cur{
  cursor: pointer;
}
.cur:hover{
  text-decoration: underline;
}
</style>
