import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/view/home.vue'
import Login from '@/view/login.vue' 
import Index from '@/view/index.vue'
import Brief from '@/view/brief.vue' //公司简介
import Confirm from '@/view/confirm.vue' //确认订单
import Submit from '@/view/submit.vue' // 提交订单
import Person from '@/view/person.vue' // 个人信息
import Order from '@/view/order.vue' // 我的订单
import OrderDetail from '@/view/orderDetail.vue' // 订单详情
import Wallet from '@/view/wallet.vue' // 我的钱包
import Transfer from '@/view/transfer.vue' // 余额转出
import Yellow from '@/view/yellow.vue' // 充值/转出记录
import Upload from '@/view/upload.vue' //上传充值审核
import Invoice from '@/view/invoice.vue' //发票管理
import Invoicing from '@/view/invoicing.vue' //开票记录
import Rule from '@/view/rule.vue' //平台规则
import AddInvoice from '@/view/addInvoice.vue' //新增 修改 发票
import ModifyLogin from '@/view/modifyLogin.vue' //修改登录密码
import ModifyPhone from '@/view/modifyPhone.vue' //修改手机号
import ModifyPay from '@/view/modifyPay.vue' //支付密码
import Feedback from '@/view/feedback.vue' //意见反馈
import Record from '@/view/record.vue' //反馈记录


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      redirect: 'index',
      children: [
        {
          path: '/index',
          name: 'index',
          component: Index,
        },
        {
          path: '/login/:type',
          name: 'login',
          component: Login,
        },
		{
		  path: '/brief',
		  name: 'brief',
		  component: Brief,
		},
		{
		  path: '/confirm',
		  name: 'confirm',
		  component: Confirm,
		},
		{
		  path: '/submit/:type',
		  name: 'submit',
		  component: Submit,
		},
		{
		  path: '/person',
		  name: 'person',
		  component: Person,
		},
		{
		  path: '/order',
		  name: 'order',
		  component: Order,
		},
		{
		  path: '/orderDetail/:id',
		  name: 'orderDetail',
		  component: OrderDetail,
		},
		{
		  path: '/wallet',
		  name: 'wallet',
		  component: Wallet,
		},
		{
		  path: '/transfer',
		  name: 'transfer',
		  component: Transfer,
		},
		{
		  path: '/yellow/:type',
		  name: 'yellow',
		  component: Yellow,
		},
		{
		  path: '/upload',
		  name: 'upload',
		  component: Upload,
		},
		{
		  path: '/invoice',
		  name: 'invoice',
		  component: Invoice,
		},
		{
		  path: '/invoicing',
		  name: 'invoicing',
		  component: Invoicing,
		},
		{
		  path: '/rule',
		  name: 'rule',
		  component: Rule,
		},
		{
		  path: '/addInvoice/:type/:id',
		  name: 'addInvoice',
		  component: AddInvoice,
		},
		{
		  path: '/modifyLogin',
		  name: 'modifyLogin',
		  component: ModifyLogin,
		},
		{
		  path: '/modifyPhone',
		  name: 'modifyPhone',
		  component: ModifyPhone,
		},
		{
		  path: '/modifyPay',
		  name: 'modifyPay',
		  component: ModifyPay,
		},
		{
		  path: '/feedback',
		  name: 'feedback',
		  component: Feedback,
		},
		{
		  path: '/record',
		  name: 'record',
		  component: Record,
		},
      ]
    },
    // {
    //   path: '/signature/:id',
    //   name: 'signature',
    //   component: Signature
    // }
  ]
})