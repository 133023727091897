<template>
	<div class="w1200 page">
		<div class="flex confirm_t" v-if="type != 1">
			<div class="pointer" @click="inded('index')">首页</div>
			<img class="imd" src="../../static/image/20.png" alt="" srcset="">
			<div class="pointer">确认订单</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">提交订单</div>
		</div>
		<div class="flex space-between">
			<div class="subm_l">
				<div>创建订单成功，请尽快付款！ 订单编号：{{order.orderNo}}</div>
				<div>请在24小时内支付，否则订单自动取消 </div>
			</div>
			<div class="subm_r flex">
				<div style="padding-top: 10px;">应付金额：</div>
				<div class="may">￥ {{order.payMoney}}</div>
			</div>
		</div>
		<div class="submit" v-if="result">
			<div class="submit_t flex">
				<div class="submit_tl flex pointer" :class="payId == item.id ? 'xuanzhong' : ''"
					v-for="(item,index) in payList" @click="payTo(item)">
					<img class="submit_tll" :src="item.url" alt="" srcset="">
					<div>{{item.name}}</div>
				</div>
			</div>
			<div class="submit_h">
				<!-- <div class="submit_ht" v-if="payId == 2">支付宝支付</div> -->
				<div class="submit_ht1" v-if="payId == 3 && !setPassword && !three">请设置支付密码</div>
				<div class="submit_ht1" v-if="payId == 3 && !setPassword && three">请再次输入支付密码</div>
				<div class="submit_ht1" v-if="payId == 3 && setPassword">余额支付</div>
				
				
				<!-- <div class="submit_hh" v-if="payId == 2 || payId == 1">
					<div v-if="payId == 1">打开微信扫一扫，扫描下方二维码完成支付</div>
				</div> -->





				<div class="submit_hd flex" v-if="payId == 2 || payId == 1">
					<!-- 	<iframe
					      :srcdoc="srcdoc"
					      width="1000"
					      height="400"
					      ref="iframeDom"
						  sandbox="allow-scripts allow-same-origin allow-popups"
					    ></iframe> -->
					<!-- <iframe :src="srcdoc" 
						  scrolling="no"
						  width="1000"
					      height="400" 
						  security="restricted" 
						  sandbox=""
						></iframe> -->

					<iframe v-if="payId == 2" id="myIframe" :srcdoc="srcdoc" frameborder="no" border="0" marginwidth="0"
						marginheight="0" scrolling="no" class="iframe-content" width="800" height="580"
						security="restricted" sandbox="allow-same-origin allow-scripts allow-forms">
					</iframe>
					<img class="submit_hdl"  v-if="payId == 1 && QRImgUrl" :src="QRImgUrl" />
					<!-- <img class="submit_hdl" v-else src="../../static/image/24.png" alt="" srcset=""> -->
					<!-- <img class="submit_hdr" src="../../static/image/24.png" alt="" srcset=""> -->
				</div>
				<div class="submit_hb flex" v-if="payId == 3 && !three">
					<el-input maxlength="1" v-model="input1" show-password @input="woed1(2)"></el-input>
					<el-input maxlength="1" v-model="input2" id="input2" show-password @input="woed1(3)"></el-input>
					<el-input maxlength="1" v-model="input3" id="input3" show-password @input="woed1(4)"></el-input>
					<el-input maxlength="1" v-model="input4" id="input4" show-password @input="woed1(5)"></el-input>
					<el-input maxlength="1" v-model="input5" id="input5" show-password @input="woed1(6)"></el-input>
					<el-input maxlength="1" v-model="input6" id="input6" show-password></el-input>
				</div>
				<div class="submit_hb flex" v-if="payId == 3 && three">
					<el-input maxlength="1" v-model="input7" show-password @input="woed1(8)"></el-input>
					<el-input maxlength="1" v-model="input8" id="input8" show-password @input="woed1(9)"></el-input>
					<el-input maxlength="1" v-model="input9" id="input9" show-password @input="woed1(10)"></el-input>
					<el-input maxlength="1" v-model="input10" id="input10" show-password @input="woed1(11)"></el-input>
					<el-input maxlength="1" v-model="input11" id="input11" show-password @input="woed1(12)"></el-input>
					<el-input maxlength="1" v-model="input12" id="input12" show-password></el-input>
				</div>
				
				<div v-if="!setPassword">
					<div class="submit_hx pointer" v-if="payId == 3 && !three" @click="toOne">下一步</div>
					<div class="flex btn">
						<div class="submit_hx pointer" v-if="payId == 3 && three" @click="upper">上一步</div>
						<div class="submit_hx pointer" v-if="payId == 3 && three" @click="save">保存</div>
					</div>
				</div>
				<!-- <div v-else> -->
					<div class="submit_hx pointer"  v-if="setPassword && payId == 3" @click="getPayData">支付</div>
				<!-- </div> -->
				

			</div>
			<div class="submit_d" v-if="payId == 2 || payId == 1">
				<div>温馨提示:</div>
				<div>1.“支付宝支付”支持使用花呗分期</div>
				<div>2.使用方法:打开手机支付宝 >扫一扫 >>付款方式”切换至“花呗分期”</div>
				<div>3能否使用花呗分期取决于支付宝对您的消费行为进行的风控评估，若有疑问可联系支付宝客服</div>
			</div>
		</div>
		<div class="result" v-if="!result">
			<div class="result_t">
				<img class="good" src="../../static/image/25.png" alt="" srcset="">
				<div>订单支付成功!</div>
			</div>
			<div class="result_h flex">
				<div class="result_hl">支付金额：</div>
				<div class="result_hr">￥ {{order.payMoney}}</div>
			</div>
			<div class="result_h flex">
				<div class="result_hl">支付方式：</div>
				<div class="result_hr" v-if="payId == 1">微信支付</div>
				<div class="result_hr" v-if="payId == 2">支付宝支付</div>
				<div class="result_hr" v-if="payId == 3">余额支付</div>
			</div>
			<div class="result_h flex">
				<div class="result_hl">支付时间：</div>
				<div class="result_hr">{{orderTime}}</div>
			</div>
			<div class="result_d flex space-between">
				<div class="result_dl pointer" @click="inded('order')">查看订单</div>
				<div class="result_dr bodycolor pointer" @click="inded('index')">返回首页</div>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcode'
	export default {
		components: {},
		data() {
			return {
				payList: [
					{
						name: '余额支付',
						id: 3,
						url: require('../../static/image/23.png')
					},{
						name: '微信支付',
						id: 1,
						url: require('../../static/image/21.png')
					},
					{
						name: '支付宝支付',
						id: 2,
						url: require('../../static/image/22.png')
					},
				],
				payId: 3,
				input: 1,
				input1: '',
				input2: '',
				input3: '',
				input4: '',
				input5: '',
				input6: '',
				input7: '',
				input8: '',
				input9: '',
				input10: '',
				input11: '',
				input12: '',
				three: false,
				result: true,
				order: '',
				type: 1,
				orderTime: '',
				srcdoc: '',

				QRImgUrl: '',
				QRlink: '',
				timer: '',
				setPassword:true,
			}
		},
		mounted() {},
		created() {
			this.currentInfo()
			if (window.sessionStorage.getItem('order')) {
				this.order = window.sessionStorage.getItem('order')
				this.order = JSON.parse(this.order);
			}
			if (this.$route.params.type) {
				this.type = this.$route.params.type
			}
			// if (this.payId == 2) {
				// this.dataHtml()
			// }
			let _this = this
			this.timer = setInterval(function() {
				_this.orderInfo()
			}, 4000);
			// this.getQRcode(111111)
		},
		// created() {
			// let _this = this
			// console.log(123)
		
			// clearInterval(_this.timer)
		// },
		destroyed() {
			let _this = this
			clearInterval(_this.timer)
		},
		methods: {
			setPassWorsd(){
				let page = {
					payPassword : this.input1 + this.input2 + this.input3 + this.input4 + this.input5 + this.input6
				}
				this.$api.post(this.$apiUrls.setPassWord,page).then((res)=> {
				    if (res.code == 0) {
						this.setPassword = true
						this.$message.success('设置成功！');
						this.payTo({id:3})
						this.currentInfo()
				    } else {
						this.$message.error(res.msg);
						return
					}
				})
			},
			currentInfo(){
				this.$api.get(this.$apiUrls.currentInfo,).then((res)=> {
				    if (res.code == 0) {
						this.setPassword = res.data.setPassword
						console.log(this.setPassword,123)
				    }
				})
			},
			orderInfo() {
				let params = {
					id:this.order.id
				}
				this.$api.get(this.$apiUrls.orderInfo,{params}).then((res)=> {
					if (res.code == 0) {
						if(res.data.orderState > 0 && res.data.orderState<3){
						this.orderTime = this.timestampToTime(res.data.payTime)
							this.result = false
				            clearInterval(this.timer);
						}
				    }
				})
			},
			woed1(id) {
				// console.log()
				// let name = 'input' + (Number(type) +1)
				let input = document.getElementById('input' + id);
				// console.log(this.$refs)
				// console.log(this.$refs.name)
				input.focus();
			},
			getQRcode(url) {
				console.log(url,123)
				// QRCode.toDataURL(url, { errorCorrectionLevel: 'L', margin: 2, width: 128 }, (err, url) => {
				//           if (err) throw err
				//           this.QRImgUrl= url
				//         }
				// 		)
				let opts = {
					errorCorrectionLevel: "L", //容错级别
					type: "image/png", //生成的二维码类型
					quality: 0.3, //二维码质量
					margin: 5, //二维码留白边距
					width: 200, //宽
					height: 200, //高
					text: 'www.xxx.com', //二维码内容
					color: {
						dark: "#666666", //前景色
						light: "#fff" //背景色
			 	}
				};
				QRCode.toDataURL(url, opts, (err, url) => {
					console.log(err, url,11111)
					if (err) throw err
					this.QRImgUrl = url
				})
			},
			dataHtml() {
				let page = {
					id: this.order.id,
					payPassWord: '',
				}
				if (this.payId == 3) {
					page.payType = 2
					page.payPassWord = this.input1 + this.input2 + this.input3 + this.input4 + this.input5 + this.input6
				} else {
					page.payType = this.payId - 1
				}
				
				this.$api.post(this.$apiUrls.dataHtml, page).then((res) => {
					console.log(res, 123)
					// return
					if(this.payId == 2){
						this.srcdoc = res
					}else{
						this.getQRcode(res.codeUrl)
					}
				})
			},

			getPayData() {
				if(!this.input1 || !this.input2 || !this.input3 || !this.input4 || !this.input5 || !this.input6){
					this.$message.error('请填写密码！');
					return
				}
				let page = {
					id: this.order.id,
				}
				if (this.payId == 3) {
					page.payType = 2
					page.payPassWord = this.input1 + this.input2 + this.input3 + this.input4 + this.input5 + this.input6
				} else {
					page.payType = this.payId - 1
				}
				this.$api.post(this.$apiUrls.getPayData, page).then((res) => {
					console.log(res,123)
					if (res.code == 0) {
						this.$message.success('支付成功！');
						this.result = false
						this.orderTime = this.timestampToTime(res.data.orderTime)
						// this.srcdoc = res.data.payData.body
					} else {
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 时间转
			timestampToTime(timestamp) {
				var date = new Date(timestamp * 1000);
				var Y = date.getFullYear() + "-";
				var M =
					(date.getMonth() + 1 < 10 ?
						"0" + (date.getMonth() + 1) :
						date.getMonth() + 1) + "-";
				var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
				var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
				var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":";
				var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
				// var h = date.getHours() + ":";
				// var m = date.getMinutes() + ":";
				// var s = date.getSeconds();
				return Y + M + D + h + m + s;
			},
			upper() {
				this.input1=''
				this.input2=''
				this.input3=''
				this.input4=''
				this.input5=''
				this.input6=''
				this.three = false
			},
			toOne() {
				if (!this.input1 || !this.input2 || !this.input3 || !this.input4 || !this.input5 || !this.input6) {
					this.$message.error('请填写完整的密码');
					return
				} else {
					this.three = true
				}
			},
			save() {
				if (this.input1 == this.input7 && this.input2 == this.input8 && this.input3 == this.input9 && this
					.input4 == this.input10 && this.input5 == this.input11 && this.input6 == this.input12) {
					this.setPassWorsd()
				} else {
					this.$message.error('两次密码不一致请重新输入！');
					return
				}
			},
			payTo(item) {
				console.log(item.id,123)
				this.payId = item.id
				this.input1 = ''
				this.input2 = ''
				this.input3 = ''
				this.input4 = ''
				this.input5 = ''
				this.input6 = ''
				this.input7 = ''
				this.input8 = ''
				this.input9 = ''
				this.input10 = ''
				this.input11 = ''
				this.input12 = ''
				this.three = false
				if (this.payId != 3) {
					this.dataHtml()
				} 
				// else if (this.payId == 1) {
				// 	this.getPayData()
				// }
			},
			inded(url) {
				window.sessionStorage.setItem('statusType', 0);
				let page = {}
				this.$router.push({
					name: url,
					params: page,
				});
			}
		}
	}
</script>
<style>
	.iframe-content {
		margin: 0 auto;
	}
</style>
<style lang="scss" scoped>
	::v-deep {
		.el-input {
			width: 64px;
			height: 64px;
			margin: 0 6px 0 0;
		}

		input {
			width: 64px;
			height: 64px;
			text-align: center;
			padding: 0 !important;
		}

		.el-input__suffix {
			display: none;
		}
	}

	.page {
		padding: 32px 0 67px 0;
		box-sizing: border-box;
	}

	.confirm_t {
		margin: 0 0 18px 0;

		div {
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}

		.imd {
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}

	.subm_l {
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		line-height: 26px;
	}

	.subm_r {
		color: #333333;
		font-size: 18px;

		.may {
			font-size: 22px;
			color: #FF2B2B;
			font-weight: 900;
		}
	}

	.submit {
		background: #fff;
		margin: 25px 0 99px 0;
		padding: 33px 39px 31px;
		box-sizing: border-box;

		.submit_t {
			border-bottom: 1px solid #E4EFF0;

			.submit_tl {
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				margin: 0 69px 0 0;
				width: 143px;
				padding: 0 0 25px 0;
				box-sizing: border-box;

				.submit_tll {
					width: 22px;
					height: 22px;
					margin: 0 11px 0 0;
				}
			}
		}

		.submit_h {
			padding: 46px 0 86px 0px;
			box-sizing: border-box;

			.submit_ht1 {
				font-size: 20px;
				font-weight: bold;
				color: #333333;
				text-align: center;
			}

			.submit_ht {
				font-size: 20px;
				font-weight: bold;
				color: #333333;
				padding: 0 0 0 400px;
				box-sizing: border-box;
			}

			.submit_hh {
				margin: 13px 0 22px 0;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				padding: 0 0 0 400px;
				box-sizing: border-box;
			}

			.submit_hd {
				// padding: 0 0 0 400px;
				box-sizing: border-box;

				.submit_hdl {
					width: 220px;
					height: 220px;
					margin: 0 auto;
				}

				.submit_hdr {
					margin: 0 0 0 57px;
					width: 194px;
					height: 166px;
				}
			}

			.submit_hb {
				margin: 52px 0 64px 0;
				align-content: center;
				justify-content: center;
			}

			.btn {
				justify-content: center;
				align-content: center;
			}

			.submit_hx {
				width: 184px;
				height: 54px;
				background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
				border-radius: 28px 28px 28px 28px;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 54px;
				margin: 0 auto 270px;
				text-align: center;
			}
		}

		.submit_d {
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 32px;
		}

	}

	.xuanzhong {
		font-weight: 900 !important;
		border-bottom: 1px solid #0DC3D6;
	}

	.result {
		background: #FFFFFF;
		padding: 120px 0 114px;
		margin: 50px 0 99px 0;
		box-sizing: border-box;

		.result_t {
			width: 448px;
			height: 220px;
			// box-shadow: 0px 3px 26px 1px rgba(28,149,201,0.16);
			margin: 0 auto;
			background: url(../../static/image/26.png) 0 0 no-repeat;
			background-size: 100% 100%;
			font-size: 20px;
			font-weight: 400;
			color: #333333;
			padding: 38px 0 0 0;
			box-sizing: border-box;
			text-align: center;

			.good {
				width: 76px;
				height: 76px;
				margin: 0 auto 22px;
			}
		}

		.result_h {
			margin: 0 0 20px 0;

			.result_hr,
			.result_hl {
				width: 50%;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				text-align: right;
			}

			.result_hr {
				text-align: left;
				font-weight: 900;
			}
		}

		.result_d {
			margin: 63px auto 0;
			width: 448px;

			.result_dl,
			.result_dr {
				width: 184px;
				height: 53px;
				text-align: center;
				line-height: 53px;
				border-radius: 28px 28px 28px 28px;
				font-size: 18px;
				font-weight: 400;
			}

			.result_dl {
				color: #FFFFFF;
				background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
			}

			.result_dr {
				border: 1px solid #0DC3D6;
			}
		}
	}
</style>
