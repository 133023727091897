<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_rt flex space-between">
					<div>旧密码验证修改</div>
				</div>
			<!-- 	<div class="modify_h flex">
					<div class="modify_hl">旧密码</div>
					<div class="modify_hr flex">
						<div class="modify_hrl flex pointer" @click="typeTo(1)">
							<img class="imd" v-if="type == 1" src="../../static/image/18.png" alt="" srcset="">
							<img class="imd" v-else src="../../static/image/17.png" alt="" srcset="">
							<div :class="type == 1 ? 'xuanz' : ''">旧密码验证修改</div>
						</div>
						<div class="modify_hrl flex pointer" @click="typeTo(2)">
							<img class="imd" v-if="type == 2" src="../../static/image/18.png" alt="" srcset="">
							<img class="imd" v-else src="../../static/image/17.png" alt="" srcset="">
							<div :class="type == 2 ? 'xuanz' : ''">手机号验证修改</div>
						</div>
						<div class="modify_hrr flex"></div>
					</div>
				</div> -->
				<div class="modify_h flex">
					<div class="modify_hl"></div>
					<div class="modify_hb">
						<div>我们要对您绑定的手机号进行验证</div>
						<div>{{user.mobile}}</div>
					</div>
				</div>
				<div class="transfer flex">
					<div class="transfer_t flex">
						<div class="transfer_tl bixuan">验证码</div>
						<el-input class="nber" v-model="input1" placeholder="请输入验证码"></el-input>
						<div class="transfer_tll pointer" v-if="!timTpe" @click="tioCod">获取验证码</div>
						<div  class="transfer_tll transfer_tlr" v-else>{{time}}秒后重新获取</div>
					</div>
				</div>
				<div class="modify_h flex">
					<div class="modify_hl bixuan">新的手机号</div>
					<el-input v-model="input3" placeholder="请输入手机号"></el-input>
				</div>
				<div class="transfer flex">
					<div class="transfer_t flex">
						<div class="transfer_tl bixuan">验证码</div>
						<el-input class="nber" v-model="input2" placeholder="请输入验证码"></el-input>
						<div class="transfer_tll pointer" v-if="!timTpe1" @click="tioCod1">获取验证码</div>
						<div  class="transfer_tll transfer_tlr" v-else>{{time1}}秒后重新获取</div>
					</div>
				</div>
				<div class="modify_b pointer" @click="wancheng">完成</div>
			</div>
		</div>
		<phone></phone>
		
	</div>
</template>

<script>
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone},
		data() {
			return {
				lestId:7,
				input1:'',
				input2:'',
				input3:'',
				type:2,
				timTpe:false,
				time:60,
				tb:'',
				timTpe1:false,
				time1:60,
				tb1:'',
				user:'',
			}
		},
		mounted() {},
		created() {
			this.currentInfo()
		},
		methods: {
			wancheng(){
				if(!this.input1){
					this.$message.error('请填写旧手机号验证码');
					return
				}
				if(!this.input3){
					this.$message.error('请填写新手机号');
					return
				}else{
                    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
					if(!reg.test(this.input3)){
						this.$message.error('请输入正确的手机号！');
						return
					}
				}
				if(!this.input2){
					this.$message.error('请填写新手机号验证码');
					return
				}
				let params = {
					code:this.input2,
					codeOld:this.input1,
					mobile:this.input3
				}
				this.$api.post(this.$apiUrls.mobileBind,params).then((res)=> {
				    if (res.code == 0) {
						this.$message.error('修改成功');
						window.sessionStorage.setItem('token', '');
						window.sessionStorage.setItem('user','');
						this.$router.push({
							name: 'login',
							params: {},
						});
				    }else{
						this.$message.error(res.mse);
					}
				})
			},
			currentInfo(){
				this.$api.get(this.$apiUrls.currentInfo,).then((res)=> {
				    if (res.code == 0) {
						this.user = res.data
						this.user.mobile = res.data.userMobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
				    }
				})
			},
			tioCod1(){
				if(!this.input3){
					this.$message.error('请填写新手机号');
					return
				}else{
                    let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
					if(!reg.test(this.input3)){
						this.$message.error('请输入正确的手机号！');
						return
					}
				}
				let params = {
					mobile:this.input3
				}
				this.$api.get(this.$apiUrls.sendbindCode,{params}).then((res)=> {
				    if (res.code == 0) {
						this.$message.success(res.msg);
						let that = this
						this.td1 = setInterval(function() {
							// 判断剩余秒数
							if (that.time == 0) {
							// 清除定时器和复原按钮
							    that.timTpe1 = false
								clearInterval(that.td1);
							} else {
								that.timTpe1 = true
								that.time1--;
							}
						}, 1000);
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
				
			},
			tioCod(){
				this.$api.get(this.$apiUrls.sendmobileCode,).then((res)=> {
				    if (res.code == 0) {
						this.$message.success(res.msg);
						let that = this
						this.td = setInterval(function() {
							// 判断剩余秒数
							if (that.time == 0) {
							// 清除定时器和复原按钮
							    that.timTpe = false
								clearInterval(that.td);
							} else {
								that.timTpe = true
								that.time--;
							}
						}, 1000);
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			 
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-input{
			width: 320px;
			height: 48px;
		}
		.el-input,
		.el-input__inner{
			height: 48px;
			width: 320px;
		}
		.nber{
			.el-input__inner{
				padding: 0 140px 0 15px;
			}
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 66px 0;
			.imd{
				width: 17px;
				height: 17px;
				margin: 3px 9px 0 0;
			}
		}
	}
	.modify_b{
		width: 184px;
		height: 54px;
		background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
		border-radius: 28px 28px 28px 28px;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
		margin: 42px auto 0;
	}
	.modify_h{
		margin: 0 0 24px 0 ;
		.modify_hl{
			width: 40%;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 48px;
			text-align: right;
			margin: 0 24px 0 0;
		}
		.modify_hr{
			.modify_hrl{
				line-height: 48px;
				margin: 0 41px 0 0;
				.imd{
					width: 20px;
					height: 20px;
					margin: 14px 8px 0 0;
					font-size: 14px;
					font-weight: 400;
					color: #999999;
				}
			}
		}
		.modify_hb{
			width: 320px;
			height: 72px;
			background: rgba(13,195,214,0.09);
			border-radius: 6px 6px 6px 6px;
			font-size: 14px;
			font-weight: 400;
			color: #0DC3D6;
			padding: 12px 15px;
			box-sizing: border-box;
			div{
				margin-bottom: 10px;
			}
		}
	}
	.xuanz{
		color: #333333;
	}
	.transfer{
		margin: 0 0 26px 0;
		.transfer_t{
			width: 100%;
			position: relative;
			// margin: 0 127px 0 0;
			.transfer_tl{
				width: 40%;
				text-align: right;
				margin: 0 24px 0 0;
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 48px;
			}
			.transfer_tll{
				position: absolute;
				left: calc(40% + 250px) ;
				top: 15px;
				font-size: 14px;
				font-weight: 400;
				color: #F73E64;
			}
			.transfer_tlr{
				left: calc(40% + 220px) !important;
			}
		}
		.transfer_r{
			margin: 0 0 0 0;
		}
	}
</style>