<template>
	<div class="person_l">
		<div class="person_ll" v-for="(item,index) in lest">
			<div class="person_llt">{{item.name}}</div>
			<div class="person_llb pointer" :class="lestId == itm.id ? 'xuanzhong' : ''" v-for="(itm,idex) in item.chen" @click="listTo(itm)">
				{{itm.name}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
		    lestId:{
				type:Number,
				default: 0
			},
		},
		data() {
			return {
				// lestId:1,
				lest:[
					{
						name:'订单中心',
						chen:[
						    {name:'我的订单',id:1,url:'order'}
					    ],
					},
					{
						name:'其他功能',
						chen:[
						    {name:'我的钱包',id:2,url:'wallet'},
						    {name:'发票管理',id:3,url:'invoice'},
						    {name:'账户充值信息',id:4,url:'rule'},
					    ],
					},
					{
						name:'系统设置',
						chen:[
						    {name:'修改登录密码',id:5,url:'modifyLogin'},
						    {name:'修改支付密码',id:6,url:'modifyPay'},
						    {name:'修改手机号',id:7,url:'modifyPhone'},
						    {name:'意见反馈',id:8,url:'feedback'},
					    ],
					},
				]
			}
		},
		mounted() {},
		created() {

		},
		methods: {
			listTo(item){
				// console.log(item,123)
				this.$router.push({
					name: item.url,
					params: {},
				});
				// this.lestId = item.id
			}
		}
	}
</script>

<style lang="scss" scoped>
	.person_l{
		background: #fff;
		width: 200px;
		height: 679px;
		padding: 40px 0 0 0px;
		box-sizing: border-box;
		margin: 0 20px 0 0;
	}
	.person_ll{
		box-sizing: border-box;
		
		.person_llt{
			margin-bottom: 32px;
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			padding: 0px 0 0 64px;
		}
		.person_llb{
			line-height: 40px;
			font-size: 14px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #666666;
			margin-bottom: 20px;
			padding: 0px 0 0 64px;
		}
	}
	.xuanzhong{
		background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
		color: #fff !important; 
	}
</style>