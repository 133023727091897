<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="inded('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r">
				
				<!-- <div id="printTest">
					<p style="color: red;font-size: 30px;">打印内容1</p>
					<p>打印内容2</p>
					<p>打印内容3</p>
					<p>打印内容4</p>
				</div>
				<button v-print="print" >打印</button> -->
				
				<div class="person_rt">我的订单</div>
				<div class="order_t flex space-between">
					<div class="order_tl flex">
						<div class="order_tll pointer" v-for="(item,index) in statusList" 
						   :class="statusType == item.id ? 'xuan bodycolor' : ''" @click="staty(item)">
							{{item.name}}
						</div>
					</div>
					<div class="order_tr flex">
						<div class="order_trl">
							<el-input v-model="input1" placeholder="订单编号" clearable></el-input>
						</div>
						<div class="order_trh flex">
							<div class="order_trhl">下单日期:</div>
							<div class="order_trhr">
								 <el-date-picker
								      v-model="value1"
								      type="daterange"
								      range-separator="至"
								      start-placeholder="开始日期"
								      end-placeholder="结束日期"
									  value-format="yyyy-MM-dd HH:mm:ss"
									  >
								    </el-date-picker>
							</div>
						</div>
						<div class="order_trr flex pointer" @click="sousuo">
							<img class="imd" src="../../static/image/38.png" alt="" srcset="">
							<div>搜索</div>
						</div>
					</div>
				</div>
				<div class="order_h flex">
					<div class="order_hl">订单信息</div>
					<div class="order_hl">交期</div>
					<div class="order_hl">价格</div>
					<div class="order_hl">订单状态</div>
					<div class="order_hl">订单操作</div>
				</div>
				<div v-if="isShowLoading">
					<div v-if="orderList.length > 0">
					    <div class="order_d" v-for="(item,index) in orderList" >
					    	<div class="order_dt flex">
					    		<div>{{item.orderTime}}</div>
					    		<div>订单编号：{{item.orderNo}}</div>
					    	</div>
					    	<div class="order_dh flex">
					    		<div class="order_dhl ordero"> 
					    		
					    			<div class="ordero_t p1 flex">
					    				<div>贴片层：</div>
					    				<div v-if="item.pasterType == 0">官方提供</div>
					    				<div v-if="item.pasterType == 1">双层贴片</div>
					    				<div v-if="item.pasterType == 2">单层贴片</div>
										<div v-if="item.pasterType == 3">顶层+底层全贴</div>
										<div v-if="item.pasterType == 4">只贴顶层</div>
										<div v-if="item.pasterType == 5">只贴底层</div>
					    			</div>
					    			<div class="ordero_t p1">贴片数量：{{item.pasterNum}}pcs</div>
					    			<div class="ordero_t p1">贴片元件：{{item.pasterDeviceStart}}焊点/pcs，{{item.pasterDeviceEnd}}种类/pcs</div>
					    			<div class="ordero_t p1">插件焊接：{{item.plugInStart}}焊点/pcs，{{item.plugInEnd}}种类/pcs</div>
					    			<div class="ordero_t p1" v-if="item.weldCraft == 0">焊接工艺：无铅高温</div>
					    			<div class="ordero_t p1" v-else>焊接工艺：有铅高温</div>
					    			<div class="ordero_t p1" v-if="item.steelMeshType == '0'">备料方式：官方提供</div>
					    			<div class="ordero_t p1" v-if="item.steelMeshType == 1">备料方式：贝旭提供</div>
					    		</div>
					    		<div class="order_dhl orderT">{{item.deliveryName}}</div>
					    		<div class="order_dhl orders">
					    			<div class="orders_t">实付</div>
					    			<div class="orders_h">￥{{item.payMoney}}</div>
					    		</div>
					    		<div class="order_dhl orders">
					    			<div class="orders_t" v-if="item.orderState == 0">待支付</div>
					    			<div class="orders_t" v-if="item.orderState == 1">待发货</div>
					    			<div class="orders_t" v-if="item.orderState == 2">待收货</div>
					    			<div class="orders_t" v-if="item.orderState == 3">已完成</div>
					    			<div class="orders_t" v-if="item.orderState == 4">已取消</div>
					    			<div class="orders_r pointer" @click="detal(item)">订单详情</div>
					    		</div>
					    		<div class="order_dhl orderf">
					    			<div class="orderf_t absolute">
					    				<div class="orderf_1 pointer" v-if="item.orderState == 1 && item.sfRemind == 0" @click="sendOut(item)">提醒发货</div>
					    				<div class="orderf_5 pointer" v-if="item.orderState == 1 && item.sfRemind == 1">已提醒</div>
					    				<div class="orderf_2 pointer" v-if="item.orderState == 0" @click="adPay(item)">去支付</div>
					    				<!-- <div class="orderf_2 pointer" v-if="item.orderState >= 1 && item.orderState < 4 && item.sfInvoice != 1" @click="add(item)">申请开票</div> -->
					    				<!--  -->
										<div class="orderf_2 pointer" v-if="item.orderState >= 1 && item.orderState < 4 && item.sfInvoice == 1" @click="addDetal(item)">开票信息</div>
					    				<div class="orderf_3 pointer" v-if="item.orderState == 2" @click="confirmTop(item)">确认收货</div>
					    				<div class="orderf_4 pointer" v-if="item.orderState == 2" @click="look(item)">查看物流</div>
					    				<div class="orderf_5 pointer" v-if="item.orderState == 4 || item.orderState == 3" @click="detailTop(item)">删除订单</div>
					    			</div>
					    			
					    		</div>
					    	</div>
					    </div>
					</div>
					
				    <none v-else></none>
				</div>
				
				
				<el-pagination
				v-if="orderList.length > 0"
				  background
				  layout="->,prev, pager, next"
				  :page-size='10'
				  :current-page.sync='pages'
				  @current-change="feye"
				  :total="total">
				  <!-- :total="100"> -->
				</el-pagination>
			</div>
		</div>
		<phone></phone>
		<!-- 查看发票信息 -->
		<lookOrder :dialogVisible="orderVisible" :orderSid='orderSid' :orderState="orderState" @orderCallber="orderCallber" ></lookOrder>
		<!-- 新增开票 -->
		<logistics :logisVisible="logisVisible" :wuliu="wuliu" @logisCallber="logisCallber" ></logistics>
		<el-dialog
		  title="提示"
		  :visible.sync="detalType"
		  width="30%">
		  <span>确定删除该订单么？</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="detalType = false">取 消</el-button>
		    <el-button type="primary" @click="detailQd">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  title="提示"
		  :visible.sync="confirmType"
		  width="30%">
		  <span>确定收货么？</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="confirmType = false">取 消</el-button>
		    <el-button type="primary" @click="confirmQd">确 定</el-button>
		  </span>
		</el-dialog>
		
		<!-- 选择发票 -->
		<lookUp :dialogVisible="dialogVisiblr" :type="2" @callber="callber" @noLook="noLook" @callberr="callberr" @addLook="addLook" @edit="edit" ref="child"></lookUp>
	    <!-- 添加发票 -->
	    <lookEdit :edId="edId" :dialogVisible="editVisible" @addAlss="addAlss" @noLookEdit="noLookEdit"></lookEdit>
	</div>
</template>

<script>
	import None from "@/components/none.vue"
	import lookUp from "@/components/lookUp.vue"
	import lookEdit from "@/components/lookEdit.vue"
    import logistics from "@/components/logistics.vue"
    import lookOrder from "@/components/lookOrder.vue"
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone,lookOrder,logistics,lookUp,lookEdit,None},
		data() {
			return {
				print: {
					id: 'printTest',
					popTitle: '配置页眉标题', // 打印配置页上方的标题
					extraHead: '打印', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
					preview: false, // 是否启动预览模式，默认是false
					previewTitle: '预览的标题', // 打印预览的标题
					previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
					zIndex: 20002, // 预览窗口的z-index，默认是20002，最好比默认值更高
					previewBeforeOpenCallback () { console.log('正在加载预览窗口！'); console.log(that.msg, this) }, // 预览窗口打开之前的callback
					previewOpenCallback () { console.log('已经加载完预览窗口，预览打开了！') }, // 预览窗口打开时的callback
					beforeOpenCallback () { console.log('开始打印之前！') }, // 开始打印之前的callback
					openCallback () { console.log('执行打印了！') }, // 调用打印时的callback
					closeCallback () { console.log('关闭了打印工具！') }, // 关闭打印的callback(无法区分确认or取消)
					clickMounted () { console.log('点击v-print绑定的按钮了！') },
					standard: '',
					extarCss: '',
				},
				lestId:1,
				statusType:'x',
				statusList:[
					{name:'全部',id:'x'},
					// {name:'待支付',id:0},
					{name:'待发货',id:1},
					{name:'待收货',id:2},
					{name:'已完成',id:3},
				],
				input1:'',
				value1:[],
				orderVisible:false,
				logisVisible:false,
				detalType:false,
				confirmType:false,
				id:'',
				orderList:[],
				total:0,
				pages:1,
				wuliu:{},
				
				dialogVisiblr:false,
				titouId:'',
				orderSid:'',
				editVisible:false,
				edId:'',
				
				isShowLoading:true,
				oerderId:'',
				orderState:'',
			}
		},
		mounted() {
		},
		created() {
			this.isShowLoading = false
			this.orderPage()
		},
		methods: {
			// 关闭新增开票弹框
			noLookEdit(){
				this.editVisible = false
			},
			// 确定添加
			addAlss(){
				this.editVisible = false
				this.$refs.child.sing()
				this.edId = ''
			},
			// 查看发票信息
			addDetal(item){
				this.orderSid = item.id
				this.orderState = item.orderState
				// this.orderSid = '1679383818538082305'
				this.orderVisible = true
				
			},
			// 申请开票
			addInvoice(){
				let params = {
					orderId: this.oerderId,
					invoiceAddressId:this.titouId,
				}
				
				this.$api.post(this.$apiUrls.addInvoice,params).then((res)=> {
					if (res.code == 0) {
						this.dialogVisiblr = false
						this.$message.success('申请成功！');
						this.orderPage()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 关闭抬头弹框
			callberr(id){
				if(id){
					this.titouId = id
					this.addInvoice()
				}else{
					this.$message.error('请选择开票信息');
				}
			},
			callber(id,name){
				// console.log(id,name,1234)
				if(id){
					this.titouId = id
					// this.addInvoice()
				}
				// if(name == 'x'){
				// }
					this.dialogVisiblr = false
			},
			// 不开发票
			noLook(){
				// this.lookName = '不开发票'
				// this.dialogVisible = false
				// this.id = ''
			},
			// 添加抬头
			addLook(){
				this.editVisible = true
			},
			// 编辑发表
			edit(id){
				this.edId = id
			},
			// 发票关闭
			orderCallber(type){
				this.orderVisible = false
			},
			
			// 申请发票
			add(item){
				// this.orderSid = item.id
				this.oerderId = item.id
				this.dialogVisiblr = true
				// this.orderVisible = true
			},
			
			adPay(item){
				window.sessionStorage.setItem('order',JSON.stringify(item));
				this.$router.push({
					name: 'submit',
					params: {
						type:1,
					},
				});
			},
			inded(){
				this.$router.push({
					name: 'index',
					params: {},
				});
			},
			// 订单详情
			detal(item){
				this.$router.push({
					name: 'orderDetail',
					params: {
						id:item.id
					},
				});
			},
			// 时间转
			timestampToTime(timestamp) {
			  var date = new Date(timestamp * 1000);
			  var Y = date.getFullYear() + "-";
			  var M =
			    (date.getMonth() + 1 < 10
			      ? "0" + (date.getMonth() + 1)
			      : date.getMonth() + 1) + "-";
			  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
			  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":";
			  var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
			  return Y + M + D + h + m + s;
			},
			// console.log(); //2022-12-04 17:15:53
			feye(e){
				// console.log(e,123)
				this.orderPage()
			},
			// 搜索
			sousuo(){
				this.pages = 1
				this.orderPage()
			},
			// 订单列表
			orderPage(){
				let params = {
					orderState: this.statusType == 'x' ? '' : this.statusType,
					orderNo:this.input1,
					// startTime:this.value1[0],
					// endTime:this.value1[1],
					size:10,
					current:this.pages,
				}
				if(this.value1 && this.value1.length > 0){
					params.startTime = Date.parse(new Date(this.value1[0])) / 1000
					let name = this.value1[1].replace('00:00:00',"23:59:59")
					params.endTime = new Date(name).getTime() / 1000
				}
				
				this.$api.get(this.$apiUrls.orderPage,{params}).then((res)=> {
					if (res.code == 0) {
						this.total = Number(res.data.total)
						this.orderList = res.data.records
						for (var i = 0; i < this.orderList.length; i++) {
							this.orderList[i].orderTime = this.timestampToTime(this.orderList[i].orderTime)
						}
						this.isShowLoading = true
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 确定
			confirmQd(){
				let params = {
					id:this.id
				}
				this.$api.post(this.$apiUrls.confirmReceipt,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('收货成功！');
						this.confirmType = false
						this.orderPage()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 确认收货
			confirmTop(item){
				this.confirmType = true
				this.id = item.id
				
			},
			// 提醒发货
			sendOut(item){
				let params = {
					id:item.id
				}
				this.$api.post(this.$apiUrls.orderRemind,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('提醒成功！');
						this.orderPage()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 删除订单
			detailTop(item){
				this.detalType = true
				this.id = item.id
			},
			// 确认删除
			detailQd(){
				let params = {
					id:this.id
				}
				this.$api.post(this.$apiUrls.orderDel,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('删除成功！');
						this.detalType = false
						this.orderPage()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 物流关闭
			logisCallber(){
				this.logisVisible = false
			},
			// 查看物流
			look(item){
				// this.id = item.id
				let params = {
					id:item.id
				}
				this.$api.get(this.$apiUrls.orderLogistics,{params}).then((res)=> {
					if (res.code == 0) {
						this.wuliu = res.data
				        this.logisVisible = true
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			
			staty(item){
				this.statusType = item.id
				this.pages = 1
				this.orderPage()
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	
	
	::v-deep{
		.order_trl{
			input{
				border: none !important;
			}
		}
		.order_trhr{
			.el-input__inner{
				width: 254px !important;
				border: none;
			}
			.el-range-input{
				font-size: 12px;
			}
		}
		
		.el-pagination{
			margin: 20px 0 0 0;
		}
		.el-range-separator{
			padding: 0 !important;
			margin: 0 5px !important;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 20px 0;
		}
	}
	.confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	.order_tl{
		.order_tll{
			width: 82px;
			height: 40px;
			background: #FFFFFF;
			border-radius: 24px 24px 24px 24px;
			opacity: 1;
			border: 1px solid #DEDEDE;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 40px;
			text-align: center;
			margin: 0 24px 0 0;
		}
	}
	.order_tr{
		.order_trr{
			width: 72px;
			height: 40px;
			background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
			border-radius: 20px 20px 20px 20px;
			line-height: 40px;
			padding: 0 11px;
			box-sizing: border-box;
			font-size: 14px;
			font-weight: 400;
			color: #FFFFFF;
			.imd{
				width: 16px;
				height: 16px;
				margin: 12px 6px 0 0;
			}
		}
		.order_trl{
			width: 195px;
			height: 40px;
			background: #FFFFFF;
			border-radius: 20px 20px 20px 20px;
			opacity: 1;
			border: 1px solid #0DC3D6;
			overflow: hidden;
		}
		.order_trh{
			margin: 0 14px 0 34px;
			.order_trhl{
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 40px;
				margin: 0 10px 0 0;
			}
			.order_trhr{
				width: 254px;
				height: 40px;
				background: #FFFFFF;
				border-radius: 20px 20px 20px 20px;
				opacity: 1;
				border: 1px solid #0DC3D6;
				overflow: hidden;
			}
		}
		
	}
	.order_h{
		width: 100%;
		height: 60px;
		padding: 15px 0 13px 0;
		box-sizing: border-box;
		background: #FAFAFA;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #EEEEEE;
		margin: 23px 0 0 0;
		.order_hl{
			width: 20%;
			border-right: 1px solid #CFDDDE;
			font-size: 18px;
			font-weight: 400;
			color: #333333;
			text-align: center;
			// line-height: 60px;
		}
		.order_hl:last-child{
			border-right:none;
		}
	}
	.order_d{
		height: 270px;
		border: 1px solid #CFDDDE;
		border-top: none;
		.order_dt{
			height: 47px;
			line-height: 47px;
			padding: 0 0 0 15px;
			box-sizing: border-box;
			font-size: 14px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #CFDDDE;
			width: 100%;
			div{
				margin: 0 20px 0 0 ;
			}
		}
		.order_dh{
			// width: 100%;
			height: 222px;
			.order_dhl{
				width: 20%;
				height: 100%;
				border-right: 1px solid #CFDDDE;
			}
			.order_dhl:last-child{
				border-right:none;
			}
			.orderT{
				text-align: center;
				line-height: 193px;
				font-size: 12px;
				font-weight: bold;
				color: #333333;
			}
			.ordero{
				padding: 20px 14px 0 14px;
				box-sizing: border-box;
				.ordero_t{
					font-size: 12px;
					font-weight: 400;
					color: #333333;
					margin: 0 0 18px 0;
				}
				.ordero_t:last-child{
					margin: 0;
				}
			}
			.orders{
				padding: 74px 0 0 0;
				box-sizing: border-box;
				text-align: center;
				.orders_t{
					font-size: 12px;
					font-weight: 400;
					color: #333333;
					margin: 0 0 12px 0;
				}
				.orders_h{
					font-size: 13px;
					font-weight: bold;
					color: #FF0000;
				}
				.orders_r{
					font-size: 12px;
					font-weight: 400;
					color: #1890FF;
				}
			}
			.orderf{
				position: relative;
				.orderf_t{
					top: 50%;
					left: 50%;
					transform: translate(-50% ,-50%);
				}
				.orderf_5,
				.orderf_4,
				.orderf_3,
				.orderf_2,
				.orderf_1{
					width: 86px;
					height: 32px;
					border-radius: 20px 20px 20px 20px;
					font-size: 12px;
					font-weight: 400;
					line-height: 32px;
					text-align: center;
					margin: 0 0 16px 0 ;
				}
				.orderf_1{
					background: rgba(232,38,154,0.06);
					border: 1px solid #E8269A;
					color: #E8269A;
				}
				.orderf_2{
					background: rgba(3,224,221,0.06);
					border: 1px solid #0DC3D6;
					color: #0DC3D6;
				}
				.orderf_3{
					background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
					color: #FFFFFF;
				}
				.orderf_4{
					background: #1C95C9;
					color: #FFFFFF;
				}
				.orderf_5{
					border: 1px solid #999999;
					color: #999999;
				}
			}
		}
	}
	.xuan{
		background: rgba(13,195,214,0.06) !important;
		border: 1px solid #0DC3D6 !important;
	}
</style>