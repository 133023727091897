export default {
  usInfo: 'api/about/us/info', //商品简介
  registerCode: 'api/login/send/register/code', //【发送验证码】用户注册
  sendLoginCode: 'api/login/send/login/code', //【发送验证码】用户登录
  passwordCode: 'api/login/send/forget/password/code', //【发送验证码】忘记密码
  mobileCode: 'api/recharge/record/send/mobile/code', //【余额转出】发送验证码
  mobilePassword: 'api/user/send/mobile/password', //【手机号修改密码】发送验证码
  payPassword: 'api/user/send/pay/password/code', //【支付密码修改】第一步-发送验证码
  setPassWord: 'api/user/set/pass/word', //设置支付密码
  
  loginRegister: 'api/login/register', //注册
  login: 'login?grant_type=app', //登录
  loginCode: 'api/login/code', //获取登录校验图
  currentInfo: 'api/user/current/info', //查看用户信息
  currentEdit: 'api/user/current/info/edit', //修改用户信息(包含头像)
  infoCode: 'api/banner/info/code', //轮播图
  configCode: 'api/config/info/code', //系统配置
  orderMoney: 'api/order/get/info/money', //根据参数计算费用
  deliveryList: 'api/delivery/list', //交付日期列表
  uploadFile: 'api/business/file/upload/file', //上传文件信息
  addressList: 'api/invoice/address/list', //发票地址列表
  addressSave: 'api/invoice/address/save', //新增发票地址管理
  addressInfo: 'api/invoice/address/info', //发票地址管理详情
  addressEdit: 'api/invoice/address/edit', //修改发票地址管理
  sfDefault: 'api/invoice/address/sf/default', //设置为默认的发票地址
  addressDel: 'api/invoice/address/del', //删除发票地址管理
  recordPag: 'api/invoice/record/page', //开票记录列表
  editPassword: 'api/user/editPassword', //个人中心修改密码
  forgetPassword: 'api/login/forget/password', //修改密码
  lodCheck: 'api/user/pay/password/lod/check', //(老密码)第一步-校验老密码
  lodConf: 'api/user/pay/password/lod/confirm', //(老密码)第二步-提交新密码
  checkpasswordCode: 'api/user/check/pay/password/code', //【支付密码修改】第二步-验证码验证
  updatePasswordCode: 'api/user/update/pay/password', //【支付密码修改】第三步-修改支付密码
  sendmobileCode: 'api/user/send/mobile/code', //【手机号更换】第一步-发送验证码
  sendbindCode: 'api/user/send/mobile/bind/code', //【手机号更换】第二步-新手机号发送验证码
  mobileBind: 'api/user/mobile/bind', //【手机号更换】第三步-新手机号验证码绑定手机号
  
  addInvoice: 'api/order/add/invoice', // 申请开票
  recordInfo: 'api/invoice/record/info', // 申请开票
  
  
  dataHtml: 'api/order/get/pay/data/html', //根据订单返回支付网页
  orderAdd: 'api/order/add', //即支付
  getPayData: 'api/order/get/pay/data', //订单返回支付信息
  orderPage: 'api/order/page', //订单列表
  orderRemind: 'api/order/remind', //提醒发货
  orderInfo: 'api/order/info', //订单表详情
  orderDel: 'api/order/del', //删除订单表
  orderLogistics: 'api/order/logistics', //查看物流
  confirmReceipt: 'api/order/confirm/receipt', //确认收货
  userConnect: 'api/user/connect', //我的金额
  flowingPage: 'api/connect/flowing/page', //余额明细列表
  recordPage: 'api/recharge/record/page', //充值明细列表
  
  recordSav: 'api/recharge/record/save', //上传充值金额
  balanceOutSave: 'api/recharge/record/balance/out/save', //额转出提交
  recordRecord: 'api/recharge/record/record/page', //转出记录列表
  feedback: 'api/feedback', //意见反馈
  feedbackPage: 'api/feedback/page', //分页查询意见反馈
  
  
}
