import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import Cookie from '@/util/cookie'
Vue.use(VueAxios,axios);
	// let token = ''
	// if(window.sessionStorage.getItem('token')){
	// 	token =window.sessionStorage.getItem('token_type') + ' ' + window.sessionStorage.getItem('token')
	// }
	// console.log(token,1)
const requests = axios.create({
  timeout: 8000,
  baseURL: 'https://api.beixusmt.com/',
  headers: {
    'content-type':  'application/json;charset=UTF-8;',
	// 'authorization': token
    // 'content-type':  'application/x-www-form-urlencoded'
  }
})
requests.interceptors.request.use((config) => {
	// if(config.url == 'user/login' || config.url == 'sms/aliSend' || config.url == 'user/mobileLogin'){
	// 	let a = {
	// 		'content-type':  'application/x-www-form-urlencoded',
	// 	}
	// 	config.headers = Object.assign({}, a)
	// }
	let token = ''
	if(window.sessionStorage.getItem('token')){
		token =window.sessionStorage.getItem('token_type') + ' ' + window.sessionStorage.getItem('token')
		config.headers['authorization'] = token;
	}
	// console.log(window.sessionStorage.getItem('token'),2)
  // config.headers['Authorization'] = window.sessionStorage.getItem('token') || '';
  return config;
})

requests.interceptors.response.use((res) => {

  if(res.data.code == '-201' || res.data.code == '-202' || res.data.code == '-203' || res.data.code == '5' ){
	  window.sessionStorage.setItem('token', '');
	  window.sessionStorage.setItem('token_type', '');
	  window.sessionStorage.setItem('user','');
    window.location.href = '/#/login/1';
    return Promise.reject(res.data);
  }else{
    return res.data;
  }
}, (error) => {
  return Promise.reject(error)
})

export default requests;