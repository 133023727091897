<template>
	<div class="page">
		<div class="inde_tl">
			<swiper class="swiper" :options="swiperOption">
				<div class="swiper-slide" v-for="(item,index) in imgList">
					<img class="imgd" v-if="item.apiComFileInfosVo" :src="item.apiComFileInfosVo.filePath" alt="" srcset="">
				</div>
				<div class="swiper-pagination" slot="pagination"></div>
			</swiper>
		</div>
		<div class="w1200 inde_h">
			<div class="inde_ht flex">
				<img class="inde_htl" src="../../static/image/2.png" alt="" srcset="">
				<div class="inde_htr">SMT在线计价</div>
			</div>
			<div class="inde_hh">
				<div class="inde_hht">基本信息</div>
				<div class="flex inde_hhh">
					<div class="inde_hhhl flex">
						<div class="bixuan">选择贴片层</div>
						<el-tooltip class="item" effect="dark" :content="content1" placement="bottom-start">
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip>
					</div>
					<div class="inde_hhhr flex wrap">
						<div class="inde_hhhrl pointer" :class="item.id == titlId ? 'biao' : ''" v-for="(item,index) in titlList" @click="titlTo(item,1)">{{item.name}}</div>
					</div>
				</div>
				<div class="flex inde_hhh" v-if="titlId == 1">
					<div class="inde_hhhl flex">
						<div class="bixuan">贴面部分</div>
					</div>
					<div class="inde_hhhr flex wrap">
						<div class="inde_hhhrl pointer" :class="item.id == patchId ? 'biao' : ''" v-for="(item,index) in patchList" @click="titlTo(item,2)">{{item.name}}</div>
					</div>
				</div>
				
				<div class="flex inde_hhh">
					<div class="inde_hhhl flex">
						<div class="bixuan">板子尺寸</div>
						<el-tooltip class="item" effect="dark" :content="content6"  placement="bottom-start"  >
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip>
					</div>
					<div class="inde_hhhr ">
						<div class="flex wrap">
							<el-input placeholder="请输入" :maxlength='6' v-model="input6" class="qijian" :class="input6 > 500 ? 'jing' : ''" @input="handleEdit6">
							    <template slot="append">长/MM</template>
							</el-input>
							<div class="box">x</div>
							<el-input placeholder="请输入" :maxlength='6' v-model="input7" class="qijian" :class="input7 > 460 ? 'jing' : ''" @input="handleEdit7">
							    <template slot="append">宽/MM</template>
							</el-input>
						</div>
						<div class="flex">
							<div class="gao">
							    <p  v-if="input6 > 500">长度不得超过500</p>
							</div>
							<div class="gao" v-if="input7 > 460">宽度不得超过460</div>
						</div>
						
					</div>
				</div>
				
				<div class="flex inde_hhh">
					<div class="inde_hhhl flex">
						<div class="bixuan">贴片数量</div>
						<el-tooltip class="item" effect="dark" :content="content2" placement="bottom-start">
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip>
					</div>
					<div class="inde_hhhr flex wrap">
						<el-input placeholder="请输入贴片数量" :maxlength='6' v-model="input1" class="numbe" @input="handleEdit1">
						    <template slot="append">PCS</template>
						</el-input>
					</div>
				</div>
				<div class="flex inde_hhh">
					<div class="inde_hhhl flex">
						<div class="bixuan">贴片元件</div>
						<el-tooltip class="item" effect="dark"  :content="content3" placement="bottom-start">
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip>
					</div>
					<div class="inde_hhhr flex wrap">
						
						<el-input placeholder="请输入" :maxlength='6' v-model="input2" class="qijian" @input="handleEdit2">
						    <template slot="append">焊点/PCS</template>
						</el-input>
						<div class="box">x</div>
						<el-input placeholder="请输入" :maxlength='6' v-model="input3" class="qijian" @input="handleEdit3">
						    <template slot="append">种类/PCS</template>
						</el-input>
					</div>
				</div>
				<div class="flex inde_hhh">
					<div class="inde_hhhl flex">
						<div class="bixuan">插件焊接</div>
						<el-tooltip class="item" effect="dark" :content="content4" placement="bottom-start">
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip>
					</div>
					<div class="inde_hhhr">
						<div class=" flex wrap">
							<el-input placeholder="请输入" :maxlength='6' v-model="input4" class="qijian" @input="handleEdit4">
							    <template slot="append">焊点/PCS</template>
							</el-input>
							<div class="box">x</div>
							<el-input placeholder="请输入" :maxlength='6' v-model="input5" class="qijian" @input="handleEdit5">
							    <template slot="append">种类/PCS</template>
							</el-input>
						</div>
						
						<!-- <div class="flex">
							<div class="gao">
							    <p  v-if="input4">长度不得超过500</p>
							</div>
							<div class="gao" v-if="input7 > 460">宽度不得超过460</div>
						</div> -->
					</div>
				</div>
				<div class="flex inde_hhh">
					<div class="inde_hhhl flex">
						<div class="bixuan">焊接工艺</div>
					<!-- 	<el-tooltip class="item" effect="dark" :content="content1" placement="bottom-start">
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip> -->
					</div>
					<div class="inde_hhhr flex wrap">
						<div class="inde_hhhrl pointer" :class="item.id == technologyId ? 'biao' : ''" v-for="(item,index) in technologyList" @click="titlTo(item,4)">{{item.name}}</div>
					</div>
				</div>
				<div class="flex inde_hhh">
					<div class="inde_hhhl flex">
						<div class="bixuan">备料方式</div>
						<el-tooltip class="item" effect="dark" :content="content5" placement="bottom-start">
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip>
					</div>
					<div class="inde_hhhr flex wrap">
						<div class="inde_hhhrl pointer" :class="item.id == titlId1 ? 'biao' : ''" v-for="(item,index) in titlList1" @click="titlTo(item,3)">{{item.name}}</div>
					</div>
				</div>
				<div class="flex inde_hhh">
					<div class="inde_hhhl flex">
						<div class="bixuan">钢网</div>
						<el-tooltip class="item" effect="dark" :content="content5" placement="bottom-start">
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip>
					</div>
					<div class="inde_hhhr flex wrap">
						<div class="inde_hhhrl pointer" :class="item.id == titlId2 ? 'biao' : ''" v-for="(item,index) in titlList2" @click="titlTo(item,5)">{{item.name}}</div>
					</div>
				</div>
		
				<div class="toGo pointer" @click="toGo">前往下单</div>
			</div>
		</div>
		<phone></phone>
	</div>
</template>

<script>
	import phone from "@/components/phone.vue"
	import "swiper/dist/css/swiper.css";
	import {swiper,swiperSlide} from "vue-awesome-swiper";
	export default {
		components: {
			swiper,
			swiperSlide,
			phone
		},
		data() {
			return {
				imgList:[],
				swiperOption: {
					loop: false,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false,
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true, //允许分页点击跳转
					},
					// 设置点击箭头
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
				},
				titlList:[
					{name:'单面贴片',id:2},
					{name:'双面贴片',id:1},
					// {name:'官方提供',id:'0'}
				],
				titlId:2,
				technologyList:[
					{name:'有铅高温',id:1},
					{name:'无铅高温',id:'0'},
				],
				technologyId:'1',
				patchList:[
					{name:'顶层+底层全贴',id:3},
					{name:'只贴顶层',id:4},
					{name:'只贴底层',id:5},
				],
				patchId:3,
				titlList1:[
					{name:'客户提供',id:'0'},
					{name:'贝旭提供',id:1},
					// {name:'钢网',id:1},
				],
				titlId1:'0',
				titlList2:[
					{name:'普通钢网',id:'0'},
					{name:'精密钢网',id:1},
				],
				titlId2:'0',
				input2:'',
				content1:'提示1',
				content2:'提示2',
				content3:'提示3',
				content4:'提示4',
				content5:'提示5',
				content6:'提示6',
				
				input1:'',
				input2:'',
				input3:'',
				input4:'',
				input5:'',
				input6:'',
				input7:'',
				inputType1:false,
				inputType2:false,
				inputType3:false,
				inputType4:false,
				inputType5:false,
				inputType6:false,
				inputType7:false,
				toType:false,
				toBoole:false
			}
		},
		created(){
			if(window.sessionStorage.getItem('ToBack') == 1){
				let params = window.sessionStorage.getItem('params')
				 params = JSON.parse(params);
				this.input1 = params.pasterNum
				this.input2 = params.pasterDeviceStart
				this.input3 = params.pasterDeviceEnd
				this.input4 = params.plugInStart
				this.input5 = params.plugInEnd
				this.input6 = params.plankSizeStart
				this.input7 = params.plankSizeEnd
				this.technologyId = params.weldCraft
				this.titlId1 = params.steelMeshType
				this.titlId2 = params.steelMesh
				if(params.pasterType == 2){
					this.titlId = params.pasterType
				}else{
					this.titlId = 1
					this.patchId = params.pasterType
				}
			}
		},
		mounted() {
			
			this.infoCode()
			this.configCode1()
			this.configCode2()
			this.configCode3()
			this.configCode4()
			this.configCode5()
			this.configCode6()
		},
		methods: {
			handleEdit1(e){
				let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
				value = value.replace(/^\./g, '')  //第一个字符不能是.
				value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
				value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
				value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
				value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
				value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
				this.input1 = value
				this.inputType1 = false
			},
			handleEdit2(e){
				let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
				value = value.replace(/^\./g, '')  //第一个字符不能是.
				value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
				value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
				value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
				value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
				value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
				this.input2 = value
				this.inputType2 = false
			},
			handleEdit3(e){
				let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
				value = value.replace(/^\./g, '')  //第一个字符不能是.
				value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
				value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
				value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
				value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
				value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
				this.input3 = value
			},
			handleEdit4(e){
				let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
				value = value.replace(/^\./g, '')  //第一个字符不能是.
				value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
				value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
				value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
				value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
				value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
				this.input4 = value
				this.inputType4 = false
			},
			handleEdit5(e){
				let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
				value = value.replace(/^\./g, '')  //第一个字符不能是.
				value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
				value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
				value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
				value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
				value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
				this.input5 = value
				this.inputType5 = false
			},
			handleEdit6(e){
				console.log(e,1234)
				let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
				value = value.replace(/^\./g, '')  //第一个字符不能是.
				value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
				value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
				value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
				value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
				value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
				this.input6 = value
				this.inputType6 = false
			},
			handleEdit7(e){
				let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
				value = value.replace(/^\./g, '')  //第一个字符不能是.
				value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
				value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
				value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
				value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
				value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
				this.input7 = value
				this.inputType7 = false
			},
			configCode1(){
				let params = {
					code:'TPCSM'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content1 = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			configCode2(){
				let params = {
					code:'TPSLSM'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content2 = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			configCode3(){
				let params = {
					code:'TPQJSM'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content3 = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			configCode4(){
				let params = {
					code:'CJSM'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content4 = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			configCode5(){
				let params = {
					code:'GWSM'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content5 = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			configCode6(){
				let params = {
					code:'BZCX'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content6 = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
		
			// 轮播图
			infoCode(){
				let params = {
					code:'101'
				}
				this.$api.get(this.$apiUrls.infoCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.imgList = res.data
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			titlTo(item,type){
				if(type == 1){
					this.titlId = item.id
				}else if(type == 2){
					this.patchId = item.id
				}else if(type == 3){
					this.titlId1 = item.id
				}else if(type == 4){
					this.technologyId = item.id
				}else if(type == 5){
					this.titlId2 = item.id
					
				}
			},
			handleMouseLeave() {
			    this.toBoole = !this.toBoole
			},
			toGo(){
				if(!window.sessionStorage.getItem('token')){
					this.$message.error('请先登录再下单');
					
					setTimeout(() => {
					    this.$router.push({
					    	name: 'login',
					    	params: {},
					    });
					}, 1000)
					return
				}
				if(!this.input1){
					this.$message.error('请输入贴片数量');
					return
				}
				if(!this.input2){
					this.$message.error('请输入贴片元件');
					return
				}
				if(!this.input3){
					this.$message.error('请输入贴片元件');
					return
				}
				if(!this.input4){
					this.$message.error('请输入插件焊接');
					return
				}
				if(!this.input5){
					this.$message.error('请输入插件焊接');
					return
				}
				if(!this.input6){
					this.$message.error('请输入板子长度');
					return
				}
				if(this.input6 > 500){
					this.$message.error('请输入板子长度不得超过500');
					return
				}
				if(!this.input7){
					this.$message.error('请输入板子宽度');
					return
				}
				if(this.input7 > 460){
					this.$message.error('请输入板子宽度不得超过460');
					return
				}
				if(this.toType){
					return
				}
				this.toType = true
				let params = {
					pasterNum:this.input1,
					pasterDeviceStart:this.input2,
					pasterDeviceEnd:this.input3,
					plugInStart:this.input4,
					plugInEnd:this.input5,
					// pasterType:this.titlId,
					plankSizeStart:this.input6,
					plankSizeEnd:this.input7,
					weldCraft:this.technologyId,
					steelMeshType:this.titlId1,
					steelMesh:this.titlId2,
				}
				if(this.titlId == 1){
					params.pasterType = this.patchId
				}else{
					params.pasterType = this.titlId
				}
				this.$api.get(this.$apiUrls.orderMoney,{params}).then((res)=> {
					// console.log(res,123)
				    if (res.code == 0) {
						// this.$message.success(res.msg);
						let detail = res.data
						window.sessionStorage.setItem('detal',JSON.stringify(detail));
						window.sessionStorage.setItem('params',JSON.stringify(params));
						
						window.sessionStorage.setItem('statusType', 0);
						window.sessionStorage.setItem('orderType',2);
						setTimeout(() => {
						    this.$router.push({
						    	name: 'confirm',
						    	params: {},
						    });
						}, 1000)
						
						return
				    }else{
						this.toType = false
						this.$message.error(res.msg);
						return
					}
				})
				
			}
		}
	}
</script>
<style lang="scss" scoped>
	.gao{
		width: 50%;
		color: red;
		padding: 8px 0 0 0 ;
		font-size: 12px;
	}
	.gao:nth-child(2n){
		margin: 0 0 0 40px;
	}
	.jing{
		border: 1px solid red !important;
	}
	::v-deep{
		.swiper-pagination-bullet{
			width: 56px;
			height: 5px;
			border-radius: 10px;
			background: #BEBEBE;
		}
		.swiper-pagination-bullet-active{
			background: #fff;
		}
		.numbe{
			border: 1px solid #DCDFE6 !important;
			border-radius: 6px 6px 6px 6px;
			overflow: hidden;
			input{
				width: 434px;
				height: 48px;
				border: none;
			}
			input:focus{
				// border-right: none;
				// border-top: 1px solid #DCDFE6 !important;
				// border-left: 1px solid #DCDFE6 !important;
				// border-bottom: 1px solid #DCDFE6 !important;
			}
			.el-input-group__append{
				background: #fff;
				border: none;
				font-size: 14px;
			}
		}
		.qijian{
			width: 198px;
			height: 48px;
			border: 1px solid #DCDFE6 ;
			border-radius: 6px 6px 6px 6px;
			overflow: hidden;
			input{
				// width: 198px;
				height: 48px;
				border: none;
			}
			.el-input-group__append{
				background: #fff;
				border: none;
				font-size: 14px;
				width: 60px;
				height: 48px !important;
			}
		}
	}
	.inde_tl{
		width: 100%;
		min-width: 1200px;
		height: 600px;
		.swiper{
			width: 100%;
			height: 100%;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
	.inde_h{
		margin: 39px auto 132px;
		background: #fff;
		padding: 0 41px 80px;
		box-sizing: border-box;
		.inde_ht{
			height: 87px;
			padding: 38px 0 0 0 ;
			box-sizing: border-box;
			border-bottom: 1px solid #E4EFF0;
			.inde_htl{
				width: 4px;
				height: 14px;
				margin: 4px 0 0 0;
			}
			.inde_htr{
				font-size: 16px;
				font-family: Microsoft YaHei-Bold, Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				margin: 0 0 0 20px;
			}
		}
		.inde_hh{
			.inde_hht{
				padding: 14px 0 0 16px;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
			}
			.inde_hhh{
				// height: 48px;
				margin: 27px 0 0 0;
				width: 100%;
				.inde_hhhl{
					width: 30%;
					justify-content:flex-end;
					font-size: 14px;
					font-family: Microsoft YaHei-Regular, Microsoft YaHei;
					color: #333333;
					line-height: 48px;
					.tishi{
						width: 14px;
						height: 14px;
						margin: 17px 0 0 11px;
					}
				}
				.inde_hhhr{
					margin-left: 35px;
					.biao{
						background: linear-gradient(270deg, #00E7DF 0%, #1C95C9 100%);
						color: #FFFFFF !important;
					}
					.inde_hhhrl{
						// width: 92px;
						height: 40px;
						border-radius: 0px 0px 0px 0px;
						opacity: 1;
						border: 1px solid #EEEEEE;
						margin: 0 17px 12px 0;
						text-align: center;
						font-size: 14px;
						font-weight: 400;
						color: #666666;
						line-height: 40px;
						padding: 0 10px;
						box-sizing: border-box;
					}
				}
				.box{
					margin: 0 15px;
					line-height: 48px;
					font-size: 16px;
					font-weight: 400;
					color: #333333;
				}
				.inde_hhhh{
					width: 434px;
					height: 48px;
				}
			}
			.toGo{
				width: 335px;
				height: 54px;
				background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
				border-radius: 28px 28px 28px 28px;
				font-size: 18px;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 54px;
				margin: 54px auto 0px;
				text-align: center;
			}
		}
	}
	
</style>
