<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_rt flex space-between">
					<div>修改支付密码</div>
				</div>
				
					<div class="modify_h flex" v-if="user.setPassword">
						<div class="modify_hl">修改方式</div>
						<div class="modify_hr flex">
							<div class="modify_hrl flex pointer" @click="typeTo(1)">
								<img class="imd" v-if="type == 1" src="../../static/image/18.png" alt="" srcset="">
								<img class="imd" v-else src="../../static/image/17.png" alt="" srcset="">
								<div :class="type == 1 ? 'xuanz' : ''">旧支付密码验证修改</div>
							</div>
							<div class="modify_hrl flex pointer" @click="typeTo(2)">
								<img class="imd" v-if="type == 2" src="../../static/image/18.png" alt="" srcset="">
								<img class="imd" v-else src="../../static/image/17.png" alt="" srcset="">
								<div :class="type == 2 ? 'xuanz' : ''">手机号验证修改</div>
							</div>
							<div class="modify_hrr flex"></div>
						</div>
					</div>
					<div class="submit_ht1" v-else>请设置密码</div>
					
					<div v-if="user.setPassword">
						<div class="modify_h flex" v-if="type == 2 && number == 1">
							<div class="modify_hl"></div>
							<div class="modify_hb">
								<div>我们要对您绑定的手机号进行验证</div>
								<div>{{user.mobile}}</div>
							</div>
						</div>
						<div class="transfer flex" v-if="type == 2 && number == 1">
							<div class="transfer_t flex">
								<div class="transfer_tl bixuan">验证码</div>
								<el-input class="nber" v-model="input7" placeholder="请输入"></el-input>
								<div class="transfer_tll pointer" v-if="!timTpe" @click="tioCod">获取验证码</div>
								<div  class="transfer_tll transfer_tlr" v-else>{{time}}秒后重新获取</div>
							</div>
						</div>
						<el-steps :active="number" align-center v-if="(number>=1 && type == 1) || (number>1 && type == 2)">
						  <el-step title="" description=""></el-step>
						  <el-step title="" description=""></el-step>
						  <el-step title="" description=""></el-step>
						</el-steps>
					</div>
					
					
					<div class="payM">
						<div v-if="user.setPassword">
							<div class="payM_t" v-if="number == 1 && type == 1">修改支付密码</div>
							<div class="payM_h" v-if="number == 1 && type == 1">请输入支付密码 以验证身份</div>
						</div>
						
						<div class="payM_b flex" v-if="number == 1 && type == 1">
							<el-input v-model="input1"  maxlength="1" show-password @input="woed1(1)"></el-input>
							<el-input v-model="input2" id="input1" maxlength="1" show-password @input="woed1(2)"></el-input>
							<el-input v-model="input3" id="input2" maxlength="1" show-password @input="woed1(3)"></el-input>
							<el-input v-model="input4" id="input3" maxlength="1" show-password @input="woed1(4)"></el-input>
							<el-input v-model="input5" id="input4" maxlength="1" show-password @input="woed1(5)"></el-input>
							<el-input v-model="input6" id="input5" maxlength="1" show-password ></el-input>
						</div>
						<div class="payM_t" v-if="number == 2 ">设置支付密码</div>
						<div class="payM_h" v-if="number == 2 ">请设置新支付密码</div>
						<div class="payM_b flex" v-if="number == 2">
							<el-input v-model="input8"  maxlength="1" show-password @input="woed1(6)"></el-input>
							<el-input v-model="input9" id="input6" maxlength="1" show-password @input="woed1(7)"></el-input>
							<el-input v-model="input10" id="input7" maxlength="1" show-password @input="woed1(8)"></el-input>
							<el-input v-model="input11" id="input8" maxlength="1" show-password @input="woed1(9)"></el-input>
							<el-input v-model="input12" id="input9" maxlength="1" show-password @input="woed1(10)"></el-input>
							<el-input v-model="input13" id="input10" maxlength="1" show-password ></el-input>
						</div>
						<div v-if="user.setPassword && number == 3">
							<div class="payM_t">设置支付密码</div>
							<div class="payM_h">请再次输入新支付密码</div>
						</div>
						<div class="payM_h" v-if="!user.setPassword && number == 3">请再次输入密码</div>
						<div class="payM_b flex" v-if="number == 3">
							<el-input v-model="input14"  maxlength="1" show-password @input="woed1(11)"></el-input>
							<el-input v-model="input15" id="input11" maxlength="1" show-password @input="woed1(12)"></el-input>
							<el-input v-model="input16" id="input12" maxlength="1" show-password @input="woed1(13)"></el-input>
							<el-input v-model="input17" id="input13" maxlength="1" show-password @input="woed1(14)"></el-input>
							<el-input v-model="input18" id="input14" maxlength="1" show-password @input="woed1(15)"></el-input>
							<el-input v-model="input19" id="input15" maxlength="1" show-password></el-input>
						</div>
						<div class="payM_d" v-if="type!=2">支付密码为6位数字，用户提现、设置密保、添加银行卡等操作，可保障资金安全</div>
					</div>
					<div class="flex dibu" v-if="user.setPassword">
						<div class="toGo pointer" v-if="number == 2 || number == 3" @click="upGo">上一步</div>
						<div class="toGo pointer" v-if="number == 1 || number == 2" @click="toGo">下一步</div>
						<div class="toGo pointer" v-if="number == 3" @click="wanchen">完成</div>
					</div>
					<div class="flex dibu" v-else>
						<div class="toGo pointer" v-if="number == 2 || number == 3" @click="number = 1">上一步</div>
						<div class="toGo pointer" v-if="number == 1 || number == 2" @click="shehzi">下一步</div>
						<div class="toGo pointer" v-if="number == 3" @click="shehi">完成</div>
					</div>
					
			</div>
		</div>
		<phone></phone>
		
	</div>
</template>

<script>
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone},
		data() {
			return {
				lestId:6,
				type:1,
				input1:'',
				input2:'',
				input3:'',
				input4:'',
				input5:'',
				input6:'',
				input7:'',
				input8:'',
				input9:'',
				input10:'',
				input11:'',
				input12:'',
				input13:'',
				input14:'',
				input15:'',
				input16:'',
				input17:'',
				input18:'',
				input19:'',
				timTpe:false,
				time:60,
				td:'',
				number:1,
				user:{
					setPassword:true
				},
			}
		},
		mounted() {},
		created() {
			this.currentInfo()
		},
		methods: {
			shehzi(){
				this.number = 3
			},
			shehi(){
				if(this.input1== this.input14 && this.input2 == this.input15 && this.input3 == this.input16 && this.input4 == this.input17 && this.input5 == this.input18 && this.input6 == this.input19){
					this.setPassWord()
				}else{
					this.$message.error('两次密码不一致');
					return
				}
			},
			setPassWord(){
				let page = {
					payPassword : this.input1 + this.input2 + this.input3 + this.input4 + this.input5 + this.input6
				}
				this.$api.post(this.$apiUrls.setPassWord,page).then((res)=> {
				    if (res.code == 0) {
						this.$message.success('设置成功！');
						this.chongzhi()
						this.currentInfo()
				    } else {
						this.$message.error(res.msg);
						return
					}
				})
			},
			woed1(id) {
				let input = document.getElementById('input' + id);
				input.focus();
			},
			currentInfo(){
				this.$api.get(this.$apiUrls.currentInfo,).then((res)=> {
				    if (res.code == 0) {
						this.user = res.data
						this.user.mobile = res.data.userMobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
				    }
				})
			},
			chongzhi(){
				this.input1=''
				this.input2=''
				this.input3=''
				this.input4=''
				this.input5=''
				this.input6=''
				this.input7=''
				this.input8=''
				this.input9=''
				this.input10=''
				this.input11=''
				this.input12=''
				this.input13=''
				this.input14=''
				this.input15=''
				this.input16=''
				this.input17=''
				this.input18=''
				this.input19=''
				this.number = 1
			},
			lodCheck(){
				let params = {
					lodPayPassword : this.input1 + this.input2 + this.input3 + this.input4 + this.input5 + this.input6
				}
				this.$api.post(this.$apiUrls.lodCheck,params).then((res)=> {
					if (res.code == 0) {
						this.number = 2
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			lodConf(){
				let params = {
					payPassword : this.input8 + this.input9 + this.input10 + this.input11 + this.input12 + this.input13
				}
				this.$api.post(this.$apiUrls.lodConf,params).then((res)=> {
					if (res.code == 0) {
						this.chongzhi()
						clearInterval(that.td);
						this.$message.success('修改成功！');
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			checkpasswordCode(){
				let params = {
					code : this.input7
				}
				this.$api.get(this.$apiUrls.checkpasswordCode,{params}).then((res)=> {
					if (res.code == 0) {
						// this.chongzhi()
						// this.$message.success('修改成功！');
						clearInterval(this.td);
						this.timTpe=false
						this.number = 2
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			updatePasswordCode(){
				let that = this
				let params = {
					payPassword : this.input8 + this.input9 + this.input10 + this.input11 + this.input12 + this.input13
				}
				this.$api.post(this.$apiUrls.updatePasswordCode,params).then((res)=> {
					if (res.code == 0) {
						clearInterval(that.td);
						this.$message.success('修改成功！');
						setTimeout(() => {
						    this.chongzhi()
						}, 1000)
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			upGo(){
				this.number--
			},
			toGo(){
				if(this.type == 1){
					if(this.number == 1){
						if(this.input1 && this.input2 && this.input3 && this.input4 && this.input5 && this.input6){
							this.lodCheck()
						}else{
							this.$message.error('请填写完整密码');
							return
						}
					}else if(this.number == 2){
						if(this.input8 && this.input9 && this.input10 && this.input11 && this.input12 && this.input13){
							this.number = 3
						}else{
							this.$message.error('请填写新密码');
							return
						}
					}
				}else{
					if(this.number == 1){
						if(this.input7){
							// this.number = 2
							this.checkpasswordCode()
							
						}else{
							this.$message.error('请填写验证码');
							return
						}
						
					}else if(this.number == 2){
						if(this.input8 && this.input9 && this.input10 && this.input11 && this.input12 && this.input13){
							this.number = 3
						}else{
							this.$message.error('请填写新密码');
							return
						}
					}
					
				}
			},
			wanchen(){
				if(this.input8 == this.input14 && this.input9 == this.input15 && this.input10 == this.input16 && this.input11 == this.input17 && this.input12 == this.input18 && this.input13 == this.input19){
					if(this.type == 1){
						this.lodConf()
					}else{
						this.updatePasswordCode()
					}
				}else{
					this.$message.error('两次密码不一致');
					return
				}
			},
			typeTo(type){
				this.type = type
				this.chongzhi()
				clearInterval(this.td);
			},
			tioCod(){
				let params = {
					type:1,
				}
				this.$api.get(this.$apiUrls.payPassword,{params}).then((res)=> {
				    if (res.code == 0) {
						this.$message.success(res.msg);
						let that = this
						this.td = setInterval(function() {
							console.log(that.time ,'执行')
							// 判断剩余秒数
							if (that.time == 0) {
							// 清除定时器和复原按钮
							    that.timTpe = false
								clearInterval(that.td);
							} else {
								that.timTpe = true
								that.time--;
							}
						}, 1000);
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.submit_ht1 {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		text-align: center;
	}
	::v-deep{
		.el-input{
			width: 320px;
			height: 48px;
		}
		.el-input,
		.el-input__inner{
			height: 48px;
			width: 320px;
		}
		
		.nber{
			.el-input__inner{
				padding: 0 140px 0 15px;
			}
		}
		.el-steps {
			width: 200px;
			margin: 20px auto ;
		}
		.el-step__icon{
			background: #BFBFBF;
			color: #FFFFFF;
			border: none !important;
		}
		.is-finish {
			.el-step__icon{
				background: #0DC3D6;
				color: #FFFFFF;
			}
		}
		.payM_b{
			.el-input,
			.el-input__inner{
				height: 42px;
				width: 42px;
				padding: 0 !important;
			}
			input{
				width: 42px;
				height: 42px;
				text-align: center;
			}
		}
		.el-input{
			margin-right: 4px;
		}
		.el-input__suffix{
			display: none;
		}
		.el-step__head.is-finish{
			border-color: #0DC3D6;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 20px 0;
			.imd{
				width: 17px;
				height: 17px;
				margin: 3px 9px 0 0;
			}
		}
	}

	.modify_h{
		margin: 0 0 24px 0 ;
		.modify_hl{
			width: 40%;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 48px;
			text-align: right;
			margin: 0 24px 0 0;
		}
		.modify_hr{
			.modify_hrl{
				line-height: 48px;
				margin: 0 41px 0 0;
				.imd{
					width: 20px;
					height: 20px;
					margin: 14px 8px 0 0;
					font-size: 14px;
					font-weight: 400;
					color: #999999;
				}
			}
		}
		.modify_hb{
			width: 320px;
			height: 72px;
			background: rgba(13,195,214,0.09);
			border-radius: 6px 6px 6px 6px;
			font-size: 14px;
			font-weight: 400;
			color: #0DC3D6;
			padding: 12px 15px;
			box-sizing: border-box;
			div{
				margin-bottom: 10px;
			}
		}
	}
	.transfer{
		margin: 0 0 26px 0;
		.transfer_t{
			width: 100%;
			position: relative;
			// margin: 0 127px 0 0;
			.transfer_tl{
				width: 40%;
				text-align: right;
				margin: 0 24px 0 0;
				font-size: 14px;
				font-weight: 400;
				color: #333333;
				line-height: 48px;
			}
			.transfer_tll{
				position: absolute;
				left: calc(40% + 250px) ;
				top: 15px;
				font-size: 14px;
				font-weight: 400;
				color: #F73E64;
			}
			.transfer_tlr{
				left: calc(40% + 220px) !important;
			}
		}
		.transfer_r{
			margin: 0 0 0 0;
		}
	}
	.toGo{
		width: 184px;
		height: 54px;
		background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
		border-radius: 28px 28px 28px 28px;
		font-size: 18px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
		margin: 40px auto 0;
	}
	.payM{
		width: 272px;
		margin: 20px auto ;
		text-align: center;
		.payM_t{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
		}
		.payM_h{
			font-size: 11px;
			font-weight: 400;
			color: #333333;
			margin: 10px 0 20px;
		}
		.payM_d{
			font-size: 10px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			margin: 14px 0 0 0;
		}
	}
	.dibu{
		justify-content: center;
		align-content: center;
	}
</style>