<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_rt flex space-between">
					<div>意见反馈</div>
					<div class="flex bodycolor pointer" @click="tiaohuan('record')">
						<img class="imd" src="../../static/image/44.png" alt="" srcset="">
						<div>反馈记录</div>
					</div>
				</div>
				
				<div class="deedbackT">
					<div class="deedbackT_t">反馈类型</div>
					<div class="deedbackT_h flex wrap">
						<div class="deedbackT_hl pointer" :class="type == '0' ? 'xuanz' : ''" @click="type = '0'">产品建议</div>
						<div class="deedbackT_hl pointer" :class="type == 1 ? 'xuanz' : ''" @click="type = 1">功能故障</div>
						<div class="deedbackT_hl pointer" :class="type == 2 ? 'xuanz' : ''" @click="type = 2">体验问题</div>
						<div class="deedbackT_hl pointer" :class="type == 3 ? 'xuanz' : ''" @click="type = 3">其他问题</div>
					</div>
				</div>
				<div class="deedbackH">
					<div class="deedbackH_t bixuan">问题描述</div>
					<el-input
					  type="textarea"
					  :rows="15"
					   resize='none'
					  placeholder="问题描述的越详细，有助于我们更快的解决问题~"
					  :maxlength='400'
					  v-model="textarea">
					</el-input>
				</div>
				<div class="flex wrap dibu">
				    <div class="dibu_l" v-for="(item,index) in imageUrl">
				        <img :src="item" class="avatar">
				        <i class="el-icon-circle-close " @click="detail(index)"></i>
				    </div>
				    <el-upload
				      class="avatar-uploader"
				      :action="url"
				      :show-file-list="false"
				      :on-success="handleAvatarSuccess"
				      :before-upload="beforeAvatarUpload">
				    	  <div class="dibu_r">
				    		  <img  class="shang" src="../../static/image/45.png" alt="" srcset="">
				    		  <div>请上传应用截图，最多上传6张</div>
				    	  </div>
				    </el-upload>
				</div>
				<div class="deedbackH">
					<div class="deedbackH_t">联系方式</div>
					<el-input
					   resize='none'
					  placeholder="请填写联系方式"
					  v-model="telephone">
					</el-input>
				</div>
				<div class="deedbackD pointer" @click="feedback">提交反馈</div>
			</div>
		</div>
		<phone></phone>
		
	</div>
</template>

<script>
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone},
		data() {
			return {
				lestId:8,
				type:'0',
				textarea:'',
				telephone:'',
				imageUrl:[],
				url:'',
				fileList:[],
			}
		},
		mounted() {},
		created() {
			this.url ='https://api.beixusmt.com/'+ this.$apiUrls.uploadFile
		},
		methods: {
			feedback(){
				if(!this.textarea){
					this.$message.error('请填写详细问题');
					return
				}
				let params = {
					contentInfo:this.textarea,
					feedbackType:this.type,
					fileIds:this.fileList,
					telephone:this.telephone,
				}
				this.$api.post(this.$apiUrls.feedback,params).then((res)=> {
					if (res.code == 0) {
						this.chongzhi()
						this.$message.success('提交成功！');
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			chongzhi(){
				this.textarea=''
				this.type='0'
				this.fileList=[]
				this.telephone = ''
				this.imageUrl = []
			},
			detail(index){
				this.imageUrl.splice(index,1)
				this.fileList.splice(index,1)
			},
			handleAvatarSuccess(res, file) {
				this.fileList.push(res.data.fileId)
				this.imageUrl.push(URL.createObjectURL(file.raw))
			    // this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				console.log(file)
				let isJPG = ''
				if(file.type === 'image/jpeg' || file.type === 'image/png'){
					isJPG = true
				}else{
					isJPG = false
				}
				// return
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
				  this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
				  this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-textarea{
			height: 216px;
		}
		.el-textarea__inner{
			height: 216px;
			background: #FAFBFC;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 22px 0;
			.imd{
				width: 17px;
				height: 17px;
				margin: 3px 9px 0 0;
			}
		}
	}
	.deedbackT{
		.deedbackT_t{
			font-size: 14px;
			font-weight: bold;
			color: #333333;
		}
		.deedbackT_h{
			.deedbackT_hl{
				padding: 11px 32px;
				box-sizing: border-box;
				margin: 16px 46px 0 0;
				border-radius: 100px;
				border: 1px solid #999999;
			}
		}
	}
	.deedbackH{
		.deedbackH_t{
			font-size: 14px;
			font-family: Microsoft YaHei-Bold, Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			margin: 25px 0 15px 0;
		}
	}
	.deedbackD{
		width: 184px;
		height: 54px;
		background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
		border-radius: 28px 28px 28px 28px;
		font-size: 18px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 54px;
		text-align: center;
		margin: 41px auto 0;
	}
	.xuanz{
		border: 1px solid #0DC3D6 !important;
		color: #0DC3D6 !important;
	}
	.shang{
		width: 106px;
		height: 106px;
	}
	.dibu{
		margin: 24px 0 0 0;
	}
	.dibu_l{
		margin: 0 10px 0 0;
		width: 106px;
		height: 106px;
		position: relative;
		.el-icon-circle-close{
			position: absolute;
			right: 5px;
			top: 5px;
			// width: 20px;
			// height: 20px;
			font-size: 20px;
		}
	}
	.dibu_r{
		font-size: 14px;
		font-weight: 400;
		color: #808080;
		text-align: left;
	}
</style>