<template>
	<div class="page">
		
		<el-dialog
		  title="提示"
		  @close="chufa"
		  :visible.sync="dialog"
		  width="40%">
		   <div slot="title" class="dialog-title lookt">
			    <img class="lookt_l" src="../../static/image/2.png" alt="">
		        <span class="title-text">查看物流</span>
		    </div>
			<div class="logis flex">
				<div class="logis_l">物流名称：</div>
				<div class="logis_r">{{wuliu.logisticsName}}</div>
			</div>
			<div class="logis flex">
				<div class="logis_l">物流单号：</div>
				<div class="logis_r">{{wuliu.logisticsNo}}</div>
			</div>
			
			<div class="logis flex">
				<div class="logis_l">物流网址：</div>
				<div class="logis_r">{{wuliu.logisticsAddress}}</div>
			</div>
		   
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
		    logisVisible:{
				type:Boolean,
				default: false
			},
			wuliu:{
				type:Object,
				default: {}
			},
			
		},
		data() {
			return {
                dialog: false,
				id:1,
				lootList:[1,2,3,4,5],
				lootId:'',
			}
		},
		watch:{
			logisVisible(n,o){
				console.log(n,o)
				this.dialog=n
			},
			wuliu(n,o){
				console.log(n,o)
			}
		},
		mounted() {},
		created() {

		},
		methods: {
		
			// 点击背景
			chufa(){
				console.log('触发')
				this.$emit('logisCallber',this.id)
			},
			// 关闭
			guanbi(){
				this.dialog = false
				this.$emit('logisCallber',this.id)
			} 
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-dialog__body{
			padding-top: 0 !important;
			
		}
	}
	.lookt{
		margin: 0 0 22px 0;
		.lookt_l{
			width: 3px;
			height: 14px;
		}
		.title-text{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			margin: 0 0 0 8px;
		}
	}
	.logis{
		margin: 0 0 28px 0;
		.logis_l{
			width: 100px;
			text-align: left;
			font-size: 20px;
			font-weight: 400;
			color: #666666;
		}
		.logis_r{
			font-size: 20px;
			font-weight: 400;
			color: #333333;
			margin: 0 0 0 14px;
		}
	}

</style>