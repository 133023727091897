<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<!-- <div class="person_rt flex space-between">
					<div>账户充值信息</div>
				</div> -->
				<div class="content" v-html="content"></div>
			</div>
		</div>
		<phone></phone>
		
	</div>
</template>

<script>
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone},
		data() {
			return {
				lestId:4,
				content:'',
			}
		},
		mounted() {},
		created() {
			this.configCode()
		},
		methods: {
			// 系统信息
			configCode(){
				let params = {
					code:'PTGZ'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-date-editor{
			width: 254px !important;
			border: none !important;
		}
		.active{
			background: #0DC3D6 !important;
		}
		.number{
			background: #F4F4F5 ;
		}
		.el-pagination {
		    text-align: center; 
			margin-top: 20px;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 20px 0;
			.imd{
				width: 17px;
				height: 17px;
				margin: 3px 9px 0 0;
			}
		}
	}

	.bin{
		border-bottom: 2px solid #0DC3D6;
	}
	
</style>