<template>
    <div class="page">
		<div class="w1200 flex space-between">
			<div class="topl flex">
				<div class="topl_l">
					<img class="bottm_l1" src="../../static/image/56.png" alt="" srcset="">
				</div>
				<div class="topl_h pointer" :class="statusType == '0' ? 'bodycolor' : ''" @click="tiaozhuan('index',0)">在线下单</div>
				<div class="topl_r pointer" :class="statusType == '1' ? 'bodycolor' : ''" @click="tiaozhuan('brief',1)">公司简介</div>
			</div>
			<div class="topr flex">
				<div class="topr_l pointer" :class="statusType == '2' ? 'bodycolor' : ''" @click="tiaozhuan('order',2)">我的订单</div>
				<div v-if="user" class="topr_d flex relative" @mouseenter="handleMouseLeave" @mouseleave="handleMouseLeave">
					<img class="topr_dl" v-if="user.fileInfo" :src="user.fileInfo.filePath" alt="" srcset="">
					<img class="topr_dl" v-else src="../../static/image/27.png" alt="" srcset="">
					<div class="topr_dh p1">{{user.nickName || '新用户'}}</div>
					<img class="topr_dr" src="../../static/image/28.png" alt="" srcset="">
					<div class="topr_dp" v-if="toBoole">
						<div class="topr_dpl flex pointer" @click="tiaozhuan('person',0)">
							<img class="topr_dl" src="../../static/image/29.png" alt="" srcset="">
							<div>个人中心</div>
						</div>
						<div class="topr_dpd pointer" @click="tuichu">退出</div>
					</div>
				</div>
				<div v-if="!user" class="topr_h pointer" @click="tiaozhuan('login',1)">登录</div>
				<div v-if="!user" class="topr_r pointer" @click="tiaozhuan('login',2)">注册</div>
			</div>
		</div>
    </div>
</template>

<script>
  export default {
    name: 'nav-header',
	data(){
		return{
			statusType:'',
			toBoole:false,
			user:'',
		}
	},
	watch:{
		$route(to,from){
			// console.log(window.sessionStorage.getItem('statusType'),1233)
			
			if(window.sessionStorage.getItem('user')){
				// this.user = window.sessionStorage.getItem('user')
				// this.user = JSON.parse(this.user);
				this.currentInfo()
			}else{
				this.user = ''
			}
			if (window.sessionStorage.getItem('statusType')) {
				this.statusType = window.sessionStorage.getItem('statusType')
			} else {
				this.statusType = '0'
			}
		}
	},
	created(){
		if(window.sessionStorage.getItem('user')){
			// this.user = window.sessionStorage.getItem('user')
			// this.user = JSON.parse(this.user);
			this.currentInfo()
		}else{
			this.user = ''
		}
		// console.log(this.user,1234)
		if (window.sessionStorage.getItem('statusType')) {
			this.statusType = window.sessionStorage.getItem('statusType')
		} else {
			this.statusType = '0'
		}
		this.queryGeneralInfo()
  },
	mounted() {

	},
	methods:{
		xinxi(){
			this.currentInfo()
		},
		// 退出登录
		tuichu(){
			this.toBoole = false
			window.sessionStorage.setItem('token', '');
			window.sessionStorage.setItem('token_type', '');
			window.sessionStorage.setItem('user','');
			window.location.href = '/#/login/1';
		},
		currentInfo(){
			this.$api.get(this.$apiUrls.currentInfo,).then((res)=> {
			    if (res.code == 0) {
					this.user = res.data
			    }
				// console.log(res,123)
			})
		},
		handleMouseLeave(){
			this.toBoole = !this.toBoole
		},
		tiaozhuan(url,type){
			if(url == 'order' && !this.user){
				this.$router.push({
					name: 'login',
					params: {type:1},
				});
				return
			}
			// if(this.statusType != type){
				this.statusType = type
				window.sessionStorage.setItem('statusType', this.statusType);
				let page = {}
				if(type){
					page.type=type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			// }
			
		},
		queryGeneralInfo(){
			// this.$api.get(this.$apiUrls.queryGeneralInfo,).then((res)=> {
			//     if (res.code == 1) {
			// 		this.detail = res.data
			//     }
			// })
		},

	}
  }
</script>

<style lang="scss" scoped>
	.bottm_l1{
		width: 100%;
	}
	// xuanz
	.page{
		width: 100%;
		height: 70px;
		background: #fff;
		box-shadow: 0px 3px 26px 1px rgba(28,149,201,0.16);
	}
	.topl{
		.topl_l{
			padding: 5px 0 0 0;
			width: 114px;
			// height: 35px;
			// background: linear-gradient(94deg, #00E7DF 0%, #1C95C9 100%);
			// border-radius: 6px 6px 6px 6px;
			// font-size: 16px;
			// font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			// font-weight: 400;
			// color: #FFFFFF;
			// line-height: 35px;
			// text-align: center;
			// margin: 18px 0 0 0;
		}
		.topl_h,
		.topl_r{
			padding:0 32px;
			font-size: 18px;
			font-weight: 400;
			color: #333333;
			height: 20px;
			margin: 23px 0 0 0;
		}
		.topl_h{
			border-right: 1px solid #EEEEEE;
		}
	}
	.topr{
		.topr_l{
			font-size: 18px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #333333;
			line-height: 70px;
			margin:0 49px 0 0;
		}
		.topr_r,
		.topr_h{
			width: 90px;
			height: 46px;
			border-radius: 0px 0px 0px 0px;
			font-size: 18px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			line-height: 46px;
			text-align: center;
			margin: 12px 22px 0 0;
		}
		.topr_h{
			color: #0DC3D6;
			border: 1px solid #0DC3D6;
		}
		.topr_r{
			color: #1C95C9;
			border: 1px solid #1C95C9;
		}
		.topr_d{
			width: 211px;
			height: 70px;
			background: #EEEEEE;
			padding: 0 15px;
			box-sizing: border-box;
			.topr_dl{
				width: 38px;
				height: 38px;
				margin:16px 10px 0 0 ;
				border-radius: 38px;
			}
			.topr_dh{
				width: 110px;
				font-size: 18px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #333333;
				line-height: 70px;
			}
			.topr_dr{
				width: 14px;
				height: 14px;
				margin:28px 0px 0 16px ;
			}
			.topr_dp{
				position: absolute;
				left: 0;
				top: 100%;
				width: 172px;
				height: 169px;
				background: #FFFFFF;
				box-shadow: 0px 3px 26px 1px rgba(0,0,0,0.66);
				border-radius: 6px 6px 6px 6px;
				z-index: 1000;
				padding: 23px 0 0 11px;
				.topr_dpl{
					font-size: 20px;
					font-weight: 400;
					color: #666666;
					img{
						width: 26px;
						height: 26px;
						margin: 0 24px 0 0;
					}
				}
				.topr_dpd{
					width: 130px;
					height: 41px;
					background: #F1F3F9;
					border-radius: 6px 6px 6px 6px;
					margin: 61px auto 0;
					font-size: 18px;
					font-weight: 400;
					color: #636D80;
					line-height:41px;
					text-align: center;
				}
			}
		}
	}
</style>

