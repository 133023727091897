<template>
	<div class="page">
		
		<el-dialog
		  title="提示"
		  @close="chufa"
		  :visible.sync="dialog"
		  width="40%">
		   <div slot="title" class="dialog-title lookt">
			    <img class="lookt_l" src="../../static/image/2.png" alt="">
		        <span class="title-text">发票详情</span>
		    </div>
			<div class="order">
				<div class="order_t" v-if="detail.invoiceStatus == 0">开票中</div>
				<div class="order_t" v-if="detail.invoiceStatus == 1">已开票</div>
				<div class="order_b flex">
					<div>预计发票金额：￥{{detail.invoiceMoneyAll}}</div>
					<el-tooltip class="item" effect="dark" content="·开票金额为消费者实付金额·现展示为订单预计开票金额，最终金额以发票实际开具为准" placement="bottom">
					    <img class="lookt_l" src="../../static/image/41.png" alt="">
					</el-tooltip>
				</div>
				<div class="order_d flex">
					<img class="order_dl" src="../../static/image/18.png" alt="">
					<div class="order_dh jindu"></div>
					<div class="order_dh" :class="detail.invoiceStatus == 1 ? 'jindu' : ''"></div>
					<img class="order_dl" v-if="detail.invoiceStatus == 1" src="../../static/image/18.png" alt="">
					<img class="order_dr" v-if="detail.invoiceStatus == 0" src="../../static/image/40.png" alt="">
				</div>
				<div class="order_h flex space-between">
					<!-- <div >订单完成</div> -->
					<div v-if="orderState == 0">订单待支付</div>
					<div v-if="orderState == 1">订单待发货</div>
					<div v-if="orderState == 2">订单待收货</div>
					<div v-if="orderState == 3">订单已完成</div>
					<div v-if="orderState == 4">订单已取消</div>
					<!-- orderState -->
					<div style="margin-right: 13px;" v-if="detail.invoiceStatus == 0">开票中</div>
					<div style="margin-right: 13px;" v-if="detail.invoiceStatus == 1">已开票</div>
				</div>
			</div>
			<div class="orderd">
				<div class="orderd_t flex">
					<div class="orderd_tl">开票主体</div>
					<div class="orderd_tr" v-if="detail.billingSubject == 0">公司</div>
					<div class="orderd_tr" v-else>个人</div>
				</div>
				<div class="orderd_t flex">
					<div class="orderd_tl">开票公司名称/个人名称</div>
					<div class="orderd_tr">{{detail.name}}</div>
				</div>
				<div class="orderd_t flex" v-if="detail.dutyParagraph">
					<div class="orderd_tl">公司税号</div>
					<div class="orderd_tr">{{detail.dutyParagraph}}</div>
				</div>
				<div class="orderd_t flex">
					<div class="orderd_tl">所在地区</div>
					<div class="orderd_tr">{{detail.pcaName}}</div>
				</div>
				<div class="orderd_t flex">
					<div class="orderd_tl">联系方式</div>
					<div class="orderd_tr">{{detail.phone}}</div>
				</div>
				<div class="orderd_t flex">
					<div class="orderd_tl">账号</div>
					<div class="orderd_tr">{{detail.account}}</div>
				</div>
				<div class="orderd_t flex">
					<div class="orderd_tl">开户行</div>
					<div class="orderd_tr">{{detail.openingBank}}</div>
				</div>
				<div class="orderd_t flex">
					<div class="orderd_tl">申请时间</div>
					<div class="orderd_tr">{{detail.createTime}}</div>
				</div>
			</div>
		    
		</el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
		    dialogVisible:{
				type:Boolean,
				default: false
			},
			orderSid:{
				type:String,
				default: ''
			},
			orderState:{
				type:String,
				default: ''
			}
		},
		data() {
			return {
                dialog: false,
				id:1,
				lootList:[1,2,3,4,5],
				lootId:'',
				detail:'',
			}
		},
		watch:{
			
			dialogVisible(n,o){
				console.log(n,o,1)
				this.dialog=n
			},
			orderSid(n,o){
				console.log(n,o,2)
				if(n){
					this.recordInfo()
				}
			}
		},
		mounted() {},
		created() {

		},
		methods: {
			timestampToTime(timestamp) {
			  var date = new Date(timestamp * 1000);
			  var Y = date.getFullYear() + "-";
			  var M =
			    (date.getMonth() + 1 < 10
			      ? "0" + (date.getMonth() + 1)
			      : date.getMonth() + 1) + "-";
			  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			  var h = date.getHours() + ":";
			  var m = date.getMinutes() + ":";
			  var s = date.getSeconds();
			  return Y + M + D + h + m + s;
			},
		    recordInfo(){
				console.log(this.orderSid,123)
				let params = {
					id: this.orderSid,
				}
				
				this.$api.get(this.$apiUrls.recordInfo,{params}).then((res)=> {
					if (res.code == 0) {
						this.detail = res.data
						this.detail.invoiceTime = this.timestampToTime(this.detail.invoiceTime)
						this.detail.createTime = this.timestampToTime(this.detail.createTime)
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			noLook(){
				this.dialog = false
				this.$emit('noLook','')
			},
			// 选择数据
			lootTop(item){
				// console.log(item,123)
				this.lootId = item
			},
			// 编辑
			edit(item){
				console.log('编辑')
			},
			// 点击背景
			chufa(){
				console.log('触发')
				this.$emit('orderCallber',this.id)
			},
			// 关闭
			guanbi(){
				this.dialog = false
				this.$emit('orderCallber',this.id)
			} 
		}
	}
</script>

<style lang="scss" scoped>
	/deep/.el-dialog__body{
		padding: 0 !important;
		// padding-left: 0 !important;
		// padding-right: 0 !important;
	}
	.lookt{
		.lookt_l{
			width: 3px;
			height: 14px;
		}
		.title-text{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			margin: 0 0 0 8px;
		}
	}
	.order{
		width: 100%;
		height: 184px;
		background: url(../../static/image/39.png) 0 0 no-repeat;
		background-size: 100% 100%;
		
		text-align: center;
		padding: 23px 0 0 0;
		.order_t{
			font-size: 16px;
			font-weight: bold;
			color: #FFFFFF;
			margin: 0 0 12px 0;
		}
		.order_b{
			font-size: 12px;
			font-weight: 400;
			color: #FFFFFF;
			justify-content: center;
			align-content: center;
			img{
				width: 14px;
				height: 14px;
				margin: 0 0 0 12px;
			}
		}
		.order_d{
			width:270px;
			margin: 43px auto 0;
			.orderd{
				width: 60px;
			}
			.order_dr{
				width: 40px;
				height: 40px;
				margin: -4px 0 0 0 ;
			}
			.order_dl{
				width: 24px;
				height: 24px;
				margin: 0 6px 0 0 ;
			}
			.order_dh{
				width: 98px;
				border-top: 1px solid #EEEEEE;
				margin: 12px 0 0 0;
			}
		}
		.order_h{
			width: 300px;
			margin: 0 auto;
			padding: 0 12px 0 0 ;
			box-sizing: border-box;
			font-size: 13px;
			font-family: PingFang SC-Medium, PingFang SC;
			font-weight: 500;
			color: #666666;
		}
	}
	.orderd{
		background: #FFFFFF;
		margin: 0 23px;
		border-top: 1px solid #EEEEEE;
		padding: 22px 0 30px 0;
		box-sizing: border-box;
		.orderd_t{
			margin: 0 0 25px 0;
			.orderd_tl{
				width: 30%;
				font-size: 16px;
				font-weight: 400;
				color: #999999;
				text-align: right;
			}
			.orderd_tr{
				width: 70%;
				padding: 0 0 0 23px;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: 400;
				color: #000000;
			}
		}
		.orderd_t:last-child{
			margin: 0 0 0 0;
		}
	}
	.jindu{
		border-top: 1px solid #0DC3D6 !important;
	}

</style>