import { render, staticRenderFns } from "./person.vue?vue&type=template&id=44ae08a7&scoped=true&"
import script from "./person.vue?vue&type=script&lang=js&"
export * from "./person.vue?vue&type=script&lang=js&"
import style0 from "./person.vue?vue&type=style&index=0&id=44ae08a7&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ae08a7",
  null
  
)

export default component.exports