<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_rt flex">
					<div class="pointer" @click="tiaohuan('invoice')">发票管理</div>
					<img class="imd" src="../../static/image/7.png" alt="" srcset="">
					<div class="bodycolor" v-if="type == 1">新增发票</div>
					<div class="bodycolor" v-else>编辑发票</div>
				</div>
				
				<div class="lookEdit flex">
					<div class="lookEdit_l">开票主体</div>
					<div class="lookEdit_r">
						<el-radio v-model="radio" label="1">公司</el-radio>
						<el-radio v-model="radio" label="2">个人</el-radio>
					</div>
				</div>
				<div class="lookEdit flex">
					<div class="lookEdit_l bixuan">开票公司名称/个人名称</div>
					<div class="lookEdit_r">
						<el-input v-model="input1" placeholder="请输入开票公司名称/个人名称"></el-input>
					</div>
				</div>
				<div class="lookEdit flex">
					<div class="lookEdit_l bixuan">公司税号</div>
					<div class="lookEdit_r">
						<el-input v-model="input2" placeholder="请输入公司税号"></el-input>
					</div>
				</div>
				<div class="lookEdit flex">
					<div class="lookEdit_l bixuan">所在地区</div>
					<div class="lookEdit_r flex">
						<el-select v-model="input3" placeholder="省">
						    <el-option
						      v-for="(item,index) in options"
							  @click.native="sheng(item)"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						</el-select>
						<div class="bian"></div>
						<el-select v-model="input4" placeholder="市">
						    <el-option
						      v-for="(item,index) in options1"
							  @click.native="shi(item)"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						</el-select>
						<div class="bian"></div>
						<el-select v-model="input5" placeholder="区">
						    <el-option
						      v-for="(item,index) in options2"
							  @click.native="qu(item)"
						      :key="item.id"
						      :label="item.name"
						      :value="item.id">
						    </el-option>
						</el-select>
						
						
					</div>
				</div>
				<div class="lookEdit flex">
					<div class="lookEdit_l bixuan">详细地址</div>
					<div class="lookEdit_r">
						<el-input
						  type="textarea"
						  :rows="4"
						  placeholder="请填写详细地址：如道路、门牌号、小区、楼栋号等"
						  v-model="input6">
						</el-input>
					</div>
				</div>
				<div class="lookEdit flex">
					<div class="lookEdit_l bixuan">联系方式</div>
					<div class="lookEdit_r">
						<el-input v-model="input7" placeholder="请输入联系方式"></el-input>
					</div>
				</div>
				<div class="lookEdit flex">
					<div class="lookEdit_l bixuan">账号</div>
					<div class="lookEdit_r">
						<el-input v-model="input8" placeholder="请输入账号"></el-input>
					</div>
				</div>
				<div class="lookEdit flex">
					<div class="lookEdit_l bixuan">开户行</div>
					<div class="lookEdit_r">
						<el-input v-model="input9" placeholder="请输入开户行"></el-input>
					</div>
				</div>
				<div class="lookEdit flex">
					<div class="lookEdit_l">设置为默认抬头</div>
					<div class="lookEdit_r">
						<el-switch
						  v-model="input10"
						  active-color="#13ce66"
						  inactive-color="#EEEEEE">
						</el-switch>
					</div>
				</div>
				
				<div class="tijiao pointer" @click="baocun" v-if="type == 1">提交</div>
				<div class="tijiao pointer" @click="baocun" v-else>保存</div>
			</div>
		</div>
		<phone></phone>
		
	</div>
</template>

<script>
	import { province } from '@/api/map'
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone},
		data() {
			return {
				radio:'1',
				lestId:3,
				type:1,
				lootId:'',
				input1:'',
				input2:'',
				input3:'',
				input4:'',
				input5:'',
				input6:'',
				input7:'',
				input8:'',
				input9:'',
				input10:false,
				options:[],
				options1:[],
				options2:[],
				value: '',
				id:'',
				toType:false,
				sName:'',
				hName:'',
				qName:'',
			}
		},
		mounted() {},
		created() {
			this.options = province
			// console.log(this.options,123)
			if(this.$route.params.type){
				this.type = this.$route.params.type
			}
			if(this.$route.params.id){
				this.id = this.$route.params.id
				this.addressInfo()
			}
		},
		methods: {
			addressInfo(){
				let params = {
					id:this.id
				}
				this.$api.get(this.$apiUrls.addressInfo,{params}).then((res)=> {
					console.log(res,123)
					if (res.code == 0) {
						let data = res.data
						this.radio = data.billingSubject == 0 ? '1': '2'
						this.input1 = data.name
						this.input2 = data.dutyParagraph
						this.input3 = data.provinceCode
						this.input4 = data.cityCode
						this.input5 = data.areaCode
						this.input6 = data.address
						this.input7 = data.phone
						this.input8 = data.account
						this.input9 = data.openingBank
						this.input10 = data.sfDefault == 1 ? true : false 
						data.pcaName = data.pcaName.split(',')
						this.sName = data.pcaName[0]
						this.hName = data.pcaName[1]
						this.qName = data.pcaName[2]
						for (var i = 0; i < this.options.length; i++) {
							if(this.options[i].id == data.provinceCode){
								this.options1 = this.options[i].children
							}
						}
						for (var i = 0; i < this.options1.length; i++) {
							if(this.options1[i].id == data.cityCode){
								this.options2 = this.options1[i].children
							}
						}
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			baocun(){
				if(!this.input1){
					this.$message.error('请输入开票公司名称/个人名称');
					return
				}
				if(!this.input2){
					this.$message.error('请输入公司税号');
					return
				}
				if(!this.input3){
					this.$message.error('请选择省');
					return
				}
				if(!this.input4){
					this.$message.error('请选择市');
					return
				}
				if(!this.input5){
					this.$message.error('请选择区');
					return
				}
				if(!this.input6){
					this.$message.error('请填写详细地址');
					return
				}
				if(!this.input7){
					this.$message.error('请输入联系方式');
					return
				}
				if(!this.input8){
					this.$message.error('请输入账号');
					return
				}
				if(!this.input8){
					this.$message.error('请输入开户行');
					return
				}
				let url = ''
				let params = {
					billingSubject:this.radio == 1 ? '0':1,
					name:this.input1,
					dutyParagraph:this.input2,
					provinceCode:this.input3,
					cityCode:this.input4,
					areaCode:this.input5,
					address:this.input6,
					phone:this.input7,
					account:this.input8,
					openingBank:this.input9,
					sfDefault:this.input10 ? 1 : '0',
					pcaName:this.sName+this.hName+this.qName
				}
				if(this.id){
					url = this.$apiUrls.addressEdit
					params.id = this.id
				}else{
					url = this.$apiUrls.addressSave
				}
				if(this.toType){
					return
				}
				this.toType = true
				this.$api.post(url,params).then((res)=> {
					// console.log(res,123)
				    if (res.code == 0) {
						if(this.id){
							this.$message.success('修改成功');
						}else{
							this.$message.success('添加成功');
						}
						this.$router.back() 
				    }else{
						this.toType = false
					}
					this.toType = false
				})
			},
			sheng(item){
				this.options1 = item.children
				this.sName = item.name
				this.input4 = ''
				this.input5 = ''
			},
			shi(item){
				this.options2 = item.children
				this.hName = item.name
				
				this.input5 = ''
			},
			qu(item){
				this.qName = item.name
			},
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-select{
			width: 126px;
			margin-right: 18px;
			input{
				width: 126px;
			}
		}
		.el-textarea{
			width: 414px;
		}
	}
	/deep/ input{
		height: 48px;
		width: 414px;
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 20px 0;
			.imd{
				width: 7px;
				height: 12px;
				margin: 5px 12px 0;
			}
		}
	}

	.lookEdit{
		margin: 0 0 24px 0;
		.lookEdit_l{
			width: 30%;
			text-align: right;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 48px;
		}
		.lookEdit_r{
			width: 70%;
			padding-left: 24px;
			line-height: 48px;
		}
	}
	.tijiao{
		width: 184px;
		height: 53px;
		background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
		border-radius: 28px 28px 28px 28px;
		text-align: center;
		color: #fff;
		font-size: 18px;
		font-weight: 400;
		color: #FFFFFF;
		margin: 40px auto 0;
		line-height: 53px;
	}
</style>