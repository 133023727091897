<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="inded('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r">
				<div class="person_rt flex">
					<div class="pointer" @click="inded('order')">我的订单</div>
					<img class="imd" src="../../static/image/7.png" alt="" srcset="">
					<div class="bodycolor">订单详情</div>
				</div>
				
				<div class="detlt">
					<div class="detlt_t flex">
						<div class="detlt_tl">订单号：{{detail.orderNo}}</div>
						<div class="detlt_tr">
							<div v-if="detail.orderState == 1">您的订单仓库正在备货，请耐心等待~</div>
							<div v-if="detail.orderState == 2">您的订单已发货，请注意收货~</div>
							<div v-if="detail.orderState == 3">您的订单完成，期待下次合作~</div>
							<div v-if="detail.orderState == 4">您的订单已取消</div>
						</div>
					</div>
					<div class="detlt_h flex">
						<div class="detlt_hl">
							<div class="detlt_hlt bodycolor">
								<!-- <div v-if="detail.">待付款</div> -->
								<div v-if="detail.orderState == 0">待支付</div>
								<div v-if="detail.orderState == 1">待发货</div>
								<div v-if="detail.orderState == 2">待收货</div>
								<div v-if="detail.orderState == 3">已完成</div>
								<div v-if="detail.orderState == 4">已取消</div>
							</div>
							<div class="detlt_hlh flex">
								<div class="orderf_2 pointer" v-if="detail.orderState == 0" @click="adPay()">去支付</div>
								<div class="orderf_1 pointer" v-if="detail.orderState == 1 && detail.sfRemind == 0" @click="sendOut">提醒发货</div>
								<div class="orderf_5 pointer" v-if="detail.orderState == 4 || detail.orderState == 3" @click="detailTop()">删除订单</div>
								<!-- <div class="orderf_2 pointer" v-if="detail.orderState == 3 && detail.sfInvoice != 1" @click="add()">申请开票</div> -->
								<div class="orderf_2 pointer" v-if="detail.orderState == 3 && detail.sfInvoice == 1" @click="addDetal()">开票信息</div>
								<div class="orderf_3 pointer" v-if="detail.orderState == 2" @click="confirmTop()">确认收货</div>
								<div class="orderf_4 pointer" v-if="detail.orderState == 2" @click="look()">查看物流</div>
							</div>
						</div>
						<div class="detlt_hr">
							<div class="detlt_hrt flex">
								<div class="detlt_hrtl flex">
									<img class="imd" v-if="detail.orderState >= 1 && detail.orderState != 4" src="../../static/image/30.png" alt="" srcset="">
									<img class="imd" v-else src="../../static/image/31.png" alt="" srcset="">
								</div>
								<div class="detlt_hrth bodycolor">
									<div v-if="detail.orderState >= 1 && detail.orderState != 4">待发货</div>
								</div>
								<div class="detlt_hrtl flex">
									<img class="imd" v-if="detail.orderState >= 2 && detail.orderState != 4" src="../../static/image/32.png" alt="" srcset="">
									<img class="imd" v-else src="../../static/image/33.png" alt="" srcset="">
								</div>
								<div class="detlt_hrth bodycolor">
									<div v-if="detail.orderState >= 2 && detail.orderState != 4">待收货</div>
								</div>
								<div class="detlt_hrtl flex">
									<img class="imd" v-if="detail.orderState == 3 && detail.orderState != 4" src="../../static/image/34.png" alt="" srcset="">
									<img class="imd" v-else src="../../static/image/35.png" alt="" srcset="">
								</div>
								<div class="detlt_hrth bodycolor">
									<div v-if="detail.orderState == 3 && detail.orderState != 4">已完成</div>
								</div>
								<div class="detlt_hrtl flex">
									<img class="imd" v-if="detail.orderState == 3 && detail.orderState != 4" src="../../static/image/36.png" alt="" srcset="">
									<img class="imd" v-else src="../../static/image/37.png" alt="" srcset="">
								</div>
							</div>
							<div class="detlt_hrh flex">
								<div class="detlt_hrhl" :class="detail.orderState >= 1 ? '' : ''">付款成功</div>
								<div class="detlt_hrhh " :class="detail.orderState >= 1 && detail.orderState != 4 ? 'borderBottm' : ''"></div>
								
								<div class="detlt_hrhl" :class="detail.orderState == 1 ? 'bodycolor' : ''">待发货</div>
								<div class="detlt_hrhh" :class="detail.orderState > 1 && detail.orderState != 4 ? 'borderBottm' : ''"></div>
								<div class="detlt_hrhl" :class="detail.orderState == 2 ? 'bodycolor' : ''">等待收货</div>
								<div class="detlt_hrhh " :class="detail.orderState > 2 && detail.orderState != 4 ? 'borderBottm' : ''"></div>
								<div class="detlt_hrhl" :class="detail.orderState == 3 ? 'bodycolor' : ''">交易成功</div>
							</div>
							<div class="detlt_hrd flex" v-if="detail.orderState != 4 && detail.orderState >= 1">
								<div class="detlt_hrhl">{{detail.payTime}}</div>
								<!-- <div class="detlt_hrhh "></div>
								<div class="detlt_hrhl">2021-11-04 13:39:10</div>
								<div class="detlt_hrhh"></div>
								<div class="detlt_hrhl">2021-11-04 13:39:10</div>
								<div class="detlt_hrhh "></div>
								<div class="detlt_hrhl">2021-11-04 13:39:10</div> -->
							</div>
						</div>
					</div>

				</div>
				
				<div class="detl_t">
					<div class="detl_tt flex">
						<div class="detl_ttl">订单信息</div>
						<div class="detl_ttr">CVS文件</div>
					</div>
					<div class="detl_th flex">
						<div class="detl_thl">
							<div class="detl_thlt flex">
								<div>订单编号：{{detail.orderNo}}</div>
								<div class="detl_thlr bodycolor pointer" @click="theCopy(detail.orderNo)">复制</div>
							</div>
							<div class="detl_thlt">下单时间：{{detail.orderTime}}</div>
							<div class="detl_thlt flex" v-if="detail.orderState > 0 &&detail.orderState < 4">
								<div>付款方式：</div>
								<div v-if="detail.payType == 0">微信支付</div>
								<div v-if="detail.payType == 1">支付宝支付</div>
								<div v-if="detail.payType == 2">余额支付</div>
							</div>
							<div class="detl_thlt" v-if="detail.orderState > 0 &&detail.orderState < 4">支付时间：{{detail.payTime}}</div>
						</div>
						<div class="detl_thr">
							<div class="flex">
								<div>文件:</div>
								<div style="margin: 0 0 0 20px;">
									<div class="detl_thrl flex" v-for="(item,index) in detail.fileInfo" @click="xiazia(item)">
										<div class="pointer">{{item.fileExplainName}}</div>
									</div>
								</div>
							</div>
							
							<div class="flex" style="margin: 20px 0 0 0 ;">
								<div>合同:</div>
								<div style="margin: 0 0 0 20px;">
									<div class="detl_thrl flex" v-for="(item,index) in detail.fileInfoHt" @click="xiazia(item)">
										<div class="pointer">{{item.fileExplainName}}</div>
									</div>
								</div>
							</div>
							
							
							
						</div>
					</div>
				</div>
				
				<div class="detl_h">
					<div class="detl_ht">费用信息</div>
					<div class="detl_hh">
						<div class="detl_hhd flex">
							<div>工程费：￥{{detail.projectMoney}}</div>
							<div class="detl_hhdr" v-if="detail.invoiceMoney">发票税费：￥{{detail.invoiceMoney}}</div>
						</div>
						<!-- <div class="detl_hhd">贴片费：￥{{detail.pasterTypeMoney}}</div> -->
						<div class="detl_hhd">贴片焊点费：￥{{detail.weldingSpotMoney}}</div>
						<div class="detl_hhd">插件焊接费：￥{{detail.craftWeldingSpotMoney}}</div>
						<div class="detl_hhd">上料费：￥{{detail.reloadingMoney}}</div>
						<div class="detl_hhd">钢网费：￥{{detail.steelMeshMoney}}</div>
						<div class="detl_hhd">元器件费：￥{{detail.steelMeshTypeMoney}}</div>
					</div>
				</div>
				
				<div class="order_h flex">
					<div class="order_hl">订单信息</div>
					<div class="order_hl">交期</div>
					<div class="order_hl">价格</div>
					<div class="order_hl">订单状态</div>
					<div class="order_hl">订单操作</div>
				</div>
				
				<div class="order_d">
					<div class="order_dt flex">
						<div>{{detail.orderTime}}</div>
						<div>订单编号：{{detail.orderNo}}</div>
					</div>
					<div class="order_dh flex">
						<div class="order_dhl ordero">
							<div class="ordero_t flex">贴片层：
							    <div v-if="detail.pasterType == 0">官方提供</div>
							    <div v-if="detail.pasterType == 1">双层贴片</div>
							    <div v-if="detail.pasterType == 2">单层贴片</div>
							    <div v-if="detail.pasterType == 3">顶层+底层全贴</div>
							    <div v-if="detail.pasterType == 4">只贴顶层</div>
							    <div v-if="detail.pasterType == 5">只贴底层</div>
							</div>
							<div class="ordero_t p1" v-if="detail.pasterNum">贴片数量：{{detail.pasterNum}}pcs</div>
							<div class="ordero_t p1">贴片元件：{{detail.pasterDeviceStart}}焊点/pcs，{{detail.pasterDeviceEnd}}种类/pcs</div>
							<div class="ordero_t p1">插件焊接：{{detail.plugInStart}}焊点/pcs，{{detail.plugInEnd}}种类/pcs</div>
							<div class="ordero_t p1" v-if="detail.weldCraft == 0">焊接工艺：无铅高温</div>
							<div class="ordero_t p1" v-else>焊接工艺：有铅高温</div>
							<div class="ordero_t p1" v-if="detail.steelMeshType == '0'">备料方式：客户提供</div>
							<div class="ordero_t p1" v-if="detail.steelMeshType == 1">备料方式：贝旭提供</div>
							<div class="ordero_t p1" v-if="detail.steelMesh == '0'">钢网：普通钢网</div>
							<div class="ordero_t p1" v-if="detail.steelMesh == 1">钢网：精密钢网</div>
						</div>
						
						
						
						<div class="order_dhl orderT">{{detail.deliveryName}}</div>
						<div class="order_dhl orders">
							<div class="orders_t">实付</div>
							<div class="orders_h">￥{{detail.payMoney}}</div>
						</div>
						<div class="order_dhl orderT" v-if="detail.orderState == 0">待支付</div>
						<div class="order_dhl orderT" v-if="detail.orderState == 1">待发货</div>
						<div class="order_dhl orderT" v-if="detail.orderState == 2">待收货</div>
						<div class="order_dhl orderT" v-if="detail.orderState == 3">已完成</div>
						<div class="order_dhl orderT" v-if="detail.orderState == 4">已取消</div>
						<div class="order_dhl orderf">
							<div class="orderf_t absolute">
								<div class="orderf_1 pointer" v-if="detail.orderState == 1 && detail.sfRemind == 0" @click="sendOut">提醒发货</div>
								<div class="orderf_5 pointer" v-if="detail.orderState == 1 && detail.sfRemind == 1">已提醒</div>
								<!-- <div class="orderf_2 pointer" v-if="detail.orderState >= 1 && detail.orderState < 4 && detail.sfInvoice == 1" @click="add()">申请开票</div> -->
								<div class="orderf_2 pointer" v-if="detail.orderState >= 1 && detail.orderState < 4 && detail.sfInvoice == 1" @click="addDetal()">开票信息</div>
								<div class="orderf_3 pointer" v-if="detail.orderState == 2" @click="confirmTop()">确认收货</div>
								<div class="orderf_4 pointer" v-if="detail.orderState == 2" @click="look()">查看物流</div>
								<div class="orderf_5 pointer" v-if="detail.orderState == 4 || detail.orderState == 3" @click="detailTop()">删除订单</div>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="detl_d flex flex-end">
					<p  style="padding: 10px 30px 0 0;">费用总计：</p>
					<p>¥ {{detail.allMoney}} </p>
				</div>
				<div class="detl_b flex flex-end">
					<p style="padding: 4px 30px 0 0;">实付款:</p>
					<p>¥ {{detail.payMoney}} </p>
					<p style="margin:  0 0 0 10px;">（含{{detail.invoiceBfb}}%税费）</p>
				</div>
			</div>
		</div>
		
		<!-- 手机弹框 -->
		<phone></phone>
		<lookOrder :dialogVisible="orderVisible" :orderSid='orderSid' :orderState="detail.orderState" @orderCallber="orderCallber" ></lookOrder>
		<logistics :logisVisible="logisVisible" :wuliu="wuliu" @logisCallber="logisCallber" ></logistics>
		<el-dialog
		  title="提示"
		  :visible.sync="detalType"
		  width="30%">
		  <span>确定删除该订单么？</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="detalType = false">取 消</el-button>
		    <el-button type="primary" @click="detailQd">确 定</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  title="提示"
		  :visible.sync="confirmType"
		  width="30%">
		  <span>确定收货么？</span>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="confirmType = false">取 消</el-button>
		    <el-button type="primary" @click="confirmQd">确 定</el-button>
		  </span>
		</el-dialog>
		<!-- 选择发票 -->
		<lookUp :dialogVisible="dialogVisiblr" :type="2" @callber="callber" @noLook="noLook" @callberr="callberr" @addLook="addLook" @edit="edit" ref="child"></lookUp>
		<!-- 添加发票 -->
		<lookEdit :edId="edId" :dialogVisible="editVisible" @addAlss="addAlss" @noLookEdit="noLookEdit"></lookEdit>
	</div>
</template>

<script>
	
	import lookUp from "@/components/lookUp.vue"
	import lookEdit from "@/components/lookEdit.vue"
    import logistics from "@/components/logistics.vue"
    import lookOrder from "@/components/lookOrder.vue"
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone,lookOrder,logistics,lookUp,lookEdit},
		data() {
			return {
				lestId:1,
				input1:'',
				value1:'',
				orderVisible:false,
				logisVisible:false,
				detalType:false,
				confirmType:false,
				id:'',
				detail:'',
				wuliu:{},
				
				
				dialogVisiblr:false,
				titouId:'',
				orderSid:'',
				editVisible:false,
				edId:'',
			}
		},
		mounted() {},
		created() {
			if(this.$route.params.id){
				this.id = this.$route.params.id
				// this.orderSid = this.id
			}
			this.orderInfo()
		},
		methods: {
			// 关闭新增开票弹框
			noLookEdit(){
				this.editVisible = false
			},
			// 确定添加
			addAlss(){
				this.editVisible = false
				this.$refs.child.sing()
				this.edId = ''
			},
			// 查看发票信息
			addDetal(){
				this.orderVisible = true
				this.orderSid = this.id
			},
			// 申请开票
			addInvoice(){
				let params = {
					orderId: this.id,
					invoiceAddressId:this.titouId,
				}
				
				this.$api.post(this.$apiUrls.addInvoice,params).then((res)=> {
					if (res.code == 0) {
						this.dialogVisiblr = false
						this.$message.success('申请成功！');
						this.orderInfo()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 关闭抬头弹框
			callberr(id){
				if(id){
					this.titouId = id
					this.addInvoice()
				}else{
					this.$message.error('请选择开票信息');
				}
			},
			callber(id,name){
				console.log(id,name,1234)
				if(id){
					this.titouId = id
					// this.addInvoice()
				}
				// if(name == 'x'){
					this.dialogVisiblr = false
				// }
			},
			// 不开发票
			noLook(){
				// this.lookName = '不开发票'
				// this.dialogVisible = false
				// this.id = ''
			},
			// 添加抬头
			addLook(){
				this.editVisible = true
			},
			// 编辑发表
			edit(id){
				this.edId = id
			},
			// 发票关闭
			orderCallber(type){
				this.orderVisible = false
			},
			
			// 申请发票
			add(){
				console.log(123)
				this.dialogVisiblr = true
				// this.orderVisible = true
				// this.orderSid = this.id
			},
			
			
			
			xiazia(item){
				window.open(item.filePath)
				// window.location.href=item.filePath
			},
			adPay(){
				window.sessionStorage.setItem('order',JSON.stringify(this.detail));
				this.$router.push({
					name: 'submit',
					params: {
						type:1,
					},
				});
			},
			inded(url){
				this.$router.push({
					name: url,
					params: {},
				});
			},
			// 时间转
			timestampToTime(timestamp) {
			  var date = new Date(timestamp * 1000);
			  var Y = date.getFullYear() + "-";
			  var M =
			    (date.getMonth() + 1 < 10
			      ? "0" + (date.getMonth() + 1)
			      : date.getMonth() + 1) + "-";
			  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
			  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":";
			  var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
			  return Y + M + D + h + m + s;
			},
			// 订单详情
			orderInfo(){
				let params = {
					id:this.id
				}
				this.$api.get(this.$apiUrls.orderInfo,{params}).then((res)=> {
					if (res.code == 0) {
						this.detail = res.data
						this.detail.orderTime = this.timestampToTime(this.detail.orderTime)
						this.detail.payTime = this.timestampToTime(this.detail.payTime)
						
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			theCopy(orderNo) {
				// 创建输入框元素
				let oInput = document.createElement('input');
				// 将想要复制的值
				oInput.value = orderNo;
				// 页面底部追加输入框
				document.body.appendChild(oInput);
				// 选中输入框
				oInput.select();
				// 执行浏览器复制命令
				document.execCommand('Copy');
				// 弹出复制成功信息
				this.$message.success('复制成功');
				// 复制后移除输入框
				oInput.remove();
			},
			// 确定
			confirmQd(){
				let params = {
					id:this.id
				}
				this.$api.post(this.$apiUrls.confirmReceipt,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('收货成功！');
						this.confirmType = false
						this.orderInfo()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 确认收货
			confirmTop(){
				this.confirmType = true
				// this.id = item.id
			},
			// 提醒发货
			sendOut(){
				
				this.$message.success('提醒成功！');
				let params = {
					id:this.id
				}
				this.$api.post(this.$apiUrls.orderRemind,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('提醒成功！');
						this.orderInfo()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 删除订单
			detailTop(){
				this.detalType = true
				// this.id = item.id
			},
			// 确认删除
			detailQd(){
				
				let params = {
					id:this.id
				}
				this.$api.post(this.$apiUrls.orderDel,params).then((res)=> {
					if (res.code == 0) {
						this.$message.success('删除成功！');
						this.detalType = false
						this.$router.back() 
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 物流关闭
			logisCallber(){
				this.logisVisible = false
			},
			// 查看物流
			look(){
				let params = {
					id:this.id
				}
				this.$api.get(this.$apiUrls.orderLogistics,{params}).then((res)=> {
					if (res.code == 0) {
						this.wuliu = res.data
				        this.logisVisible = true
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 申请发票
			// add(){
			// 	this.orderVisible = true
			// 	// this.id = item.id
			// },
			// 发票
			// orderCallber(type){
			// 	this.orderVisible = false
			// },
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
	
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px 91px;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 20px 0;
			.imd{
				width: 7px;
				height: 12px;
				margin: 5px 12px 0;
			}
		}
	}
	.confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.detlt{
		border: 1px solid #EEEEEE;
		margin: 0 0 27px 0;
		.detlt_t{
			background: #FAFAFA;
			padding: 8px 0;
			box-sizing: border-box;
			border-bottom: 1px solid #EEEEEE;
			.detlt_tl{
				width: 35%;
				border-right: 1px solid #CFDDDE;
				font-size: 14px;
				font-weight: bold;
				color: #333333;
				padding: 0 0 0 14px;
				box-sizing: border-box;
			}
			.detlt_tr{
				width: 65%;
				font-size: 14px;
				font-weight: bold;
				color: #333333;
				padding: 0 0 0 57px;
				box-sizing: border-box;
			}
		}
		.detlt_h{
			height: 193px;
			.detlt_hl{
				width: 35%;
				border-right: 1px solid #EEEEEE;
				.detlt_hlt{
					text-align: center;
					font-size: 20px;
					font-weight: bold;
					text-align: center;
					margin: 47px 0 36px 0;
				}
				.detlt_hlh{
					div:nth-child(2n){
						margin:0 0 0 16px;
					}
					justify-content: center;
					align-content: center;
					.orderf_5,
					.orderf_4,
					.orderf_3,
					.orderf_2,
					.orderf_1{
						width: 86px;
						height: 32px;
						border-radius: 20px 20px 20px 20px;
						font-size: 12px;
						font-weight: 400;
						line-height: 32px;
						text-align: center;
						// margin: 0 16px 16px 0 ;
					}
					.orderf_1{
						background: rgba(232,38,154,0.06);
						border: 1px solid #E8269A;
						color: #E8269A;
					}
					.orderf_2{
						background: rgba(3,224,221,0.06);
						border: 1px solid #0DC3D6;
						color: #0DC3D6;
					}
					.orderf_3{
						background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
						color: #FFFFFF;
					}
					.orderf_4{
						background: #1C95C9;
						color: #FFFFFF;
					}
					.orderf_5{
						border: 1px solid #999999;
						color: #999999;
					}
				}
			}
			.detlt_hr{
				width: 65%;
				.detlt_hrt{
					padding: 64px 0 0 62px ;
					box-sizing: border-box;
					.detlt_hrtl{
						width: 66px;
						justify-content: center;
						align-content: center;
						.imd{
							width: 25px;
							height: 25px;
							margin: 0 auto;
						}
					}
					.detlt_hrth{
						width: 92px;
						margin: 0 12px;
						font-size: 14px;
						font-weight: 400;
						text-align: center;
					}
				}
				.detlt_hrh{
					margin: 10px 0 0 0;
					padding: 0 0 0 62px;
					box-sizing: border-box;
					.detlt_hrhl{
						width: 66px;
						font-size: 14px;
						font-weight: 400;
						color: #333333;
						text-align: center;
					}
					.detlt_hrhh{
						width: 92px;
						margin: 0 12px 8px;
						border-bottom: 1px dashed #999999;
					}
				}
				.detlt_hrd{
					margin: 10px 0 0 0;
					padding: 0 0 0 62px;
					box-sizing: border-box;
					.detlt_hrhl{
						width: 66px;
						font-size: 12px;
						font-weight: 400;
						color: #666666;
						text-align: center;
					}
					.detlt_hrhh{
						width: 92px;
						margin: 0 12px;
					}
				}
			}
		}
	}

    .detl_t{
		border: 1px solid #EEEEEE;
		margin: 0 0 27px 0;
		.detl_tt{
			background: #FAFAFA;
			padding: 8px 0;
			box-sizing: border-box;
			border-bottom: 1px solid #EEEEEE;
			.detl_ttl{
				width: 40%;
				border-right: 1px solid #CFDDDE;
				font-size: 14px;
				font-weight: bold;
				color: #333333;
				padding: 0 0 0 14px;
				box-sizing: border-box;
			}
			.detl_ttr{
				width: 60%;
				font-size: 14px;
				font-weight: bold;
				color: #333333;
				padding: 0 0 0 82px;
				box-sizing: border-box;
			}
		}
		.detl_th{
			height: 193px;
			.detl_thl{
				padding: 20px 0 0 14px;
				box-sizing: border-box;
				width: 40%;
				border-right: 1px solid #EEEEEE;
				.detl_thlt{
					font-size: 12px;
					font-weight: 400;
					color: #333333;
					margin: 0 0 20px 0;
					.detl_thlr{
						margin: 0 0 0 20px;
					}
				}
			}
			.detl_thr{
				width: 60%;
				padding: 20px 0 0 82px;
				box-sizing: border-box;
				.detl_thrl{
					font-size: 12px;
					font-weight: 400;
					color: #333333;
					.imd{
						width: 20px;
						height: 20px;
						margin: 0 10px 0 0;
					}
				}
			}
		}
	}
	
	.detl_h{
		border: 1px solid #EEEEEE;
		.detl_ht{
			height: 48px;
			width:100%;
			font-size: 14px;
			font-weight: bold;
			color: #333333;
			line-height: 48px;
			background: #FAFAFA;
			border-bottom: 1px solid #EEEEEE;
			padding: 0 14px;
			box-sizing: border-box;
		}
		.detl_hh{
			padding: 20px 0 28px 14px;
			box-sizing: border-box;
			.detl_hhd{
				font-size: 12px;
				font-weight: 400;
				color: #333333;
				margin: 0 0 16px 0;
				.detl_hhdr{
					margin: 0 0 0 51px;
				}
			}
		}
	}
	
	.order_d{
		// height: 270px;
		border: 1px solid #CFDDDE;
		border-top: none;
		.order_dt{
			height: 47px;
			line-height: 47px;
			padding: 0 0 0 15px;
			box-sizing: border-box;
			font-size: 14px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #CFDDDE;
			width: 100%;
			div{
				margin: 0 20px 0 0 ;
			}
		}
		.order_dh{
			// width: 100%;
			height: 255px;
			.order_dhl{
				width: 20%;
				height: 100%;
				border-right: 1px solid #CFDDDE;
			}
			.order_dhl:last-child{
				border-right:none;
			}
			.orderT{
				text-align: center;
				line-height: 193px;
				font-size: 12px;
				font-weight: bold;
				color: #333333;
			}
			.ordero{
				padding: 20px 14px 0 14px;
				box-sizing: border-box;
				.ordero_t{
					font-size: 12px;
					font-weight: 400;
					color: #333333;
					margin: 0 0 18px 0;
				}
				.ordero_t:last-child{
					margin: 0;
				}
			}
			.orders{
				padding: 74px 0 0 0;
				box-sizing: border-box;
				text-align: center;
				.orders_t{
					font-size: 12px;
					font-weight: 400;
					color: #333333;
					margin: 0 0 12px 0;
				}
				.orders_h{
					font-size: 13px;
					font-weight: bold;
					color: #FF0000;
				}
				.orders_r{
					font-size: 12px;
					font-weight: 400;
					color: #1890FF;
				}
			}
			.orderf{
				position: relative;
				.orderf_t{
					top: 50%;
					left: 50%;
					transform: translate(-50% ,-50%);
				}
				.orderf_5,
				.orderf_4,
				.orderf_3,
				.orderf_2,
				.orderf_1{
					width: 86px;
					height: 32px;
					border-radius: 20px 20px 20px 20px;
					font-size: 12px;
					font-weight: 400;
					line-height: 32px;
					text-align: center;
					margin: 0 0 16px 0 ;
				}
				.orderf_1{
					background: rgba(232,38,154,0.06);
					border: 1px solid #E8269A;
					color: #E8269A;
				}
				.orderf_2{
					background: rgba(3,224,221,0.06);
					border: 1px solid #0DC3D6;
					color: #0DC3D6;
				}
				.orderf_3{
					background: linear-gradient(90deg, #00E7DF 0%, #1C95C9 100%);
					color: #FFFFFF;
				}
				.orderf_4{
					background: #1C95C9;
					color: #FFFFFF;
				}
				.orderf_5{
					border: 1px solid #999999;
					color: #999999;
				}
			}
		}
	}
	
	.order_h{
		width: 100%;
		height: 60px;
		padding: 15px 0 13px 0;
		box-sizing: border-box;
		background: #FAFAFA;
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
		border: 1px solid #EEEEEE;
		margin: 23px 0 0 0;
		.order_hl{
			width: 20%;
			border-right: 1px solid #CFDDDE;
			font-size: 18px;
			font-weight: 400;
			color: #333333;
			text-align: center;
			// line-height: 60px;
		}
		.order_hl:last-child{
			border-right:none;
		}
	}
	
	.detl_d{
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		margin: 30px 0 ;
		p:nth-child(2){
			font-size: 22px;
			font-weight: bold;
			color: #333333;
		}
	}
	
	.detl_b{
		text-align: right;
		padding:0 20px;
		box-sizing: border-box;
		height: 60px;
		background: rgba(255,43,43,0.03);
		font-size: 16px;
		font-family: Microsoft YaHei-Regular, Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		line-height: 60px;
		p:nth-child(2){
			font-size: 22px;
			font-weight: bold;
			color: #FF2B2B;
		}
	}


</style>