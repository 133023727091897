<template>
<div class="page">
	<div class="w1200 flex bottm">
		<div class="bottm_l">
			<!-- <div>项目LOGO</div> -->
			<img class="bottm_l1" src="../../static/image/55.png" alt="" srcset="">
		</div>
		<div class="bottm_h">
			<div class="bottm_ht">SMT服务</div>
			<div class="bottm_hh pointer" @click="tiaozhuan('index','0')">在线下单</div>
		</div>
		<div class="bottm_h">
			<div class="bottm_ht">关于我们</div>
			<div class="bottm_hh pointer" @click="tiaozhuan('brief',1)">公司简介</div> 
			<div class="bottm_hh pointer" @click="tiaozhuan('rule')">账户充值信息</div>
			<div class="bottm_hh pointer" @click="tiaozhuan('brief',1)">联系我们</div>
		</div>
		<div class="bottm_r">
			<div class="bottm_ht">咨询</div>
			<div class="bottm_hh">服务热线：{{detail.phone}}</div>
			<div class="bottm_hh">服务时间：{{detail.fwTime}}</div>
			<div class="bottm_hh">邮箱：{{detail.email}}</div>
		</div>
	</div>
	<div class="paged">
		<p class="w1200 pointer" @click="tiaohzuan">{{content1}}</p>
        <!-- <p class="w1200">沪ICP备100000号-1</p> -->
	</div>
</div>
</template>

<script>
  export default {
    name: 'nav-footer',
    data(){
      return{
		  detail:'',
		  content1:'',
      }
    },
    mounted() {
      this.usInfo();
	  this.configCode1()
    },
    methods: {
		tiaohzuan(){
			window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
		},
		configCode1(){
			let params = {
				code:'BQSY'
			}
			this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
				if (res.code == 0) {
					this.content1 = res.data.value
			    }else{
					this.$message.error(res.msg);
					return
				}
			})
		},
		usInfo(){
			this.$api.get(this.$apiUrls.usInfo,).then((res)=> {
			    if (res.code == 0) {
					this.detail = res.data
			    }
			})
		},
		tiaozhuan(url,type){
			if(url == 'rule' && !window.sessionStorage.getItem('token')){
				this.$router.push({
					name: 'login',
					params: {type:1},
				});
				return
			}
			if(type){
				window.sessionStorage.setItem('statusType', type);
			}
			let page = {}
			this.$router.push({
				name: url,
				params: page,
			});
		},
		queryGeneralInfo(){
			// this.$api.get(this.$apiUrls.queryGeneralInfo,).then((res)=> {
			//     if (res.code == 1) {
			// 		this.detail = res.data
			//     }
			// })
		},
    }
  }
</script>

<style lang="scss" scoped>
	.bottm_l1{
		width: 200px;
	}
	.page{
		width: 100%;
		height: 270px;
		background: #FFFFFF;
		// background: linear-gradient(95deg, #00E7DF 0%, #1C95C9 100%);
		border-radius: 0px 0px 0px 0px;
		opacity: 1;
	}
	.bottm{
		height: 220px;
		padding: 26px 0 33px;
		box-sizing: border-box;
		.bottm_l,
		.bottm_h,
		.bottm_r,
		.bottm_b{
			border-right: 1px solid rgba(255,255,255,0.44);
			// width: 20%;
			padding: 18px 80px 0;
			box-sizing: border-box;
		}
		.bottm_l{
			padding: 28px 37px 0;
			box-sizing: border-box;
			div{
				width: 180px;
				height: 46px;
				background: rgba(255,255,255,.22);
				border-radius: 6px 6px 6px 6px;
				font-size: 18px;
				font-weight: 400;
				color: #000;
				line-height: 46px;
				text-align: center;
			}
		}
		.bottm_h{
			.bottm_ht{
				font-size: 16px;
				font-weight: 400;
				color: #000;
				margin: 0px 0 20px 0;
			}
			.bottm_hh{
				font-size: 14px;
				font-weight: 400;
				color: #000;
				margin: 0 0 16px 0;
			}
		}
		.bottm_r{
			border-right: none;
			.bottm_ht{
				font-size: 16px;
				font-weight: 400;
				color: #000;
				margin: 0px 0 20px 0;
			}
			.bottm_hh{
				font-size: 14px;
				font-weight: 400;
				color: #000;
				margin: 0 0 16px 0;
			}
		}
	}
	.paged{
		width: 100%;
		height: 50px;
		background: rgba(255,255,255,.22);
		border-radius: 0px 0px 0px 0px;
		// opacity: 0.22;
		text-align: center;
		// padding: 10px 0;
		box-sizing: border-box;
		p{
			line-height: 50px;
			font-size: 12px;
			font-weight: 400;
			color: #000;
		}
	}
</style>