import { render, staticRenderFns } from "./brief.vue?vue&type=template&id=3d489efb&scoped=true&"
import script from "./brief.vue?vue&type=script&lang=js&"
export * from "./brief.vue?vue&type=script&lang=js&"
import style0 from "./brief.vue?vue&type=style&index=0&id=3d489efb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d489efb",
  null
  
)

export default component.exports