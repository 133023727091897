<template>
	<div class="w1200 page">
		<div class="flex confirm_t">
			<div class="pointer" @click="inded('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">确认订单</div>
		</div>
		<div class="confirm flex" v-if="orderType == 2">
			<div class="confirm_l">
				<div class="confirm_lt">费用清单</div>
				<!-- <div class="confirm_lh flex">
					<div class="confirm_lhl">贴片费</div>
					<div class="confirm_lhr">¥{{detal.pasterTypeMoney || 0}}</div>
					<div class="confirm_lhb" v-if="detal.pasterTypeMoney == 0">免费</div>
				</div> -->
				<div class="confirm_lh flex">
					<div class="confirm_lhl">工程费</div>
					<div class="confirm_lhr">¥{{detal.projectMoney}}</div>
					<!-- <div class="confirm_lhb" v-if="detal.projectMoney == 0">免费</div> -->
				</div>
				<div class="confirm_lh flex">
					<div class="confirm_lhl">贴片焊点费</div>
					<div class="confirm_lhr">¥{{detal.weldingSpotMoney}}</div>
					<!-- <div class="confirm_lhb" v-if="detal.weldingSpotMoney == 0">免费</div> -->
				</div>
				<div class="confirm_lh flex">
					<div class="confirm_lhl">插件焊接费</div>
					<div class="confirm_lhr">¥{{detal.craftWeldingSpotMoney}}</div>
					<!-- <div class="confirm_lhb" v-if="detal.craftWeldingSpotMoney == 0">免费</div> -->
				</div>
				<div class="confirm_lh flex">
					<div class="confirm_lhl">上料费</div>
					<div class="confirm_lhr">¥{{detal.reloadingMoney}}</div>
					<!-- <div class="confirm_lhb" v-if="detal.reloadingMoney == 0">免费</div> -->
				</div>
				<div class="confirm_lh flex">
					<div class="confirm_lhl">钢网费</div>
					<div class="confirm_lhr">¥{{detal.steelMeshMoney}}</div>
					<!-- <div class="confirm_lhb" v-if="detal.steelMeshMoney == 0">免费</div> -->
				</div>
				<!-- <div class="confirm_lh flex">
					<div class="confirm_lhl">包装费</div>
					<div class="confirm_lhr">¥{{detal.packagingMoney}}</div>
					<div class="confirm_lhb" v-if="detal.packagingMoney == 0">免费</div>
				</div> -->
			
				<div class="confirm_lh flex" v-if="params.steelMeshType == 1">
					<div class="confirm_lhl">元器件费</div>
					<div class="confirm_lhr flex">
						<div class="jine">¥</div>
						<el-input placeholder="请输入" :maxlength='11' v-model="detal.steelMeshTypeMoney " class="qijian" @input="handleEdit3" @change="vitality"></el-input>
						<!-- {{detal.yqjPieceMoney}} -->
					</div>
					<!-- <div class="confirm_lhb" v-if="detal.yqjPieceMoney == 0">免费</div> -->
				</div>
				<div class="confirm_lh flex" v-if="params.steelMeshType == '0'">
					<div class="confirm_lhl">收料地址</div>
					<div class="confirm_lhr">{{detal.steelMeshTypeAddress}}</div>
				</div>
				<div class="confirm_lt">上传合同</div>
				
				<div class="confirm_lb flex space-between">
				 	<div class="confirm_lbl">合同</div>
					<el-upload
					  class="upload-demo"
					  :action="url"
					  multiple
					  :limit="1"
					  :on-success="onSuccess"
					  :on-error="onError"
					  :on-remove="onRemove"
					  :data="uploadObjs"
					  :on-change="onChange"
					
					  >
				     	<div class="confirm_lbr pointer flex space-between">
				     		<div class="confirm_lbrl">请上传合同</div>
				     		<div class="bodycolor">上传合同</div>
				     	</div>
				    </el-upload>
				</div>
				<div class="confirm_lt">上传文件</div>
				
				<div class="confirm_lb flex space-between">
				 	<div class="confirm_lbl">文件</div>
					<el-upload
					  class="upload-demo"
					  :action="url"
					  multiple
					  :limit="1"
					  :on-success="onSuccess1"
					  :on-error="onError1"
					  :on-remove="onRemove1"
					  :data="uploadObjs1"
					  :on-change="onChange1"
					
					  >
				     	<div class="confirm_lbr pointer flex space-between">
				     		<div class="confirm_lbrl">请上传文件</div>
				     		<div class="bodycolor">上传文件</div>
				     	</div>
				    </el-upload>
				</div>
				<div class="confirm_lt">发票信息</div>
				<div class="confirm_lb flex space-between">
					
				 	<div class="confirm_lbl flex">
						发票资料
						<el-tooltip class="item" effect="dark" :content="content" placement="bottom-start">
						    <img class="tishi" src="../../static/image/3.png" alt="" srcset="">
						</el-tooltip>
					</div>
					
					<div class="confirm_lbr pointer flex space-between">
						<div class="confirm_lbrl">{{lookName}}</div>
						<div class="confirm_lbrr bodycolor flex" @click="dialogVisible = true">
							<img src="../../static/image/10.png" alt="" srcset="">
							<div>{{name}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="confirm_r absolute ">
				<div class="confirm_rt">订单信息</div>
				<div class="confirm_rh ">
					<div class="confirm_r1 flex">
						<div class="confirm_rht">贴片层</div>
						<div class="confirm_rhr p1" v-if="params.pasterType == 1">双层贴片</div>
						<div class="confirm_rhr p1" v-if="params.pasterType == 2">单层贴片</div>
						<div class="confirm_rhr p1" v-if="params.pasterType == 0">官方提供</div>
						<div class="confirm_rhr p1" v-if="params.pasterType == 3">顶层+底层全贴</div>
						<div class="confirm_rhr p1" v-if="params.pasterType == 4">只贴顶层</div>
						<div class="confirm_rhr p1" v-if="params.pasterType == 5">只贴底层</div>
						<div class="confirm_rht">贴片数量</div>
						<div class="confirm_rhr p1" >{{params.pasterNum}}PCS</div>
					</div>
					<div class="confirm_r1 flex">
						<div class="confirm_rht">贴片元件</div>
						<div class="confirm_rhr p1">{{params.pasterDeviceStart}}焊点/PCS</div>
						<div class="confirm_rht">贴片元件</div>
						<div class="confirm_rhr p1">{{params.pasterDeviceEnd}}种类/PCS</div>
					</div>
					<div class="confirm_r1 flex">
						<div class="confirm_rht">插件焊接</div>
						<div class="confirm_rhr p1">{{params.plugInStart}}焊点/PCS</div>
						<div class="confirm_rht">插件焊接</div>
						<div class="confirm_rhr p1">{{params.plugInEnd}}种类/PCS</div>
					</div>
					<div class="confirm_r1 flex">
						<div class="confirm_rht">板子尺寸</div>
						<div class="confirm_rhr p1">{{params.plankSizeStart}}长/MM X {{params.plankSizeEnd}}宽/MM</div>
						<div class="confirm_rht">焊接工艺</div>
						<div class="confirm_rhr p1" v-if="params.weldCraft == '0'">无铅高温</div>
						<div class="confirm_rhr p1" v-if="params.weldCraft == 1">有铅高温</div>
						<!-- <div class="confirm_rhd"></div> -->
					</div>
					<div class="confirm_r1 flex">
						<div class="confirm_rht">钢网</div>
						<div class="confirm_rhr p1" v-if="params.steelMesh == '0'">普通钢网</div>
						<div class="confirm_rhr p1" v-if="params.steelMesh == 1">精密钢网</div>
						<div class="confirm_rht">备料方式</div>
						<div class="confirm_rhr p1" v-if="params.steelMeshType == '0'">客户提供</div>
						<div class="confirm_rhr p1" v-if="params.steelMeshType == 1">贝旭提供</div>
						<!-- <div class="confirm_rhb p1" v-if="params.steelMeshType == '0'">客户提供</div>
						<div class="confirm_rhb p1" v-if="params.steelMeshType == 1">贝旭提供</div> -->
					</div>
				</div>
				<div class="confirm_rb flex">
					<div class="confirm_rbl">选择交期</div>
					<div class="confirm_rbr flex">
						<div class="pointer" :class="selectId == item.id ? 'bingji' : ''" v-for="(item,index) in selectList" @click="selectTo(item)">
							{{item.deliveryName}}
						</div>
					</div>
				</div>
				<div class="confirm_rd flex">
					<div class="confirm_rdl">费用总计</div>
					<div class="confirm_rdr">￥{{detal.payMoney}}</div>
				</div>
				<div class="payment pointer" @click="orderAdd()">立即支付</div>
				<div class="payment pointer" @click="ToBack()">上一步</div>
			</div>
		</div>
		<div class="confirm confirm_1" v-else>
			暂无信息请返回首页重新下单！
			<div class="payment pointer" @click="toAdd()">前往下单</div>
		</div>
		<!-- 选择发票 -->
		<lookUp :dialogVisible="dialogVisible" @callber="callber" @noLook="noLook" @addLook="addLook" @edit="edit" ref="child"></lookUp>
		<!-- 添加发票 -->
		<lookEdit :edId="edId" :dialogVisible="editVisible" @addAlss="addAlss" @noLookEdit="noLookEdit"></lookEdit>
	</div>
</template>

<script>
	import lookUp from "@/components/lookUp.vue"
	import lookEdit from "@/components/lookEdit.vue"
	export default {
		components: {lookUp,lookEdit},
		data() {
			return {
				fileList: [],
				fileList1: [],
				selectList:[],
				selectId:'',
				dialogVisible:false,
				lookName:'请选择发票',
				editVisible:false,
				detal:'',
				params:'',
				content:'发票资料',
				url:'',
				name:'请选择发票',
				toType:false,
				id:'',
				edId:'',
				uploadObjs:{
					aliasNames:'',
				},
				uploadObjs1:{
					aliasNames:'',
				},
				orderType:1,
			}
		},
		mounted() {},
		created() {
			console.log('执行')
			if(window.sessionStorage.getItem('orderType')){
				this.orderType = window.sessionStorage.getItem('orderType')
			}
			if(window.sessionStorage.getItem('detal')){
				this.detal = window.sessionStorage.getItem('detal')
				this.detal = JSON.parse(this.detal);
				this.selectList = this.detal.deliveryList
				// console.log(this.selectList[1].id,"1234567");
				if(this.selectList.length == 2){
					this.selectId = this.selectList[1].id;
					
				}else{
					this.selectId = this.selectList[0].id;
					
				}
				
			}
			if(window.sessionStorage.getItem('params')){
				this.params = window.sessionStorage.getItem('params')
				this.params = JSON.parse(this.params);
				this.params.deliveryId = this.selectId;
			}
			// this.deliveryList()
			this.url ='https://api.beixusmt.com/'+ this.$apiUrls.uploadFile
			this.configCode1()
		},
		methods: {
			// 元器件费
			vitality(){
				this.orderMoney()
				// let params = {
				// 	code:'FPZL'
				// }
				// this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
				// 	if (res.code == 0) {
				// 		this.content = res.data.value
				//     }else{
				// 		this.$message.error(res.msg);
				// 		return
				// 	}
				// })
			},
			handleEdit3(e){
				let value = e.replace(/[^\d.]/g, '') // 只能输入数字和.
				value = value.replace(/^\./g, '')  //第一个字符不能是.
				value = value.replace(/\.{2,}/g, '.') // 不能连续输入.
				value = value.replace(/(\.\d+)\./g, '$1') // .后面不能再输入.
				value = value.replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
				value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
				value = value.replace(/(\.\d{2})\d*/, '$1')// 最多保留2位小数
				this.detal.steelMeshTypeMoney = value
			},
			ToBack(){
				window.sessionStorage.setItem('ToBack',1);
				this.inded('index')
			},
			toAdd(){
				this.$router.push({
					name: 'index',
					params: {},
				});
			},
			configCode1(){
				let params = {
					code:'FPZL'
				}
				this.$api.get(this.$apiUrls.configCode,{params}).then((res)=> {
					if (res.code == 0) {
						this.content = res.data.value
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			
			// 时间列表
			deliveryList(){
				this.$api.get(this.$apiUrls.deliveryList).then((res)=> {
					// console.log(res,123)
					if (res.code == 0) {
						// this.imgList = res.data
						this.selectList = res.data
						this.selectId = this.selectList[0].id
						this.orderMoney()
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 添加抬头
			addLook(){
				console.log('执行')
				this.editVisible = true
			},
			// 不开发票
			noLook(){
				this.lookName = '不开发票'
				this.dialogVisible = false
				this.id = ''
				this.orderMoney()
			},
			// 编辑发票
			edit(id){
				this.edId = id
			},
			// 关闭抬头弹框
			callber(id,name){
				this.dialogVisible = false
				if(id){
					this.lookName = name
					this.id = id
					this.name = '已选择'
					this.orderMoney()
				}
				// console.log(this.name,1234)
				
			},
			// 确定添加
			addAlss(){
				this.editVisible = false
				this.$refs.child.sing()
				this.edId = ''
			},
			// 关闭添加弹框
			noLookEdit(){
				this.editVisible = false
				this.edId = ''
			},
			
			
			
			
			// 选择时间
			selectTo(item){
				this.selectId = item.id
				this.orderMoney()
			},
			// 重新计算金额
			orderMoney(){
				if(this.selectId){
					this.params.deliveryId = this.selectId
				}
				let params = this.params
				params.steelMeshTypeMoney = this.detal.steelMeshTypeMoney
				if(this.toType){
					return
				}
				if(this.id){
					params.sfInvoice = 1
					params.invoiceAddressId = this.id
				}else{
					params.sfInvoice = '0'
				}
				this.toType = true
				this.$api.get(this.$apiUrls.orderMoney,{params}).then((res)=> {
					// console.log(res,123)
				    if (res.code == 0) {
						let detail = res.data
						this.detal = detail
				    }else{
						this.$message.success(res.msg);
					}
					this.toType = false
				})
			},
			// 上传成功
			onSuccess1(response, file, fileList){
				console.log(response, file, fileList,123)
				this.fileList1.push(response.data.fileId)
			},
			// 上传失败
			onError1(err, file, fileList){
				this.$message.error('上传失败');
			},
			
			// 删除文件
			onRemove1(file, fileList){
				console.log(file, fileList,111111)
				if(file && file.response){
					let id = file.response.data.fileId
					let index = this.fileList1.indexOf(id)
					this.fileList1.splice(index,1)
				}
			},
			onChange1(file, fileList){
				// console.log(file, fileList,1111)
				this.uploadObjs1.aliasNames = file.name
			},
			// 上传成功
			onSuccess(response, file, fileList){
				console.log(response, file, fileList,123)
				this.fileList.push(response.data.fileId)
			},
			// 上传失败
			onError(err, file, fileList){
				this.$message.error('上传失败');
			},

			// 删除文件
			onRemove(file, fileList){
				console.log(file, fileList,111111)
				if(file && file.response){
					let id = file.response.data.fileId
					let index = this.fileList.indexOf(id)
					this.fileList.splice(index,1)
				}
				
			},
			onChange(file, fileList){
				// console.log(file, fileList,1111)
				this.uploadObjs.aliasNames = file.name
			},
			orderAdd(){
				// console.log(this.params,"提交案由");
				// return
				if(this.params.steelMeshType == 1){
					if(!this.detal.steelMeshTypeMoney){
						this.$message.error('请填写元器件费');
						return
					}
				}
				
				if(!this.selectId){
					this.$message.error('请选择交付时间');
					return
				}
				if(this.fileList.length == 0){
					this.$message.error('请上传合同');
					return
				}
				if(this.fileList1.length == 0){
					this.$message.error('请上传资料');
					return
				}
				
				// if(!this.id){
				// 	this.$message.error('请选择发票');
				// 	return
				// }
				let params = this.params
				params.steelMeshTypeMoney = this.detal.steelMeshTypeMoney
				if(this.id){
					params.sfInvoice = 1
					params.invoiceAddressId = this.id
				}else{
					params.sfInvoice = '0'
				}
				if(this.fileList.length > 0){
					params.fileIdsHt = this.fileList
				}
				if(this.fileList1.length > 0){
					params.fileIds = this.fileList1
				}
				// console.log(params)
				// return
				if(this.toType){
					return
				}
				this.toType = true
				
				this.$api.post(this.$apiUrls.orderAdd,params).then((res)=> {
					// console.log(res,123)
				    if (res.code == 0) {
						this.$message.success(res.msg);
						let order = res.data
						window.sessionStorage.setItem('order',JSON.stringify(order));
						window.sessionStorage.setItem('params',JSON.stringify(params));
						window.sessionStorage.setItem('orderType',1);
						window.sessionStorage.setItem('statusType', 0);
						window.sessionStorage.setItem('ToBack',2);
						console.log(res,'执行')
						this.$router.push({
							name: 'submit',
							params: {
								type:2
							},
						});
						
						return
				    }else{
						this.$message.success(res.msg);
						this.toType = false
					}
					this.toType = false
				})
			},
			inded(url){
				window.sessionStorage.setItem('statusType', 0);
				let page = {}
				this.$router.push({
					name: url,
					params: page,
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.qijian{
		font-size: 18px;
		font-weight: bold;
		color: #333333;
		margin: 0 0 0 16px;
	}
	.jine{
		line-height: 40px;
		font-size: 18px;
		font-weight: bold;
		color: #333333;
	}
	.confirm_1{
		padding: 200px 0 0 0;
		text-align: center;
		.payment{
			width: 306px;
			height: 54px;
			background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
			border-radius: 28px 28px 28px 28px;
			margin: 39px auto 0;
			font-size: 18px;
			font-family: Microsoft YaHei-Regular, Microsoft YaHei;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 54px;
			text-align: center;
		}
	}
	/deep/ .el-upload-list{
		// display: none;
	}
	.tishi{
		width: 14px;
		height: 14px;
		margin: 17px 0 0 11px;
	}
	.page{
		padding: 32px 0 67px 0;
		box-sizing: border-box;
	}
	.confirm_t{
		margin: 0 0 18px 0;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	.confirm{
		position: relative;
		.confirm_l{
			width: 785px;
			background: #fff;
			padding: 34px;
			box-sizing: border-box;
			.confirm_lt{
				font-size: 18px;
				font-weight: bold;
				color: #333333;
				padding: 0 0 25px 0;
				box-sizing: border-box;
				border-bottom: 1px solid #E4EFF0;
			}
			.confirm_lh:last-child{
				margin: 0 28px 0 0;
			}
			.confirm_lh{
				padding: 28px 0 0 0 ;
				margin: 0 0 35px 0;
				width: 100%;
				.confirm_lhl{
					width: 150px;
					padding: 0 50px 0 0;
					box-sizing: border-box;
					text-align: right;
					font-size: 18px;
					font-weight: 400;
					color: #666666;
				}
				.confirm_lhr{
					padding: 0 0 0 50px;
					font-size: 18px;
					font-weight: bold;
					color: #333333;
				}
				.confirm_lhb{
					width: 56px;
					height: 26px;
					background: url(../../static/image/8.png) 0 0 no-repeat;
					background-size: 100% 100%;
					font-size: 16px;
					font-weight: 400;
					color: #FFFFFF;
					line-height: 26px;
					margin: 0 0 0 28px;
					text-align: center;
				}
			}
			.confirm_lb{
				padding: 22px 0 26px;
				box-sizing: border-box;
				// margin-left: auto;
				.confirm_lbl{
					font-size: 18px;
					font-weight: 400;
					color: #333333;
					line-height: 48px;
				}
				.confirm_lbr{
					width: 414px;
					height: 48px;
					background: #FFFFFF;
					border-radius: 6px 6px 6px 6px;
					opacity: 1;
					border: 1px solid #EEEEEE;
					padding: 0 16px;
					line-height: 48px;
					box-sizing: border-box;
					font-size: 14px;
					.confirm_lbrl{
						font-size: 14px;
						font-weight: 400;
						color: #999999;
					}
					.confirm_lbrr{
						img{
							width: 18px;
							height: 18px;
							margin: 14px 8px 0 0;
						}
						
					}
				}
			}
		}
		.confirm_r{
			width: calc(100% - 809px);
			margin: 0 0 0 24px;
			background: #FFFFFF;
			padding: 30px 24px;
			box-sizing: border-box;
			left: 809px;
			top: 0;
			.confirm_rt{
				font-size: 18px;
				font-weight: bold;
				color: #333333;
				padding: 0 0 25px 0;
				box-sizing: border-box;
				border-bottom: 1px solid #E4EFF0;
			}
			.confirm_rh{
				border: 1px solid #E8EDED;
				.confirm_r1:nth-child(5){
					.confirm_rhr{
						border-bottom: none;
					}
				}
				.confirm_r1{
					height: 52px;
					.confirm_rht{
						width: 74px;
						height: 52px;
						padding: 0 9px 0 0;
						line-height: 52px;
						font-size: 14px;
						font-weight: 400;
						color: #333333;
						background: #E8EDED;
						text-align: right;
						box-sizing: border-box;
					}
					.confirm_rhr{
						width: 100px;
						padding: 0 9px 0 9px;
						box-sizing: border-box;
						border-bottom: 1px solid #E8EDED;
						font-size: 12px;
						font-weight: 400;
						color: #333333;
						height: 52px;
						line-height: 52px;
					}
					.confirm_rhd{
						width: 173px;
						border-left: 1px solid #E8EDED;
					}
					.confirm_rhb{
						width: 268px;
						border-top: 1px solid #E8EDED;
						height: 52px;
						line-height: 52px;
						padding: 0 0 0 9px;
					}
				}
				
			}
			.confirm_rb{
				margin: 20px 0 27px;
				.confirm_rbl{
					font-size: 18px;
					font-weight: 400;
					color: #333333;
					margin: 0 24px 0 0;
					width: 74px;
					
				}
				.confirm_rbr{
					width: calc(100%-96px);
					flex-wrap: wrap;
					div{
						padding: 9px 16px;
						box-sizing: border-box;
						border: 1px solid #DEDEDE;
						border-radius: 16px;
						margin: 0 24px 12px 0;
						font-size: 14px;
						font-weight: 400;
						color: #333333;
					}
					.bingji{
						border: 1px solid #0DC3D6;
						background: rgba(13, 195, 214, 0.1);
						color: #0DC3D6 !important;
					}
				}
			}
			.confirm_rd{
				.confirm_rdl{
					font-size: 18px;
					font-weight: 400;
					color: #333333;
					margin: 0 24px 0 0;
					width: 72px;
				}
				.confirm_rdr{
					font-size: 18px;
					font-weight: bold;
					color: #FF2B2B;
				}
			}
			.payment{
				width: 306px;
				height: 54px;
				background: linear-gradient(137deg, #00E7DF 0%, #1C95C9 100%);
				border-radius: 28px 28px 28px 28px;
				margin: 39px auto 0;
				font-size: 18px;
				font-family: Microsoft YaHei-Regular, Microsoft YaHei;
				font-weight: 400;
				color: #FFFFFF;
				line-height: 54px;
				text-align: center;
			}
		}
	}
</style>