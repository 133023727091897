<template>
	<div class="page  w1600">
		<div class="flex confirm_t">
			<div class="pointer" @click="tiaohuan('index')">首页</div>
			<img class="imd" src="../../static/image/7.png" alt="" srcset="">
			<div class="bodycolor">个人中心</div>
		</div>
		<div class="flex">
			<menud :lestId='lestId'></menud>
			<div class="person_r ">
				<div class="person_rt flex">
					<div class="pointer" @click="tiaohuan('feedback')">意见反馈</div>
					<img class="imd" src="../../static/image/7.png" alt="" srcset="">
					<div class="bodycolor">反馈记录</div>
				</div>
				<div v-if="isShowLoading">
				    <div v-if="list.length > 0">
						<div class="record flex" v-for="(item,index) in list">
							<div class="record_l">
								<img class="imd" v-if="item.picFileInfosVo" :src="item.picFileInfosVo.filePath" alt="" srcset="">
								<img class="imd" v-else src="../../static/image/27.png" alt="" srcset="">
							</div>
							<div class="record_r">
								<!-- :class="listId.indexOf(item) != -1 ? 'p1' : ''" -->
								<div class="record_rt" >{{item.contentInfo}}</div>
							    <div class="record_h flex wrap">
							    	<div class="record_hl" v-for="(item,index) in item.fileInfos">
							    		<img :src="item.filePath" alt="" srcset="">
							    	</div>
							    </div>
							    <div class="record_d" v-if="item.telephone">联系方式：{{item.telephone}}</div>
							    <div class="record_b flex">反馈类型：
								    <div v-if="item.feedbackType == '0'">产品建议</div>
								    <div v-if="item.feedbackType == 1">功能故障</div>
								    <div v-if="item.feedbackType == 2">体验问题</div>
								    <div v-if="item.feedbackType == 3">其他问题</div>
								</div>
							    <div class="record_b">反馈时间：{{item.createTime}}</div>
							    <div class="record_g flex" v-if="item.contentReply">
							    	<div class="record_gl">平台回复：</div>
							    	<div class="">{{item.contentReply}}</div>
							    </div>
							</div>
						</div>
					</div>
					<none v-else></none>
				</div>
				
			</div>
			<el-pagination
			v-if="list.length > 0"
			  background
			  layout="->,prev, pager, next"
			  :page-size='10'
			  :current-page.sync='pages'
			  @current-change="feye"
			  :total="total">
			  <!-- :total="100"> -->
			</el-pagination>
		</div>
		
		<phone></phone>
		
	</div>
</template>

<script>
	import None from "@/components/none.vue"
    import menud from "@/components/menu.vue"
	import phone from "@/components/phone.vue"
	export default {
		components: {menud,phone,None},
		data() {
			return {
				lestId:8,
				list:[],
				pages:1,
				total:0,
				isShowLoading:true,
			}
		},
		mounted() {},
		created() {
			this.isShowLoading = false
			this.feedbackPage()
		},
		methods: {
			feye(e){
				// console.log(e,123)
				this.feedbackPage()
			},
			feedbackPage(){
				let params = {
					size:10,
					current:this.pages,
				}
				this.$api.get(this.$apiUrls.feedbackPage,{params}).then((res)=> {
					if (res.code == 0) {
						this.total = Number(res.data.total)
						this.list = res.data.records
						for (var i = 0; i < this.list.length; i++) {
							this.list[i].createTime = this.timestampToTime(this.list[i].createTime)
						}
						this.isShowLoading = true
						// timestampToTime(1670145353)
				    }else{
						this.$message.error(res.msg);
						return
					}
				})
			},
			// 时间转
			timestampToTime(timestamp) {
			  var date = new Date(timestamp * 1000);
			  var Y = date.getFullYear() + "-";
			  var M =
			    (date.getMonth() + 1 < 10
			      ? "0" + (date.getMonth() + 1)
			      : date.getMonth() + 1) + "-";
			  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
			  var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ":";
			  var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ":";
			  var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
			  // var h = date.getHours() + ":";
			  // var m = date.getMinutes() + ":";
			  // var s = date.getSeconds();
			  return Y + M + D + h + m + s;
			},
			tiaohuan(url,type){
				let page = {}
				if(type){
					page.type = type
				}
				this.$router.push({
					name: url,
					params: page,
				});
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep{
		.el-date-editor{
			width: 254px !important;
			border: none !important;
		}
		.active{
			background: #0DC3D6 !important;
		}
		.number{
			background: #F4F4F5 ;
		}
		.el-pagination {
		    text-align: center; 
			margin-top: 20px;
		}
	}
	.page{
		padding: 32px 0 0 0;
		margin-bottom: 86px;
	}
    .confirm_t{
		margin: 0 0 18px 220px;
		div{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
		}
		.imd{
			width: 7px;
			height: 12px;
			margin: 5px 12px 0;
		}
	}
	
	.person_r{
		width: 1200px;
		// height: 500px;
		background: #fff;
		padding: 40px 38px ;
		box-sizing: border-box;
		.person_rt{
			font-size: 16px;
			font-weight: bold;
			color: #333333;
			border-bottom: 1px solid #E4EFF0;
			padding: 0 0 26px 0;
			margin: 0 0 20px 0;
			.imd{
				width: 7px;
				height: 12px;
				margin: 5px 12px 0;
			}
		}
	}
	.record{
		border-bottom: 1px solid #EEEEEE;
		padding: 20px 0 ;
		box-sizing: border-box;
		.record_l{
			width: 40px;
			height: 40px;
			border-radius: 40px;
			overflow: hidden;
			margin: 0 18px 0 0;
			.imd{
				width: 100%;
				height: 100%;
			}
		}
		.record_r{
			width: calc(100% - 58px);
			.record_rt{
				font-size: 18px;
				font-weight: 400;
				color: #333333;
			}
		}
		.record_h{
			.record_hl{
				margin: 20px 18px 0 0;
				width: 100px;
				height: 100px;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
		.record_d{
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			margin: 15px 0 0 0;
		}
		.record_b{
			font-size: 16px;
			font-weight: 400;
			color: #999999;
			margin: 19px 0 0 0;
		}
		.record_g{
			margin: 16px 0 0 0;
			font-size: 16px;
			font-weight: 400;
			color: #333333;
			.record_gl{
				color: #496A9D;
			}
		}
	}
</style>