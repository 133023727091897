import Vue from 'vue'
import router from './router/index.js'
import requests from "@/api/request";
import apiUrls from '@/api/index.js'
import VueLazyLoad from 'vue-lazyload'
import App from './App.vue'
import Qs from 'qs'
import { HOST } from '@/util/config'
import './plugins/element.js'
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);
import vueEsign from 'vue-esign'
Vue.use(vueEsign)

// 打印机
import Print from 'vue-print-nb'
Vue.use(Print);  //注册


Vue.use(VueLazyLoad,{
  loading:'/imgs/loading-svg/loading-bars.svg'
})
Vue.mixin({
  methods: {
    getImageUrl(url) {
      return url.indexOf("http://") != -1 || url.indexOf("https://") != -1 ? url : HOST + url;
    }
  }
})
Vue.prototype.qs = Qs;
Vue.prototype.$api = requests;
Vue.prototype.$apiUrls = apiUrls;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
